//Admin sidebar style
.page-sidebar,
.aside {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.page-sidebar {
  width: $page-sidebar-width;
  height: 100%;
  position: fixed;
  z-index: $zindex-fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  [data-simplebar] {
    overflow-x: hidden;
  }
  .aside-footer{
    min-width: $page-sidebar-width;
    transition: all .35s;
  }
.nav-link{
  width: 100%;
  border-radius: $border-radius-lg;
  padding-top: $nav-link-padding-y * .75;
  padding-bottom: $nav-link-padding-y * .75;
  font-weight: $sidebar-link-font-weight;
}
  .simplebar-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  .nav-link,
  .sidebar-link {
    &[data-bs-toggle="collapse"] {
      display: flex;
      align-items: center;

      &::after {
        display: inline-block;
        font-size: $font-size-sm;
        margin-right: .5rem;
        margin-left: auto;
        content: "\f286";
        font-family: bootstrap-icons;
        font-weight: normal;
        transition: transform .15s ease-in-out;
        opacity: .5;
      }

      &[aria-expanded="true"] {
        color: $component-active-bg;

        &::after {
          transform: rotate(180deg);
        }
      }

      &.active {
        color: $component-active-bg;
      }

      &:hover {
        color: $navbar-light-active-color;
      }
    }
  }
}

.sidebar-icon {
  border-radius: $border-radius;
  margin-right: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;

  >i {
    font-size: $font-size-base * 1.25;
    line-height: 1;
    vertical-align: middle;
    font-weight: lighter!important;
  }
}

.sidebar-dropdown {
  padding-left: 2.75rem;

  .sidebar-item {
    display: block;

    .sidebar-link {
      font-size: $font-size-base * .9465;
      padding: .4rem 1rem;
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-transform: capitalize;
    }
  }

  .sidebar-dropdown {
    padding-left: .5rem;
  }
}

//aside light
.aside-light {

  .aside-logo a,
  .sidebar-title {
    color: $dark;
  }

  .nav-link,
  .nav-link[data-bs-toggle="collapse"],
  .sidebar-item .sidebar-link {
    color: $navbar-light-color;

    &:hover,
    &[aria-expanded="true"] {
      color: $navbar-light-hover-color;
    }

    &.active {
      color: $navbar-light-active-color;
    }
  }
}

//aside dark
.aside-dark {

  .aside-logo a,
  .sidebar-title {
    color: white;
  }

  .nav-link,
  .nav-link[data-bs-toggle="collapse"],
  .sidebar-item .sidebar-link {
    color: $navbar-dark-color;

    &:hover,
    &[aria-expanded="true"] {
      color: $navbar-dark-hover-color;
    }

    &.active {
      color: $navbar-dark-active-color;
    }
  }
}

.sidebar-title {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  opacity: .4;
  font-size: $font-size-sm;

  >i {
    display: flex;
    width: 2rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
  }
}

.page-header,
.aside-logo {
  height: 70px;
  display: flex;
  align-items: center;
}

.page-content {
  transition: margin .3s;
}

.page-sidebar {
  transition: width .3s;
}

//Icon arrow for collapse items



@include media-breakpoint-up(lg) {
  .page-content {
    margin-left: $page-sidebar-width;
  }

  body.page-sidebar-mini {
    .page-sidebar:not(:hover) {
      width: $page-sidebar-compact-width;

      .sidebar-dropdown {
        height: 0;
        overflow: hidden;
        transition: height .3s ease;
      }

      .sidebar-text,
      .logo-text,
      .sidebar-title>span {
        opacity: 0;
        transition: opacity .3s ease;
      }

      .aside-footer {
        transform: translateX(-100%);
        opacity: 0;
      }
    }

    .page-content {
      margin-left: $page-sidebar-compact-width;
    }
  }
}

@include media-breakpoint-down(lg) {
  .page-content {
    margin-left: 0px;
  }

  .page-sidebar {
    transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: translateX(-100%);
    z-index: $zindex-modal;
  }

  .sidebar-close a{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index:-1;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s background-color .25s;
    }
  .page-sidebar-in {
    overflow: hidden;
    .page-sidebar {
      transform: translateX(0);
      box-shadow: 16px 0px 300px rgba($secondary, .225);
    }
    .page-content {
      pointer-events: none;
    }
    .sidebar-close a{
      opacity: 1;
    visibility: visible;
      background-color: rgba($secondary, .5);
      z-index:$zindex-sticky;
    }
  }
}