//
//Chat box scss start
.chat-user-box,.chat-self-box{
    padding: $spacer * .5 $spacer * .75;
    border-radius: $border-radius-lg;
    margin-bottom: $spacer * .25;
    display: table;
    position: relative;
    .chat-user-time,.chat-self-time{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: all .2s;
        opacity: 0;
        margin-top: .25rem;
    }
    &:hover{
        .chat-user-time,.chat-self-time{
            opacity: 1;
            margin-top: 0;
        }
    }
}
.chat-user-time{
    left: 100%;
    margin-left: .5rem;
    
}
.chat-self-time{
    left: auto;
    right: 100%;
    margin-right: .5rem;
    
}
.chat-user-box{
    background-color: tint-color($primary,90%);
    border-top-left-radius: 0px;
}
.chat-self-box{
    background-color: $component-active-bg;
    color: $component-active-color;
    border-bottom-right-radius: 0px;
}