//buttons override
.btn{
    text-transform: $btn-text-transform;
    font-weight:$btn-font-weight;
}


// Button white
.btn-white {
    border-color: $gray-200;
  
    &:hover, &:focus {
      background-color: $gray-100;
      border-color: $gray-300;
    }
  }

  .btn-group-toggle .btn-white:not(:disabled):not(.disabled):active,
.btn-group-toggle .btn-white:not(:disabled):not(.disabled).active,
.btn-group-toggle .btn-check:checked + .btn-white {
  background-color: $input-bg;
  border-color: $input-focus-border-color;
  box-shadow: none;
  color: $primary;
}
.btn-group-toggle .btn-white:focus,
.btn-group-toggle .btn-white.focus {
  box-shadow: none;
}