@charset "UTF-8";
body[data-aos-duration="50"] [data-aos], [data-aos][data-aos][data-aos-duration="50"] {
  transition-duration: 50ms;
}
body[data-aos-delay="50"] [data-aos], [data-aos][data-aos][data-aos-delay="50"] {
  transition-delay: 0s;
}
body[data-aos-delay="50"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="50"].aos-animate {
  transition-delay: 50ms;
}
body[data-aos-duration="100"] [data-aos], [data-aos][data-aos][data-aos-duration="100"] {
  transition-duration: 100ms;
}
body[data-aos-delay="100"] [data-aos], [data-aos][data-aos][data-aos-delay="100"] {
  transition-delay: 0s;
}
body[data-aos-delay="100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="100"].aos-animate {
  transition-delay: 100ms;
}
body[data-aos-duration="150"] [data-aos], [data-aos][data-aos][data-aos-duration="150"] {
  transition-duration: 150ms;
}
body[data-aos-delay="150"] [data-aos], [data-aos][data-aos][data-aos-delay="150"] {
  transition-delay: 0s;
}
body[data-aos-delay="150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="150"].aos-animate {
  transition-delay: 150ms;
}
body[data-aos-duration="200"] [data-aos], [data-aos][data-aos][data-aos-duration="200"] {
  transition-duration: 200ms;
}
body[data-aos-delay="200"] [data-aos], [data-aos][data-aos][data-aos-delay="200"] {
  transition-delay: 0s;
}
body[data-aos-delay="200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="200"].aos-animate {
  transition-delay: 200ms;
}
body[data-aos-duration="250"] [data-aos], [data-aos][data-aos][data-aos-duration="250"] {
  transition-duration: 250ms;
}
body[data-aos-delay="250"] [data-aos], [data-aos][data-aos][data-aos-delay="250"] {
  transition-delay: 0s;
}
body[data-aos-delay="250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="250"].aos-animate {
  transition-delay: 250ms;
}
body[data-aos-duration="300"] [data-aos], [data-aos][data-aos][data-aos-duration="300"] {
  transition-duration: 300ms;
}
body[data-aos-delay="300"] [data-aos], [data-aos][data-aos][data-aos-delay="300"] {
  transition-delay: 0s;
}
body[data-aos-delay="300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="300"].aos-animate {
  transition-delay: 300ms;
}
body[data-aos-duration="350"] [data-aos], [data-aos][data-aos][data-aos-duration="350"] {
  transition-duration: 350ms;
}
body[data-aos-delay="350"] [data-aos], [data-aos][data-aos][data-aos-delay="350"] {
  transition-delay: 0s;
}
body[data-aos-delay="350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="350"].aos-animate {
  transition-delay: 350ms;
}
body[data-aos-duration="400"] [data-aos], [data-aos][data-aos][data-aos-duration="400"] {
  transition-duration: 400ms;
}
body[data-aos-delay="400"] [data-aos], [data-aos][data-aos][data-aos-delay="400"] {
  transition-delay: 0s;
}
body[data-aos-delay="400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="400"].aos-animate {
  transition-delay: 400ms;
}
body[data-aos-duration="450"] [data-aos], [data-aos][data-aos][data-aos-duration="450"] {
  transition-duration: 450ms;
}
body[data-aos-delay="450"] [data-aos], [data-aos][data-aos][data-aos-delay="450"] {
  transition-delay: 0s;
}
body[data-aos-delay="450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="450"].aos-animate {
  transition-delay: 450ms;
}
body[data-aos-duration="500"] [data-aos], [data-aos][data-aos][data-aos-duration="500"] {
  transition-duration: 500ms;
}
body[data-aos-delay="500"] [data-aos], [data-aos][data-aos][data-aos-delay="500"] {
  transition-delay: 0s;
}
body[data-aos-delay="500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="500"].aos-animate {
  transition-delay: 500ms;
}
body[data-aos-duration="550"] [data-aos], [data-aos][data-aos][data-aos-duration="550"] {
  transition-duration: 550ms;
}
body[data-aos-delay="550"] [data-aos], [data-aos][data-aos][data-aos-delay="550"] {
  transition-delay: 0s;
}
body[data-aos-delay="550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="550"].aos-animate {
  transition-delay: 550ms;
}
body[data-aos-duration="600"] [data-aos], [data-aos][data-aos][data-aos-duration="600"] {
  transition-duration: 600ms;
}
body[data-aos-delay="600"] [data-aos], [data-aos][data-aos][data-aos-delay="600"] {
  transition-delay: 0s;
}
body[data-aos-delay="600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="600"].aos-animate {
  transition-delay: 600ms;
}
body[data-aos-duration="650"] [data-aos], [data-aos][data-aos][data-aos-duration="650"] {
  transition-duration: 650ms;
}
body[data-aos-delay="650"] [data-aos], [data-aos][data-aos][data-aos-delay="650"] {
  transition-delay: 0s;
}
body[data-aos-delay="650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="650"].aos-animate {
  transition-delay: 650ms;
}
body[data-aos-duration="700"] [data-aos], [data-aos][data-aos][data-aos-duration="700"] {
  transition-duration: 700ms;
}
body[data-aos-delay="700"] [data-aos], [data-aos][data-aos][data-aos-delay="700"] {
  transition-delay: 0s;
}
body[data-aos-delay="700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="700"].aos-animate {
  transition-delay: 700ms;
}
body[data-aos-duration="750"] [data-aos], [data-aos][data-aos][data-aos-duration="750"] {
  transition-duration: 750ms;
}
body[data-aos-delay="750"] [data-aos], [data-aos][data-aos][data-aos-delay="750"] {
  transition-delay: 0s;
}
body[data-aos-delay="750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="750"].aos-animate {
  transition-delay: 750ms;
}
body[data-aos-duration="800"] [data-aos], [data-aos][data-aos][data-aos-duration="800"] {
  transition-duration: 800ms;
}
body[data-aos-delay="800"] [data-aos], [data-aos][data-aos][data-aos-delay="800"] {
  transition-delay: 0s;
}
body[data-aos-delay="800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="800"].aos-animate {
  transition-delay: 800ms;
}
body[data-aos-duration="850"] [data-aos], [data-aos][data-aos][data-aos-duration="850"] {
  transition-duration: 850ms;
}
body[data-aos-delay="850"] [data-aos], [data-aos][data-aos][data-aos-delay="850"] {
  transition-delay: 0s;
}
body[data-aos-delay="850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="850"].aos-animate {
  transition-delay: 850ms;
}
body[data-aos-duration="900"] [data-aos], [data-aos][data-aos][data-aos-duration="900"] {
  transition-duration: 900ms;
}
body[data-aos-delay="900"] [data-aos], [data-aos][data-aos][data-aos-delay="900"] {
  transition-delay: 0s;
}
body[data-aos-delay="900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="900"].aos-animate {
  transition-delay: 900ms;
}
body[data-aos-duration="950"] [data-aos], [data-aos][data-aos][data-aos-duration="950"] {
  transition-duration: 950ms;
}
body[data-aos-delay="950"] [data-aos], [data-aos][data-aos][data-aos-delay="950"] {
  transition-delay: 0s;
}
body[data-aos-delay="950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="950"].aos-animate {
  transition-delay: 950ms;
}
body[data-aos-duration="1000"] [data-aos], [data-aos][data-aos][data-aos-duration="1000"] {
  transition-duration: 1000ms;
}
body[data-aos-delay="1000"] [data-aos], [data-aos][data-aos][data-aos-delay="1000"] {
  transition-delay: 0s;
}
body[data-aos-delay="1000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1000"].aos-animate {
  transition-delay: 1000ms;
}
body[data-aos-duration="1050"] [data-aos], [data-aos][data-aos][data-aos-duration="1050"] {
  transition-duration: 1050ms;
}
body[data-aos-delay="1050"] [data-aos], [data-aos][data-aos][data-aos-delay="1050"] {
  transition-delay: 0s;
}
body[data-aos-delay="1050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1050"].aos-animate {
  transition-delay: 1050ms;
}
body[data-aos-duration="1100"] [data-aos], [data-aos][data-aos][data-aos-duration="1100"] {
  transition-duration: 1100ms;
}
body[data-aos-delay="1100"] [data-aos], [data-aos][data-aos][data-aos-delay="1100"] {
  transition-delay: 0s;
}
body[data-aos-delay="1100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1100"].aos-animate {
  transition-delay: 1100ms;
}
body[data-aos-duration="1150"] [data-aos], [data-aos][data-aos][data-aos-duration="1150"] {
  transition-duration: 1150ms;
}
body[data-aos-delay="1150"] [data-aos], [data-aos][data-aos][data-aos-delay="1150"] {
  transition-delay: 0s;
}
body[data-aos-delay="1150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1150"].aos-animate {
  transition-delay: 1150ms;
}
body[data-aos-duration="1200"] [data-aos], [data-aos][data-aos][data-aos-duration="1200"] {
  transition-duration: 1200ms;
}
body[data-aos-delay="1200"] [data-aos], [data-aos][data-aos][data-aos-delay="1200"] {
  transition-delay: 0s;
}
body[data-aos-delay="1200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1200"].aos-animate {
  transition-delay: 1200ms;
}
body[data-aos-duration="1250"] [data-aos], [data-aos][data-aos][data-aos-duration="1250"] {
  transition-duration: 1250ms;
}
body[data-aos-delay="1250"] [data-aos], [data-aos][data-aos][data-aos-delay="1250"] {
  transition-delay: 0s;
}
body[data-aos-delay="1250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1250"].aos-animate {
  transition-delay: 1250ms;
}
body[data-aos-duration="1300"] [data-aos], [data-aos][data-aos][data-aos-duration="1300"] {
  transition-duration: 1300ms;
}
body[data-aos-delay="1300"] [data-aos], [data-aos][data-aos][data-aos-delay="1300"] {
  transition-delay: 0s;
}
body[data-aos-delay="1300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1300"].aos-animate {
  transition-delay: 1300ms;
}
body[data-aos-duration="1350"] [data-aos], [data-aos][data-aos][data-aos-duration="1350"] {
  transition-duration: 1350ms;
}
body[data-aos-delay="1350"] [data-aos], [data-aos][data-aos][data-aos-delay="1350"] {
  transition-delay: 0s;
}
body[data-aos-delay="1350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1350"].aos-animate {
  transition-delay: 1350ms;
}
body[data-aos-duration="1400"] [data-aos], [data-aos][data-aos][data-aos-duration="1400"] {
  transition-duration: 1400ms;
}
body[data-aos-delay="1400"] [data-aos], [data-aos][data-aos][data-aos-delay="1400"] {
  transition-delay: 0s;
}
body[data-aos-delay="1400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1400"].aos-animate {
  transition-delay: 1400ms;
}
body[data-aos-duration="1450"] [data-aos], [data-aos][data-aos][data-aos-duration="1450"] {
  transition-duration: 1450ms;
}
body[data-aos-delay="1450"] [data-aos], [data-aos][data-aos][data-aos-delay="1450"] {
  transition-delay: 0s;
}
body[data-aos-delay="1450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1450"].aos-animate {
  transition-delay: 1450ms;
}
body[data-aos-duration="1500"] [data-aos], [data-aos][data-aos][data-aos-duration="1500"] {
  transition-duration: 1500ms;
}
body[data-aos-delay="1500"] [data-aos], [data-aos][data-aos][data-aos-delay="1500"] {
  transition-delay: 0s;
}
body[data-aos-delay="1500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1500"].aos-animate {
  transition-delay: 1500ms;
}
body[data-aos-duration="1550"] [data-aos], [data-aos][data-aos][data-aos-duration="1550"] {
  transition-duration: 1550ms;
}
body[data-aos-delay="1550"] [data-aos], [data-aos][data-aos][data-aos-delay="1550"] {
  transition-delay: 0s;
}
body[data-aos-delay="1550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1550"].aos-animate {
  transition-delay: 1550ms;
}
body[data-aos-duration="1600"] [data-aos], [data-aos][data-aos][data-aos-duration="1600"] {
  transition-duration: 1600ms;
}
body[data-aos-delay="1600"] [data-aos], [data-aos][data-aos][data-aos-delay="1600"] {
  transition-delay: 0s;
}
body[data-aos-delay="1600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1600"].aos-animate {
  transition-delay: 1600ms;
}
body[data-aos-duration="1650"] [data-aos], [data-aos][data-aos][data-aos-duration="1650"] {
  transition-duration: 1650ms;
}
body[data-aos-delay="1650"] [data-aos], [data-aos][data-aos][data-aos-delay="1650"] {
  transition-delay: 0s;
}
body[data-aos-delay="1650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1650"].aos-animate {
  transition-delay: 1650ms;
}
body[data-aos-duration="1700"] [data-aos], [data-aos][data-aos][data-aos-duration="1700"] {
  transition-duration: 1700ms;
}
body[data-aos-delay="1700"] [data-aos], [data-aos][data-aos][data-aos-delay="1700"] {
  transition-delay: 0s;
}
body[data-aos-delay="1700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1700"].aos-animate {
  transition-delay: 1700ms;
}
body[data-aos-duration="1750"] [data-aos], [data-aos][data-aos][data-aos-duration="1750"] {
  transition-duration: 1750ms;
}
body[data-aos-delay="1750"] [data-aos], [data-aos][data-aos][data-aos-delay="1750"] {
  transition-delay: 0s;
}
body[data-aos-delay="1750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1750"].aos-animate {
  transition-delay: 1750ms;
}
body[data-aos-duration="1800"] [data-aos], [data-aos][data-aos][data-aos-duration="1800"] {
  transition-duration: 1800ms;
}
body[data-aos-delay="1800"] [data-aos], [data-aos][data-aos][data-aos-delay="1800"] {
  transition-delay: 0s;
}
body[data-aos-delay="1800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1800"].aos-animate {
  transition-delay: 1800ms;
}
body[data-aos-duration="1850"] [data-aos], [data-aos][data-aos][data-aos-duration="1850"] {
  transition-duration: 1850ms;
}
body[data-aos-delay="1850"] [data-aos], [data-aos][data-aos][data-aos-delay="1850"] {
  transition-delay: 0s;
}
body[data-aos-delay="1850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1850"].aos-animate {
  transition-delay: 1850ms;
}
body[data-aos-duration="1900"] [data-aos], [data-aos][data-aos][data-aos-duration="1900"] {
  transition-duration: 1900ms;
}
body[data-aos-delay="1900"] [data-aos], [data-aos][data-aos][data-aos-delay="1900"] {
  transition-delay: 0s;
}
body[data-aos-delay="1900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1900"].aos-animate {
  transition-delay: 1900ms;
}
body[data-aos-duration="1950"] [data-aos], [data-aos][data-aos][data-aos-duration="1950"] {
  transition-duration: 1950ms;
}
body[data-aos-delay="1950"] [data-aos], [data-aos][data-aos][data-aos-delay="1950"] {
  transition-delay: 0s;
}
body[data-aos-delay="1950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1950"].aos-animate {
  transition-delay: 1950ms;
}
body[data-aos-duration="2000"] [data-aos], [data-aos][data-aos][data-aos-duration="2000"] {
  transition-duration: 2000ms;
}
body[data-aos-delay="2000"] [data-aos], [data-aos][data-aos][data-aos-delay="2000"] {
  transition-delay: 0s;
}
body[data-aos-delay="2000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2000"].aos-animate {
  transition-delay: 2000ms;
}
body[data-aos-duration="2050"] [data-aos], [data-aos][data-aos][data-aos-duration="2050"] {
  transition-duration: 2050ms;
}
body[data-aos-delay="2050"] [data-aos], [data-aos][data-aos][data-aos-delay="2050"] {
  transition-delay: 0s;
}
body[data-aos-delay="2050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2050"].aos-animate {
  transition-delay: 2050ms;
}
body[data-aos-duration="2100"] [data-aos], [data-aos][data-aos][data-aos-duration="2100"] {
  transition-duration: 2100ms;
}
body[data-aos-delay="2100"] [data-aos], [data-aos][data-aos][data-aos-delay="2100"] {
  transition-delay: 0s;
}
body[data-aos-delay="2100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2100"].aos-animate {
  transition-delay: 2100ms;
}
body[data-aos-duration="2150"] [data-aos], [data-aos][data-aos][data-aos-duration="2150"] {
  transition-duration: 2150ms;
}
body[data-aos-delay="2150"] [data-aos], [data-aos][data-aos][data-aos-delay="2150"] {
  transition-delay: 0s;
}
body[data-aos-delay="2150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2150"].aos-animate {
  transition-delay: 2150ms;
}
body[data-aos-duration="2200"] [data-aos], [data-aos][data-aos][data-aos-duration="2200"] {
  transition-duration: 2200ms;
}
body[data-aos-delay="2200"] [data-aos], [data-aos][data-aos][data-aos-delay="2200"] {
  transition-delay: 0s;
}
body[data-aos-delay="2200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2200"].aos-animate {
  transition-delay: 2200ms;
}
body[data-aos-duration="2250"] [data-aos], [data-aos][data-aos][data-aos-duration="2250"] {
  transition-duration: 2250ms;
}
body[data-aos-delay="2250"] [data-aos], [data-aos][data-aos][data-aos-delay="2250"] {
  transition-delay: 0s;
}
body[data-aos-delay="2250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2250"].aos-animate {
  transition-delay: 2250ms;
}
body[data-aos-duration="2300"] [data-aos], [data-aos][data-aos][data-aos-duration="2300"] {
  transition-duration: 2300ms;
}
body[data-aos-delay="2300"] [data-aos], [data-aos][data-aos][data-aos-delay="2300"] {
  transition-delay: 0s;
}
body[data-aos-delay="2300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2300"].aos-animate {
  transition-delay: 2300ms;
}
body[data-aos-duration="2350"] [data-aos], [data-aos][data-aos][data-aos-duration="2350"] {
  transition-duration: 2350ms;
}
body[data-aos-delay="2350"] [data-aos], [data-aos][data-aos][data-aos-delay="2350"] {
  transition-delay: 0s;
}
body[data-aos-delay="2350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2350"].aos-animate {
  transition-delay: 2350ms;
}
body[data-aos-duration="2400"] [data-aos], [data-aos][data-aos][data-aos-duration="2400"] {
  transition-duration: 2400ms;
}
body[data-aos-delay="2400"] [data-aos], [data-aos][data-aos][data-aos-delay="2400"] {
  transition-delay: 0s;
}
body[data-aos-delay="2400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2400"].aos-animate {
  transition-delay: 2400ms;
}
body[data-aos-duration="2450"] [data-aos], [data-aos][data-aos][data-aos-duration="2450"] {
  transition-duration: 2450ms;
}
body[data-aos-delay="2450"] [data-aos], [data-aos][data-aos][data-aos-delay="2450"] {
  transition-delay: 0s;
}
body[data-aos-delay="2450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2450"].aos-animate {
  transition-delay: 2450ms;
}
body[data-aos-duration="2500"] [data-aos], [data-aos][data-aos][data-aos-duration="2500"] {
  transition-duration: 2500ms;
}
body[data-aos-delay="2500"] [data-aos], [data-aos][data-aos][data-aos-delay="2500"] {
  transition-delay: 0s;
}
body[data-aos-delay="2500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2500"].aos-animate {
  transition-delay: 2500ms;
}
body[data-aos-duration="2550"] [data-aos], [data-aos][data-aos][data-aos-duration="2550"] {
  transition-duration: 2550ms;
}
body[data-aos-delay="2550"] [data-aos], [data-aos][data-aos][data-aos-delay="2550"] {
  transition-delay: 0s;
}
body[data-aos-delay="2550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2550"].aos-animate {
  transition-delay: 2550ms;
}
body[data-aos-duration="2600"] [data-aos], [data-aos][data-aos][data-aos-duration="2600"] {
  transition-duration: 2600ms;
}
body[data-aos-delay="2600"] [data-aos], [data-aos][data-aos][data-aos-delay="2600"] {
  transition-delay: 0s;
}
body[data-aos-delay="2600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2600"].aos-animate {
  transition-delay: 2600ms;
}
body[data-aos-duration="2650"] [data-aos], [data-aos][data-aos][data-aos-duration="2650"] {
  transition-duration: 2650ms;
}
body[data-aos-delay="2650"] [data-aos], [data-aos][data-aos][data-aos-delay="2650"] {
  transition-delay: 0s;
}
body[data-aos-delay="2650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2650"].aos-animate {
  transition-delay: 2650ms;
}
body[data-aos-duration="2700"] [data-aos], [data-aos][data-aos][data-aos-duration="2700"] {
  transition-duration: 2700ms;
}
body[data-aos-delay="2700"] [data-aos], [data-aos][data-aos][data-aos-delay="2700"] {
  transition-delay: 0s;
}
body[data-aos-delay="2700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2700"].aos-animate {
  transition-delay: 2700ms;
}
body[data-aos-duration="2750"] [data-aos], [data-aos][data-aos][data-aos-duration="2750"] {
  transition-duration: 2750ms;
}
body[data-aos-delay="2750"] [data-aos], [data-aos][data-aos][data-aos-delay="2750"] {
  transition-delay: 0s;
}
body[data-aos-delay="2750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2750"].aos-animate {
  transition-delay: 2750ms;
}
body[data-aos-duration="2800"] [data-aos], [data-aos][data-aos][data-aos-duration="2800"] {
  transition-duration: 2800ms;
}
body[data-aos-delay="2800"] [data-aos], [data-aos][data-aos][data-aos-delay="2800"] {
  transition-delay: 0s;
}
body[data-aos-delay="2800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2800"].aos-animate {
  transition-delay: 2800ms;
}
body[data-aos-duration="2850"] [data-aos], [data-aos][data-aos][data-aos-duration="2850"] {
  transition-duration: 2850ms;
}
body[data-aos-delay="2850"] [data-aos], [data-aos][data-aos][data-aos-delay="2850"] {
  transition-delay: 0s;
}
body[data-aos-delay="2850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2850"].aos-animate {
  transition-delay: 2850ms;
}
body[data-aos-duration="2900"] [data-aos], [data-aos][data-aos][data-aos-duration="2900"] {
  transition-duration: 2900ms;
}
body[data-aos-delay="2900"] [data-aos], [data-aos][data-aos][data-aos-delay="2900"] {
  transition-delay: 0s;
}
body[data-aos-delay="2900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2900"].aos-animate {
  transition-delay: 2900ms;
}
body[data-aos-duration="2950"] [data-aos], [data-aos][data-aos][data-aos-duration="2950"] {
  transition-duration: 2950ms;
}
body[data-aos-delay="2950"] [data-aos], [data-aos][data-aos][data-aos-delay="2950"] {
  transition-delay: 0s;
}
body[data-aos-delay="2950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2950"].aos-animate {
  transition-delay: 2950ms;
}
body[data-aos-duration="3000"] [data-aos], [data-aos][data-aos][data-aos-duration="3000"] {
  transition-duration: 3000ms;
}
body[data-aos-delay="3000"] [data-aos], [data-aos][data-aos][data-aos-delay="3000"] {
  transition-delay: 0s;
}
body[data-aos-delay="3000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="3000"].aos-animate {
  transition-delay: 3000ms;
}

[data-aos] {
  pointer-events: none;
}
[data-aos].aos-animate {
  pointer-events: auto;
}

body[data-aos-easing=linear] [data-aos], [data-aos][data-aos][data-aos-easing=linear] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}
body[data-aos-easing=ease] [data-aos], [data-aos][data-aos][data-aos-easing=ease] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}
body[data-aos-easing=ease-in] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
}
body[data-aos-easing=ease-out] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}
body[data-aos-easing=ease-in-out] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
body[data-aos-easing=ease-in-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-back] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
body[data-aos-easing=ease-out-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-back] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
body[data-aos-easing=ease-in-out-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-back] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
body[data-aos-easing=ease-in-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-sine] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}
body[data-aos-easing=ease-out-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-sine] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
body[data-aos-easing=ease-in-out-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-sine] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
body[data-aos-easing=ease-in-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-quad] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-quad] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-quad] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
body[data-aos-easing=ease-in-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-cubic] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-cubic] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-cubic] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
body[data-aos-easing=ease-in-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-quart] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-quart] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-quart] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

@media screen {
  html:not(.no-js) {
    /**
    * Fade animations:
    * fade
    * fade-up, fade-down, fade-left, fade-right
    * fade-up-right, fade-up-left, fade-down-right, fade-down-left
    */
    /**
    * Zoom animations:
    * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
    * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
    */
    /**
    * Slide animations
    */
    /**
    * Flip animations:
    * flip-left, flip-right, flip-up, flip-down
    */
  }
  html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
    opacity: 0;
    transition-property: opacity, transform;
  }
  html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    transform: none;
  }
  html:not(.no-js) [data-aos=fade-up] {
    transform: translate3d(0, 100px, 0);
  }
  html:not(.no-js) [data-aos=fade-down] {
    transform: translate3d(0, -100px, 0);
  }
  html:not(.no-js) [data-aos=fade-right] {
    transform: translate3d(-100px, 0, 0);
  }
  html:not(.no-js) [data-aos=fade-left] {
    transform: translate3d(100px, 0, 0);
  }
  html:not(.no-js) [data-aos=fade-up-right] {
    transform: translate3d(-100px, 100px, 0);
  }
  html:not(.no-js) [data-aos=fade-up-left] {
    transform: translate3d(100px, 100px, 0);
  }
  html:not(.no-js) [data-aos=fade-down-right] {
    transform: translate3d(-100px, -100px, 0);
  }
  html:not(.no-js) [data-aos=fade-down-left] {
    transform: translate3d(100px, -100px, 0);
  }
  html:not(.no-js) [data-aos^=zoom][data-aos^=zoom] {
    opacity: 0;
    transition-property: opacity, transform;
  }
  html:not(.no-js) [data-aos^=zoom][data-aos^=zoom].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
  html:not(.no-js) [data-aos=zoom-in] {
    transform: scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-up] {
    transform: translate3d(0, 100px, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-down] {
    transform: translate3d(0, -100px, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-right] {
    transform: translate3d(-100px, 0, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-left] {
    transform: translate3d(100px, 0, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-out] {
    transform: scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-up] {
    transform: translate3d(0, 100px, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-down] {
    transform: translate3d(0, -100px, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-right] {
    transform: translate3d(-100px, 0, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-left] {
    transform: translate3d(100px, 0, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos^=slide][data-aos^=slide] {
    transition-property: transform;
    visibility: hidden;
  }
  html:not(.no-js) [data-aos^=slide][data-aos^=slide].aos-animate {
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
  html:not(.no-js) [data-aos=slide-up] {
    transform: translate3d(0, 100%, 0);
  }
  html:not(.no-js) [data-aos=slide-down] {
    transform: translate3d(0, -100%, 0);
  }
  html:not(.no-js) [data-aos=slide-right] {
    transform: translate3d(-100%, 0, 0);
  }
  html:not(.no-js) [data-aos=slide-left] {
    transform: translate3d(100%, 0, 0);
  }
  html:not(.no-js) [data-aos^=flip][data-aos^=flip] {
    backface-visibility: hidden;
    transition-property: transform;
  }
  html:not(.no-js) [data-aos=flip-left] {
    transform: perspective(2500px) rotateY(-100deg);
  }
  html:not(.no-js) [data-aos=flip-left].aos-animate {
    transform: perspective(2500px) rotateY(0);
  }
  html:not(.no-js) [data-aos=flip-right] {
    transform: perspective(2500px) rotateY(100deg);
  }
  html:not(.no-js) [data-aos=flip-right].aos-animate {
    transform: perspective(2500px) rotateY(0);
  }
  html:not(.no-js) [data-aos=flip-up] {
    transform: perspective(2500px) rotateX(-100deg);
  }
  html:not(.no-js) [data-aos=flip-up].aos-animate {
    transform: perspective(2500px) rotateX(0);
  }
  html:not(.no-js) [data-aos=flip-down] {
    transform: perspective(2500px) rotateX(100deg);
  }
  html:not(.no-js) [data-aos=flip-down].aos-animate {
    transform: perspective(2500px) rotateX(0);
  }
}
/*!
 * Bootstrap v5.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #325aff;
  --bs-indigo: #3546F5;
  --bs-purple: #9647ca;
  --bs-pink: #ff4d62;
  --bs-red: #f96767;
  --bs-orange: #fe8a12;
  --bs-yellow: #ffb016;
  --bs-green: #4BC98D;
  --bs-teal: #11b193;
  --bs-cyan: #2adfff;
  --bs-white: #fff;
  --bs-gray: #785f5f;
  --bs-gray-dark: #412b2b;
  --bs-gray-100: #fef6f6;
  --bs-gray-200: #faf1f1;
  --bs-gray-300: #efe2e2;
  --bs-gray-400: #e3d7d7;
  --bs-gray-500: #b49a9a;
  --bs-gray-600: #785f5f;
  --bs-gray-700: #593e3e;
  --bs-gray-800: #412b2b;
  --bs-gray-900: #301f1f;
  --bs-primary: #6f0000;
  --bs-secondary: #593e3e;
  --bs-success: #4BC98D;
  --bs-info: #2adfff;
  --bs-warning: #ffb016;
  --bs-danger: #ff4d62;
  --bs-white: #fff;
  --bs-light: #fef6f6;
  --bs-gray: #efe2e2;
  --bs-dark: #221515;
  --bs-primary-rgb: 111, 0, 0;
  --bs-secondary-rgb: 89, 62, 62;
  --bs-success-rgb: 75, 201, 141;
  --bs-info-rgb: 42, 223, 255;
  --bs-warning-rgb: 255, 176, 22;
  --bs-danger-rgb: 255, 77, 98;
  --bs-white-rgb: 255, 255, 255;
  --bs-light-rgb: 254, 246, 246;
  --bs-gray-rgb: 239, 226, 226;
  --bs-dark-rgb: 34, 21, 21;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 34, 21, 21;
  --bs-body-color-rgb: 120, 95, 95;
  --bs-body-bg-rgb: 254, 246, 246;
  --bs-font-sans-serif: "Jost", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.9375rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #785f5f;
  --bs-body-bg: #fef6f6;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(34, 21, 21, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.075;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #301f1f;
}

h1, .h1 {
  font-size: calc(1.359375rem + 1.3125vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.34375rem;
  }
}

h2, .h2 {
  font-size: calc(1.3125rem + 0.75vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.875rem;
  }
}

h3, .h3 {
  font-size: calc(1.2890625rem + 0.46875vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.640625rem;
  }
}

h4, .h4 {
  font-size: calc(1.265625rem + 0.1875vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.40625rem;
  }
}

h5, .h5 {
  font-size: 1.1484375rem;
}

h6, .h6 {
  font-size: 0.9375rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 600;
}

small, .small {
  font-size: 0.8203125rem;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #6f0000;
  text-decoration: none;
}
a:hover {
  color: #430000;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.8203125rem;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.8203125rem;
  color: #ff4d62;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.8203125rem;
  color: #fff;
  background-color: #301f1f;
  border-radius: 0.325rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 500;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #b49a9a;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.171875rem;
  font-weight: 400;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 500;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 500;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 500;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 500;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 500;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 500;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.8203125rem;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.171875rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.8203125rem;
  color: #785f5f;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fef6f6;
  border: 1px solid #efe2e2;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.5rem rgba(34, 21, 21, 0.05);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.8203125rem;
  color: #785f5f;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.25rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.25rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 1.5rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 1.5rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 1.75rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 1.75rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 2rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 2rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 2.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 2.5rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 3rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 3rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 3.5rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 3.5rem;
}

.g-11,
.gx-11 {
  --bs-gutter-x: 4rem;
}

.g-11,
.gy-11 {
  --bs-gutter-y: 4rem;
}

.g-12,
.gx-12 {
  --bs-gutter-x: 5rem;
}

.g-12,
.gy-12 {
  --bs-gutter-y: 5rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.25rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.25rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-6,
.gx-sm-6 {
    --bs-gutter-x: 1.75rem;
  }

  .g-sm-6,
.gy-sm-6 {
    --bs-gutter-y: 1.75rem;
  }

  .g-sm-7,
.gx-sm-7 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-7,
.gy-sm-7 {
    --bs-gutter-y: 2rem;
  }

  .g-sm-8,
.gx-sm-8 {
    --bs-gutter-x: 2.5rem;
  }

  .g-sm-8,
.gy-sm-8 {
    --bs-gutter-y: 2.5rem;
  }

  .g-sm-9,
.gx-sm-9 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-9,
.gy-sm-9 {
    --bs-gutter-y: 3rem;
  }

  .g-sm-10,
.gx-sm-10 {
    --bs-gutter-x: 3.5rem;
  }

  .g-sm-10,
.gy-sm-10 {
    --bs-gutter-y: 3.5rem;
  }

  .g-sm-11,
.gx-sm-11 {
    --bs-gutter-x: 4rem;
  }

  .g-sm-11,
.gy-sm-11 {
    --bs-gutter-y: 4rem;
  }

  .g-sm-12,
.gx-sm-12 {
    --bs-gutter-x: 5rem;
  }

  .g-sm-12,
.gy-sm-12 {
    --bs-gutter-y: 5rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.25rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.25rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-6,
.gx-md-6 {
    --bs-gutter-x: 1.75rem;
  }

  .g-md-6,
.gy-md-6 {
    --bs-gutter-y: 1.75rem;
  }

  .g-md-7,
.gx-md-7 {
    --bs-gutter-x: 2rem;
  }

  .g-md-7,
.gy-md-7 {
    --bs-gutter-y: 2rem;
  }

  .g-md-8,
.gx-md-8 {
    --bs-gutter-x: 2.5rem;
  }

  .g-md-8,
.gy-md-8 {
    --bs-gutter-y: 2.5rem;
  }

  .g-md-9,
.gx-md-9 {
    --bs-gutter-x: 3rem;
  }

  .g-md-9,
.gy-md-9 {
    --bs-gutter-y: 3rem;
  }

  .g-md-10,
.gx-md-10 {
    --bs-gutter-x: 3.5rem;
  }

  .g-md-10,
.gy-md-10 {
    --bs-gutter-y: 3.5rem;
  }

  .g-md-11,
.gx-md-11 {
    --bs-gutter-x: 4rem;
  }

  .g-md-11,
.gy-md-11 {
    --bs-gutter-y: 4rem;
  }

  .g-md-12,
.gx-md-12 {
    --bs-gutter-x: 5rem;
  }

  .g-md-12,
.gy-md-12 {
    --bs-gutter-y: 5rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.25rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.25rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 1.75rem;
  }

  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 1.75rem;
  }

  .g-lg-7,
.gx-lg-7 {
    --bs-gutter-x: 2rem;
  }

  .g-lg-7,
.gy-lg-7 {
    --bs-gutter-y: 2rem;
  }

  .g-lg-8,
.gx-lg-8 {
    --bs-gutter-x: 2.5rem;
  }

  .g-lg-8,
.gy-lg-8 {
    --bs-gutter-y: 2.5rem;
  }

  .g-lg-9,
.gx-lg-9 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-9,
.gy-lg-9 {
    --bs-gutter-y: 3rem;
  }

  .g-lg-10,
.gx-lg-10 {
    --bs-gutter-x: 3.5rem;
  }

  .g-lg-10,
.gy-lg-10 {
    --bs-gutter-y: 3.5rem;
  }

  .g-lg-11,
.gx-lg-11 {
    --bs-gutter-x: 4rem;
  }

  .g-lg-11,
.gy-lg-11 {
    --bs-gutter-y: 4rem;
  }

  .g-lg-12,
.gx-lg-12 {
    --bs-gutter-x: 5rem;
  }

  .g-lg-12,
.gy-lg-12 {
    --bs-gutter-y: 5rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-6,
.gx-xl-6 {
    --bs-gutter-x: 1.75rem;
  }

  .g-xl-6,
.gy-xl-6 {
    --bs-gutter-y: 1.75rem;
  }

  .g-xl-7,
.gx-xl-7 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-7,
.gy-xl-7 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-8,
.gx-xl-8 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xl-8,
.gy-xl-8 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xl-9,
.gx-xl-9 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-9,
.gy-xl-9 {
    --bs-gutter-y: 3rem;
  }

  .g-xl-10,
.gx-xl-10 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xl-10,
.gy-xl-10 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xl-11,
.gx-xl-11 {
    --bs-gutter-x: 4rem;
  }

  .g-xl-11,
.gy-xl-11 {
    --bs-gutter-y: 4rem;
  }

  .g-xl-12,
.gx-xl-12 {
    --bs-gutter-x: 5rem;
  }

  .g-xl-12,
.gy-xl-12 {
    --bs-gutter-y: 5rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-6,
.gx-xxl-6 {
    --bs-gutter-x: 1.75rem;
  }

  .g-xxl-6,
.gy-xxl-6 {
    --bs-gutter-y: 1.75rem;
  }

  .g-xxl-7,
.gx-xxl-7 {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-7,
.gy-xxl-7 {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-8,
.gx-xxl-8 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xxl-8,
.gy-xxl-8 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xxl-9,
.gx-xxl-9 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-9,
.gy-xxl-9 {
    --bs-gutter-y: 3rem;
  }

  .g-xxl-10,
.gx-xxl-10 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xxl-10,
.gy-xxl-10 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xxl-11,
.gx-xxl-11 {
    --bs-gutter-x: 4rem;
  }

  .g-xxl-11,
.gy-xxl-11 {
    --bs-gutter-y: 4rem;
  }

  .g-xxl-12,
.gx-xxl-12 {
    --bs-gutter-x: 5rem;
  }

  .g-xxl-12,
.gy-xxl-12 {
    --bs-gutter-y: 5rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #785f5f;
  --bs-table-striped-bg: #fef6f6;
  --bs-table-active-color: #785f5f;
  --bs-table-active-bg: rgba(34, 21, 21, 0.075);
  --bs-table-hover-color: #785f5f;
  --bs-table-hover-bg: rgba(34, 21, 21, 0.05);
  width: 100%;
  margin-bottom: 1rem;
  color: #785f5f;
  vertical-align: top;
  border-color: #faf1f1;
}
.table > :not(caption) > * > * {
  padding: 1rem 0.75rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #f1e6e6;
  --bs-table-striped-bg: #ece1e1;
  --bs-table-striped-color: #221515;
  --bs-table-active-bg: #e1d6d6;
  --bs-table-active-color: #221515;
  --bs-table-hover-bg: #e7dcdc;
  --bs-table-hover-color: #221515;
  color: #221515;
  border-color: #e1d6d6;
}

.table-secondary {
  --bs-table-bg: #eeecec;
  --bs-table-striped-bg: #e9e7e7;
  --bs-table-striped-color: #221515;
  --bs-table-active-bg: #dfdcdc;
  --bs-table-active-color: #221515;
  --bs-table-hover-bg: #e4e1e1;
  --bs-table-hover-color: #221515;
  color: #221515;
  border-color: #dfdcdc;
}

.table-success {
  --bs-table-bg: #edfaf4;
  --bs-table-striped-bg: #e8f4ee;
  --bs-table-striped-color: #221515;
  --bs-table-active-bg: #dee9e3;
  --bs-table-active-color: #221515;
  --bs-table-hover-bg: #e3efe9;
  --bs-table-hover-color: #221515;
  color: #221515;
  border-color: #dee9e3;
}

.table-info {
  --bs-table-bg: #eafcff;
  --bs-table-striped-bg: #e5f6f9;
  --bs-table-striped-color: #221515;
  --bs-table-active-bg: #dbebed;
  --bs-table-active-color: #221515;
  --bs-table-hover-bg: #e0f0f3;
  --bs-table-hover-color: #221515;
  color: #221515;
  border-color: #dbebed;
}

.table-warning {
  --bs-table-bg: #fff7e8;
  --bs-table-striped-bg: #f9f1e3;
  --bs-table-striped-color: #221515;
  --bs-table-active-bg: #eee6d8;
  --bs-table-active-color: #221515;
  --bs-table-hover-bg: #f4ecdd;
  --bs-table-hover-color: #221515;
  color: #221515;
  border-color: #eee6d8;
}

.table-danger {
  --bs-table-bg: #ffedef;
  --bs-table-striped-bg: #f9e8ea;
  --bs-table-striped-color: #221515;
  --bs-table-active-bg: #eedddf;
  --bs-table-active-color: #221515;
  --bs-table-hover-bg: #f4e2e4;
  --bs-table-hover-color: #221515;
  color: #221515;
  border-color: #eedddf;
}

.table-light {
  --bs-table-bg: #fef6f6;
  --bs-table-striped-bg: #f9f0f0;
  --bs-table-striped-color: #221515;
  --bs-table-active-bg: #eee5e5;
  --bs-table-active-color: #221515;
  --bs-table-hover-bg: #f3ebeb;
  --bs-table-hover-color: #221515;
  color: #221515;
  border-color: #eee5e5;
}

.table-dark {
  --bs-table-bg: #221515;
  --bs-table-striped-bg: #281b1b;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #332727;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #2d2121;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #332727;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.475rem + 1px);
  padding-bottom: calc(0.475rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.675rem + 1px);
  padding-bottom: calc(0.675rem + 1px);
  font-size: 1.0546875rem;
}

.col-form-label-sm {
  padding-top: calc(0.325rem + 1px);
  padding-bottom: calc(0.325rem + 1px);
  font-size: 0.8203125rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.8203125rem;
  color: #b49a9a;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.475rem 0.875rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #785f5f;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #efe2e2;
  appearance: none;
  border-radius: 0.5rem;
  box-shadow: inset 0 1px 2px rgba(34, 21, 21, 0.0125);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #785f5f;
  background-color: #fff;
  border-color: #b78080;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(34, 21, 21, 0.0125), 0 0 0.25rem 0.125rem rgba(111, 0, 0, 0.125);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #b49a9a;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #faf1f1;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.475rem 0.875rem;
  margin: -0.475rem -0.875rem;
  margin-inline-end: 0.875rem;
  color: #785f5f;
  background-color: #fef6f6;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #f1eaea;
}
.form-control::-webkit-file-upload-button {
  padding: 0.475rem 0.875rem;
  margin: -0.475rem -0.875rem;
  margin-inline-end: 0.875rem;
  color: #785f5f;
  background-color: #fef6f6;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #f1eaea;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.475rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #785f5f;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.65rem + 2px);
  padding: 0.325rem 0.75rem;
  font-size: 0.8203125rem;
  border-radius: 0.325rem;
}
.form-control-sm::file-selector-button {
  padding: 0.325rem 0.75rem;
  margin: -0.325rem -0.75rem;
  margin-inline-end: 0.75rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.325rem 0.75rem;
  margin: -0.325rem -0.75rem;
  margin-inline-end: 0.75rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1.35rem + 2px);
  padding: 0.675rem 1.5rem;
  font-size: 1.0546875rem;
  border-radius: 0.875rem;
}
.form-control-lg::file-selector-button {
  padding: 0.675rem 1.5rem;
  margin: -0.675rem -1.5rem;
  margin-inline-end: 1.5rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.675rem 1.5rem;
  margin: -0.675rem -1.5rem;
  margin-inline-end: 1.5rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.95rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.65rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1.35rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.475rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.5rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.5rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.475rem 2.625rem 0.475rem 0.875rem;
  -moz-padding-start: calc(0.875rem - 3px);
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #785f5f;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23412b2b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.875rem center;
  background-size: 12px 8px;
  border: 1px solid #efe2e2;
  border-radius: 0.5rem;
  box-shadow: inset 0 1px 2px rgba(34, 21, 21, 0.0125);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #b78080;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(34, 21, 21, 0.0125), 0 0 0.25rem 0.125rem rgba(111, 0, 0, 0.125);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.875rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #fef6f6;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #785f5f;
}

.form-select-sm {
  padding-top: 0.325rem;
  padding-bottom: 0.325rem;
  padding-left: 0.75rem;
  font-size: 0.8203125rem;
}

.form-select-lg {
  padding-top: 0.675rem;
  padding-bottom: 0.675rem;
  padding-left: 1.5rem;
  font-size: 1.0546875rem;
}

.form-check {
  display: block;
  min-height: 1.40625rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #faf1f1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 0px;
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: 0px;
  outline: 0;
  box-shadow: none;
}
.form-check-input:checked {
  background-color: #6f0000;
  border-color: #6f0000;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #6f0000;
  border-color: #6f0000;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 3em;
}
.form-switch .form-check-input {
  width: 2.5em;
  margin-left: -3em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23b78080'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2.5em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23b78080'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.25rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fef6f6, 0 0 0.25rem 0.125rem rgba(111, 0, 0, 0.125);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fef6f6, 0 0 0.25rem 0.125rem rgba(111, 0, 0, 0.125);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #6f0000;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(34, 21, 21, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #d4b3b3;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #efe2e2;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(34, 21, 21, 0.0125);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #6f0000;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(34, 21, 21, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #d4b3b3;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #efe2e2;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(34, 21, 21, 0.0125);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #b49a9a;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #b49a9a;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.875rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.875rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.475rem 0.875rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #785f5f;
  text-align: center;
  white-space: nowrap;
  background-color: #fef6f6;
  border: 1px solid #efe2e2;
  border-radius: 0.5rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.675rem 1.5rem;
  font-size: 1.0546875rem;
  border-radius: 0.875rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.325rem 0.75rem;
  font-size: 0.8203125rem;
  border-radius: 0.325rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3.5rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.8203125rem;
  color: #4BC98D;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8203125rem;
  color: #fff;
  background-color: rgba(75, 201, 141, 0.95);
  border-radius: 0.5rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
ngb-timepicker.ng-touched.ng-valid input ~ .valid-feedback,
.ng-submitted ngb-timepicker.ng-valid input ~ .valid-feedback,
.ng-touched.ng-valid ~ .valid-feedback,
.ng-submitted .ng-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
ngb-timepicker.ng-touched.ng-valid input ~ .valid-tooltip,
.ng-submitted ngb-timepicker.ng-valid input ~ .valid-tooltip,
.ng-touched.ng-valid ~ .valid-tooltip,
.ng-submitted .ng-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid, ngb-timepicker.ng-touched.ng-valid input.form-control, .ng-submitted ngb-timepicker.ng-valid input.form-control, .form-control.ng-touched.ng-valid, .ng-submitted .form-control.ng-valid {
  border-color: #4BC98D;
  padding-right: calc(1.5em + 0.95rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234BC98D' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.2375rem) center;
  background-size: calc(0.75em + 0.475rem) calc(0.75em + 0.475rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus, ngb-timepicker.ng-touched.ng-valid input.form-control:focus, .ng-submitted ngb-timepicker.ng-valid input.form-control:focus, .form-control.ng-touched.ng-valid:focus, .ng-submitted .form-control.ng-valid:focus {
  border-color: #4BC98D;
  box-shadow: 0 0 0.25rem 0.125rem rgba(75, 201, 141, 0.125);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid, textarea.form-control.ng-touched.ng-valid, .ng-submitted textarea.form-control.ng-valid {
  padding-right: calc(1.5em + 0.95rem);
  background-position: top calc(0.375em + 0.2375rem) right calc(0.375em + 0.2375rem);
}

.was-validated .form-select:valid, .form-select.is-valid, ngb-timepicker.ng-touched.ng-valid input.form-select, .ng-submitted ngb-timepicker.ng-valid input.form-select, .form-select.ng-touched.ng-valid, .ng-submitted .form-select.ng-valid {
  border-color: #4BC98D;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), ngb-timepicker.ng-touched.ng-valid input.form-select:not([multiple]):not([size]), .ng-submitted ngb-timepicker.ng-valid input.form-select:not([multiple]):not([size]), .form-select.ng-touched.ng-valid:not([multiple]):not([size]), .ng-submitted .form-select.ng-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"], ngb-timepicker.ng-touched.ng-valid input.form-select:not([multiple])[size="1"], .ng-submitted ngb-timepicker.ng-valid input.form-select:not([multiple])[size="1"], .form-select.ng-touched.ng-valid:not([multiple])[size="1"], .ng-submitted .form-select.ng-valid:not([multiple])[size="1"] {
  padding-right: 4.8125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23412b2b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234BC98D' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.875rem center, center right 2.625rem;
  background-size: 12px 8px, calc(0.75em + 0.475rem) calc(0.75em + 0.475rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus, ngb-timepicker.ng-touched.ng-valid input.form-select:focus, .ng-submitted ngb-timepicker.ng-valid input.form-select:focus, .form-select.ng-touched.ng-valid:focus, .ng-submitted .form-select.ng-valid:focus {
  border-color: #4BC98D;
  box-shadow: 0 0 0.25rem 0.125rem rgba(75, 201, 141, 0.125);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid, ngb-timepicker.ng-touched.ng-valid input.form-check-input, .ng-submitted ngb-timepicker.ng-valid input.form-check-input, .form-check-input.ng-touched.ng-valid, .ng-submitted .form-check-input.ng-valid {
  border-color: #4BC98D;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked, ngb-timepicker.ng-touched.ng-valid input.form-check-input:checked, .ng-submitted ngb-timepicker.ng-valid input.form-check-input:checked, .form-check-input.ng-touched.ng-valid:checked, .ng-submitted .form-check-input.ng-valid:checked {
  background-color: #4BC98D;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus, ngb-timepicker.ng-touched.ng-valid input.form-check-input:focus, .ng-submitted ngb-timepicker.ng-valid input.form-check-input:focus, .form-check-input.ng-touched.ng-valid:focus, .ng-submitted .form-check-input.ng-valid:focus {
  box-shadow: 0 0 0.25rem 0.125rem rgba(75, 201, 141, 0.125);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label, ngb-timepicker.ng-touched.ng-valid input.form-check-input ~ .form-check-label, .ng-submitted ngb-timepicker.ng-valid input.form-check-input ~ .form-check-label, .form-check-input.ng-touched.ng-valid ~ .form-check-label, .ng-submitted .form-check-input.ng-valid ~ .form-check-label {
  color: #4BC98D;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .input-group ngb-timepicker.ng-touched.ng-valid input.form-control, ngb-timepicker.ng-touched.ng-valid .input-group input.form-control, .input-group .ng-submitted ngb-timepicker.ng-valid input.form-control, .ng-submitted ngb-timepicker.ng-valid .input-group input.form-control, .input-group .form-control.ng-touched.ng-valid, .input-group .ng-submitted .form-control.ng-valid, .ng-submitted .input-group .form-control.ng-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid,
.input-group ngb-timepicker.ng-touched.ng-valid input.form-select,
ngb-timepicker.ng-touched.ng-valid .input-group input.form-select,
.input-group .ng-submitted ngb-timepicker.ng-valid input.form-select,
.ng-submitted ngb-timepicker.ng-valid .input-group input.form-select,
.input-group .form-select.ng-touched.ng-valid,
.input-group .ng-submitted .form-select.ng-valid,
.ng-submitted .input-group .form-select.ng-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .input-group ngb-timepicker.ng-touched.ng-valid input.form-control:focus, ngb-timepicker.ng-touched.ng-valid .input-group input.form-control:focus, .input-group .ng-submitted ngb-timepicker.ng-valid input.form-control:focus, .ng-submitted ngb-timepicker.ng-valid .input-group input.form-control:focus, .input-group .form-control.ng-touched.ng-valid:focus, .input-group .ng-submitted .form-control.ng-valid:focus, .ng-submitted .input-group .form-control.ng-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus,
.input-group ngb-timepicker.ng-touched.ng-valid input.form-select:focus,
ngb-timepicker.ng-touched.ng-valid .input-group input.form-select:focus,
.input-group .ng-submitted ngb-timepicker.ng-valid input.form-select:focus,
.ng-submitted ngb-timepicker.ng-valid .input-group input.form-select:focus,
.input-group .form-select.ng-touched.ng-valid:focus,
.input-group .ng-submitted .form-select.ng-valid:focus,
.ng-submitted .input-group .form-select.ng-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.8203125rem;
  color: #ff4d62;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8203125rem;
  color: #fff;
  background-color: rgba(255, 77, 98, 0.95);
  border-radius: 0.5rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
ngb-timepicker.ng-touched.ng-invalid input ~ .invalid-feedback,
.ng-submitted ngb-timepicker.ng-invalid input ~ .invalid-feedback,
.ng-touched.ng-invalid ~ .invalid-feedback,
.ng-submitted .ng-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
ngb-timepicker.ng-touched.ng-invalid input ~ .invalid-tooltip,
.ng-submitted ngb-timepicker.ng-invalid input ~ .invalid-tooltip,
.ng-touched.ng-invalid ~ .invalid-tooltip,
.ng-submitted .ng-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid, ngb-timepicker.ng-touched.ng-invalid input.form-control, .ng-submitted ngb-timepicker.ng-invalid input.form-control, .form-control.ng-touched.ng-invalid, .ng-submitted .form-control.ng-invalid {
  border-color: #ff4d62;
  padding-right: calc(1.5em + 0.95rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff4d62'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff4d62' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.2375rem) center;
  background-size: calc(0.75em + 0.475rem) calc(0.75em + 0.475rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, ngb-timepicker.ng-touched.ng-invalid input.form-control:focus, .ng-submitted ngb-timepicker.ng-invalid input.form-control:focus, .form-control.ng-touched.ng-invalid:focus, .ng-submitted .form-control.ng-invalid:focus {
  border-color: #ff4d62;
  box-shadow: 0 0 0.25rem 0.125rem rgba(255, 77, 98, 0.125);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid, textarea.form-control.ng-touched.ng-invalid, .ng-submitted textarea.form-control.ng-invalid {
  padding-right: calc(1.5em + 0.95rem);
  background-position: top calc(0.375em + 0.2375rem) right calc(0.375em + 0.2375rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid, ngb-timepicker.ng-touched.ng-invalid input.form-select, .ng-submitted ngb-timepicker.ng-invalid input.form-select, .form-select.ng-touched.ng-invalid, .ng-submitted .form-select.ng-invalid {
  border-color: #ff4d62;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), ngb-timepicker.ng-touched.ng-invalid input.form-select:not([multiple]):not([size]), .ng-submitted ngb-timepicker.ng-invalid input.form-select:not([multiple]):not([size]), .form-select.ng-touched.ng-invalid:not([multiple]):not([size]), .ng-submitted .form-select.ng-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"], ngb-timepicker.ng-touched.ng-invalid input.form-select:not([multiple])[size="1"], .ng-submitted ngb-timepicker.ng-invalid input.form-select:not([multiple])[size="1"], .form-select.ng-touched.ng-invalid:not([multiple])[size="1"], .ng-submitted .form-select.ng-invalid:not([multiple])[size="1"] {
  padding-right: 4.8125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23412b2b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff4d62'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff4d62' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.875rem center, center right 2.625rem;
  background-size: 12px 8px, calc(0.75em + 0.475rem) calc(0.75em + 0.475rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus, ngb-timepicker.ng-touched.ng-invalid input.form-select:focus, .ng-submitted ngb-timepicker.ng-invalid input.form-select:focus, .form-select.ng-touched.ng-invalid:focus, .ng-submitted .form-select.ng-invalid:focus {
  border-color: #ff4d62;
  box-shadow: 0 0 0.25rem 0.125rem rgba(255, 77, 98, 0.125);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid, ngb-timepicker.ng-touched.ng-invalid input.form-check-input, .ng-submitted ngb-timepicker.ng-invalid input.form-check-input, .form-check-input.ng-touched.ng-invalid, .ng-submitted .form-check-input.ng-invalid {
  border-color: #ff4d62;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked, ngb-timepicker.ng-touched.ng-invalid input.form-check-input:checked, .ng-submitted ngb-timepicker.ng-invalid input.form-check-input:checked, .form-check-input.ng-touched.ng-invalid:checked, .ng-submitted .form-check-input.ng-invalid:checked {
  background-color: #ff4d62;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus, ngb-timepicker.ng-touched.ng-invalid input.form-check-input:focus, .ng-submitted ngb-timepicker.ng-invalid input.form-check-input:focus, .form-check-input.ng-touched.ng-invalid:focus, .ng-submitted .form-check-input.ng-invalid:focus {
  box-shadow: 0 0 0.25rem 0.125rem rgba(255, 77, 98, 0.125);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label, ngb-timepicker.ng-touched.ng-invalid input.form-check-input ~ .form-check-label, .ng-submitted ngb-timepicker.ng-invalid input.form-check-input ~ .form-check-label, .form-check-input.ng-touched.ng-invalid ~ .form-check-label, .ng-submitted .form-check-input.ng-invalid ~ .form-check-label {
  color: #ff4d62;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .input-group ngb-timepicker.ng-touched.ng-invalid input.form-control, ngb-timepicker.ng-touched.ng-invalid .input-group input.form-control, .input-group .ng-submitted ngb-timepicker.ng-invalid input.form-control, .ng-submitted ngb-timepicker.ng-invalid .input-group input.form-control, .input-group .form-control.ng-touched.ng-invalid, .input-group .ng-submitted .form-control.ng-invalid, .ng-submitted .input-group .form-control.ng-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid,
.input-group ngb-timepicker.ng-touched.ng-invalid input.form-select,
ngb-timepicker.ng-touched.ng-invalid .input-group input.form-select,
.input-group .ng-submitted ngb-timepicker.ng-invalid input.form-select,
.ng-submitted ngb-timepicker.ng-invalid .input-group input.form-select,
.input-group .form-select.ng-touched.ng-invalid,
.input-group .ng-submitted .form-select.ng-invalid,
.ng-submitted .input-group .form-select.ng-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .input-group ngb-timepicker.ng-touched.ng-invalid input.form-control:focus, ngb-timepicker.ng-touched.ng-invalid .input-group input.form-control:focus, .input-group .ng-submitted ngb-timepicker.ng-invalid input.form-control:focus, .ng-submitted ngb-timepicker.ng-invalid .input-group input.form-control:focus, .input-group .form-control.ng-touched.ng-invalid:focus, .input-group .ng-submitted .form-control.ng-invalid:focus, .ng-submitted .input-group .form-control.ng-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus,
.input-group ngb-timepicker.ng-touched.ng-invalid input.form-select:focus,
ngb-timepicker.ng-touched.ng-invalid .input-group input.form-select:focus,
.input-group .ng-submitted ngb-timepicker.ng-invalid input.form-select:focus,
.ng-submitted ngb-timepicker.ng-invalid .input-group input.form-select:focus,
.input-group .form-select.ng-touched.ng-invalid:focus,
.input-group .ng-submitted .form-select.ng-invalid:focus,
.ng-submitted .input-group .form-select.ng-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #785f5f;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.475rem 0.875rem;
  font-size: 0.9375rem;
  border-radius: 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #785f5f;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 transparent;
}
.btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active {
  box-shadow: 0 0 0 transparent;
}
.btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 transparent;
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
  box-shadow: none;
}

.btn-primary {
  color: #fff;
  background-color: #6f0000;
  border-color: #6f0000;
  box-shadow: 0 0 0 transparent;
}
.btn-primary:hover {
  color: #fff;
  background-color: #530000;
  border-color: #590000;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #530000;
  border-color: #590000;
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(133, 38, 38, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #590000;
  border-color: #530000;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(133, 38, 38, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #6f0000;
  border-color: #6f0000;
}

.btn-secondary {
  color: #fff;
  background-color: #593e3e;
  border-color: #593e3e;
  box-shadow: 0 0 0 transparent;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #432f2f;
  border-color: #473232;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #432f2f;
  border-color: #473232;
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(114, 91, 91, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #473232;
  border-color: #432f2f;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(114, 91, 91, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #593e3e;
  border-color: #593e3e;
}

.btn-success {
  color: #fff;
  background-color: #4BC98D;
  border-color: #4BC98D;
  box-shadow: 0 0 0 transparent;
}
.btn-success:hover {
  color: #fff;
  background-color: #38976a;
  border-color: #3ca171;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #38976a;
  border-color: #3ca171;
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(102, 209, 158, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #3ca171;
  border-color: #38976a;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(102, 209, 158, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #4BC98D;
  border-color: #4BC98D;
}

.btn-info {
  color: #221515;
  background-color: #2adfff;
  border-color: #2adfff;
  box-shadow: 0 0 0 transparent;
}
.btn-info:hover {
  color: #221515;
  background-color: #5fe7ff;
  border-color: #3fe2ff;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #221515;
  background-color: #5fe7ff;
  border-color: #3fe2ff;
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(41, 193, 220, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #221515;
  background-color: #55e5ff;
  border-color: #3fe2ff;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(41, 193, 220, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #221515;
  background-color: #2adfff;
  border-color: #2adfff;
}

.btn-warning {
  color: #221515;
  background-color: #ffb016;
  border-color: #ffb016;
  box-shadow: 0 0 0 transparent;
}
.btn-warning:hover {
  color: #221515;
  background-color: #ffc450;
  border-color: #ffb82d;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #221515;
  background-color: #ffc450;
  border-color: #ffb82d;
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(222, 153, 22, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #221515;
  background-color: #ffc045;
  border-color: #ffb82d;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(222, 153, 22, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #221515;
  background-color: #ffb016;
  border-color: #ffb016;
}

.btn-danger {
  color: #fff;
  background-color: #ff4d62;
  border-color: #ff4d62;
  box-shadow: 0 0 0 transparent;
}
.btn-danger:hover {
  color: #fff;
  background-color: #bf3a4a;
  border-color: #cc3e4e;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bf3a4a;
  border-color: #cc3e4e;
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(255, 104, 122, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #cc3e4e;
  border-color: #bf3a4a;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(255, 104, 122, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #ff4d62;
  border-color: #ff4d62;
}

.btn-white {
  color: #221515;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 0 0 transparent;
}
.btn-white:hover {
  color: #221515;
  background-color: white;
  border-color: white;
}
.btn-check:focus + .btn-white, .btn-white:focus {
  color: #221515;
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(222, 220, 220, 0.5);
}
.btn-check:checked + .btn-white, .btn-check:active + .btn-white, .btn-white:active, .btn-white.active, .show > .btn-white.dropdown-toggle {
  color: #221515;
  background-color: white;
  border-color: white;
}
.btn-check:checked + .btn-white:focus, .btn-check:active + .btn-white:focus, .btn-white:active:focus, .btn-white.active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(222, 220, 220, 0.5);
}
.btn-white:disabled, .btn-white.disabled {
  color: #221515;
  background-color: #fff;
  border-color: #fff;
}

.btn-light {
  color: #221515;
  background-color: #fef6f6;
  border-color: #fef6f6;
  box-shadow: 0 0 0 transparent;
}
.btn-light:hover {
  color: #221515;
  background-color: #fef8f8;
  border-color: #fef7f7;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #221515;
  background-color: #fef8f8;
  border-color: #fef7f7;
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(221, 212, 212, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #221515;
  background-color: #fef8f8;
  border-color: #fef7f7;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(221, 212, 212, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #221515;
  background-color: #fef6f6;
  border-color: #fef6f6;
}

.btn-gray {
  color: #221515;
  background-color: #efe2e2;
  border-color: #efe2e2;
  box-shadow: 0 0 0 transparent;
}
.btn-gray:hover {
  color: #221515;
  background-color: #f3e9e9;
  border-color: #f1e5e5;
}
.btn-check:focus + .btn-gray, .btn-gray:focus {
  color: #221515;
  background-color: #f3e9e9;
  border-color: #f1e5e5;
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(208, 195, 195, 0.5);
}
.btn-check:checked + .btn-gray, .btn-check:active + .btn-gray, .btn-gray:active, .btn-gray.active, .show > .btn-gray.dropdown-toggle {
  color: #221515;
  background-color: #f2e8e8;
  border-color: #f1e5e5;
}
.btn-check:checked + .btn-gray:focus, .btn-check:active + .btn-gray:focus, .btn-gray:active:focus, .btn-gray.active:focus, .show > .btn-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(208, 195, 195, 0.5);
}
.btn-gray:disabled, .btn-gray.disabled {
  color: #221515;
  background-color: #efe2e2;
  border-color: #efe2e2;
}

.btn-dark {
  color: #fff;
  background-color: #221515;
  border-color: #221515;
  box-shadow: 0 0 0 transparent;
}
.btn-dark:hover {
  color: #fff;
  background-color: #1a1010;
  border-color: #1b1111;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #1a1010;
  border-color: #1b1111;
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(67, 56, 56, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1b1111;
  border-color: #1a1010;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(67, 56, 56, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #221515;
  border-color: #221515;
}

.btn-outline-primary {
  color: #6f0000;
  border-color: #6f0000;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #6f0000;
  border-color: #6f0000;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0 rgba(111, 0, 0, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #6f0000;
  border-color: #6f0000;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(111, 0, 0, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #6f0000;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #593e3e;
  border-color: #593e3e;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #593e3e;
  border-color: #593e3e;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0 rgba(89, 62, 62, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #593e3e;
  border-color: #593e3e;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(89, 62, 62, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #593e3e;
  background-color: transparent;
}

.btn-outline-success {
  color: #4BC98D;
  border-color: #4BC98D;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #4BC98D;
  border-color: #4BC98D;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0 rgba(75, 201, 141, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #4BC98D;
  border-color: #4BC98D;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(75, 201, 141, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #4BC98D;
  background-color: transparent;
}

.btn-outline-info {
  color: #2adfff;
  border-color: #2adfff;
}
.btn-outline-info:hover {
  color: #221515;
  background-color: #2adfff;
  border-color: #2adfff;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0 rgba(42, 223, 255, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #221515;
  background-color: #2adfff;
  border-color: #2adfff;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(42, 223, 255, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #2adfff;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffb016;
  border-color: #ffb016;
}
.btn-outline-warning:hover {
  color: #221515;
  background-color: #ffb016;
  border-color: #ffb016;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0 rgba(255, 176, 22, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #221515;
  background-color: #ffb016;
  border-color: #ffb016;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(255, 176, 22, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffb016;
  background-color: transparent;
}

.btn-outline-danger {
  color: #ff4d62;
  border-color: #ff4d62;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #ff4d62;
  border-color: #ff4d62;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0 rgba(255, 77, 98, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #ff4d62;
  border-color: #ff4d62;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(255, 77, 98, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #ff4d62;
  background-color: transparent;
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}
.btn-outline-white:hover {
  color: #221515;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:focus + .btn-outline-white, .btn-outline-white:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-outline-white, .btn-check:active + .btn-outline-white, .btn-outline-white:active, .btn-outline-white.active, .btn-outline-white.dropdown-toggle.show {
  color: #221515;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:checked + .btn-outline-white:focus, .btn-check:active + .btn-outline-white:focus, .btn-outline-white:active:focus, .btn-outline-white.active:focus, .btn-outline-white.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.btn-outline-white:disabled, .btn-outline-white.disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-light {
  color: #fef6f6;
  border-color: #fef6f6;
}
.btn-outline-light:hover {
  color: #221515;
  background-color: #fef6f6;
  border-color: #fef6f6;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0 rgba(254, 246, 246, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #221515;
  background-color: #fef6f6;
  border-color: #fef6f6;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(254, 246, 246, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #fef6f6;
  background-color: transparent;
}

.btn-outline-gray {
  color: #efe2e2;
  border-color: #efe2e2;
}
.btn-outline-gray:hover {
  color: #221515;
  background-color: #efe2e2;
  border-color: #efe2e2;
}
.btn-check:focus + .btn-outline-gray, .btn-outline-gray:focus {
  box-shadow: 0 0 0 0 rgba(239, 226, 226, 0.5);
}
.btn-check:checked + .btn-outline-gray, .btn-check:active + .btn-outline-gray, .btn-outline-gray:active, .btn-outline-gray.active, .btn-outline-gray.dropdown-toggle.show {
  color: #221515;
  background-color: #efe2e2;
  border-color: #efe2e2;
}
.btn-check:checked + .btn-outline-gray:focus, .btn-check:active + .btn-outline-gray:focus, .btn-outline-gray:active:focus, .btn-outline-gray.active:focus, .btn-outline-gray.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(239, 226, 226, 0.5);
}
.btn-outline-gray:disabled, .btn-outline-gray.disabled {
  color: #efe2e2;
  background-color: transparent;
}

.btn-outline-dark {
  color: #221515;
  border-color: #221515;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #221515;
  border-color: #221515;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0 rgba(34, 21, 21, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #221515;
  border-color: #221515;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0 rgba(34, 21, 21, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #221515;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #785f5f;
  text-decoration: none;
}
.btn-link:hover {
  color: #6f0000;
}
.btn-link:disabled, .btn-link.disabled {
  color: #b49a9a;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.675rem 1.5rem;
  font-size: 1.0546875rem;
  border-radius: 0.875rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.325rem 0.75rem;
  font-size: 0.8203125rem;
  border-radius: 0.325rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 12rem;
  padding: 0.75rem 0.75rem;
  margin: 0;
  font-size: 0.9375rem;
  color: #785f5f;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #faf1f1;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(34, 21, 21, 0.04);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #faf1f1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #785f5f;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #6f0000;
  background-color: #fef6f6;
}
.dropdown-item.active, .dropdown-item:active {
  color: #6f0000;
  text-decoration: none;
  background-color: #faf1f1;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #b49a9a;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.75rem 1rem;
  margin-bottom: 0;
  font-size: 0.8203125rem;
  color: #785f5f;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #785f5f;
}

.dropdown-menu-dark {
  color: #efe2e2;
  background-color: #412b2b;
  border-color: #412b2b;
}
.dropdown-menu-dark .dropdown-item {
  color: #efe2e2;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #6f0000;
  background-color: #faf1f1;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #b49a9a;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: #faf1f1;
}
.dropdown-menu-dark .dropdown-item-text {
  color: #efe2e2;
}
.dropdown-menu-dark .dropdown-header {
  color: #b49a9a;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.65625rem;
  padding-left: 0.65625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: 0 0 0 transparent;
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #6f0000;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #430000;
}
.nav-link.disabled {
  color: #785f5f;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #efe2e2;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #faf1f1 #faf1f1 #efe2e2;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #785f5f;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #593e3e;
  background-color: #fef6f6;
  border-color: #efe2e2 #efe2e2 #fef6f6;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.5rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #6f0000;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.341796875rem;
  padding-bottom: 0.341796875rem;
  margin-right: 1rem;
  font-size: 1.1484375rem;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.1484375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
.navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
.navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
.navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
.navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
.navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: #6f0000;
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #6f0000;
}
.navbar-light .navbar-nav .nav-link {
  color: #785f5f;
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #6f0000;
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: #b49a9a;
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: #6f0000;
}
.navbar-light .navbar-toggler {
  color: #785f5f;
  border-color: rgba(34, 21, 21, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23785f5f' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: #785f5f;
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: #6f0000;
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.65);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: white;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.65);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.65%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.65);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #faf1f1;
  border-radius: 0.875rem;
  box-shadow: 0 0.75rem 1.5rem rgba(34, 21, 21, 0.03);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.875rem - 1px);
  border-top-right-radius: calc(0.875rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.875rem - 1px);
  border-bottom-left-radius: calc(0.875rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.9375rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid #faf1f1;
}
.card-header:first-child {
  border-radius: calc(0.875rem - 1px) calc(0.875rem - 1px) 0 0;
}

.card-footer {
  padding: 0.9375rem 1.25rem;
  background-color: #fff;
  border-top: 1px solid #faf1f1;
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.875rem - 1px) calc(0.875rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.9375rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: #fff;
  border-bottom-color: #fff;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.875rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.875rem - 1px);
  border-top-right-radius: calc(0.875rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.875rem - 1px);
  border-bottom-left-radius: calc(0.875rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.25rem 1.25rem;
  font-size: 0.9375rem;
  color: #785f5f;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #640000;
  background-color: #f1e6e6;
  box-shadow: inset 0 -1px 0 #faf1f1;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23640000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 0.75rem;
  height: 0.75rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23785f5f'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 0.75rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #b78080;
  outline: 0;
  box-shadow: 0 0 0 transparent;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid #faf1f1;
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-bottom-left-radius: calc(0.5rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.accordion-body {
  padding: 1.25rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  font-size: 0.8203125rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #e3d7d7;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #b49a9a;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #6f0000;
  background-color: #fff;
  border: 1px solid #faf1f1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #430000;
  background-color: #fef6f6;
  border-color: #efe2e2;
}
.page-link:focus {
  z-index: 3;
  color: #430000;
  background-color: #fef6f6;
  outline: 0;
  box-shadow: 0 0 0.25rem 0.125rem rgba(111, 0, 0, 0.125);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #6f0000;
  border-color: #6f0000;
}
.page-item.disabled .page-link {
  color: #b49a9a;
  pointer-events: none;
  background-color: #fff;
  border-color: #faf1f1;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.1484375rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.875rem;
  border-bottom-left-radius: 0.875rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.875rem;
  border-bottom-right-radius: 0.875rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.8203125rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.325rem;
  border-bottom-left-radius: 0.325rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.325rem;
  border-bottom-right-radius: 0.325rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.835em;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.5rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.5rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 500;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #430000;
  background-color: #e2cccc;
  border-color: #d4b3b3;
}
.alert-primary .alert-link {
  color: #360000;
}

.alert-secondary {
  color: #352525;
  background-color: #ded8d8;
  border-color: #cdc5c5;
}
.alert-secondary .alert-link {
  color: #2a1e1e;
}

.alert-success {
  color: #2d7955;
  background-color: #dbf4e8;
  border-color: #c9efdd;
}
.alert-success .alert-link {
  color: #246144;
}

.alert-info {
  color: #198699;
  background-color: #d4f9ff;
  border-color: #bff5ff;
}
.alert-info .alert-link {
  color: #146b7a;
}

.alert-warning {
  color: #996a0d;
  background-color: #ffefd0;
  border-color: #ffe7b9;
}
.alert-warning .alert-link {
  color: #7a550a;
}

.alert-danger {
  color: #992e3b;
  background-color: #ffdbe0;
  border-color: #ffcad0;
}
.alert-danger .alert-link {
  color: #7a252f;
}

.alert-white {
  color: #999999;
  background-color: white;
  border-color: white;
}
.alert-white .alert-link {
  color: #7a7a7a;
}

.alert-light {
  color: #989494;
  background-color: #fffdfd;
  border-color: #fffcfc;
}
.alert-light .alert-link {
  color: #7a7676;
}

.alert-gray {
  color: #8f8888;
  background-color: #fcf9f9;
  border-color: #faf6f6;
}
.alert-gray .alert-link {
  color: #726d6d;
}

.alert-dark {
  color: #140d0d;
  background-color: #d3d0d0;
  border-color: #bdb9b9;
}
.alert-dark .alert-link {
  color: #100a0a;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.703125rem;
  background-color: #faf1f1;
  border-radius: 0.5rem;
  box-shadow: inset 0 1px 2px rgba(34, 21, 21, 0.0125);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #6f0000;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.5rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #593e3e;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #593e3e;
  text-decoration: none;
  background-color: #fef6f6;
}
.list-group-item-action:active {
  color: #785f5f;
  background-color: #faf1f1;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1rem;
  color: #785f5f;
  background-color: #fff;
  border: 1px solid #faf1f1;
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #785f5f;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #785f5f;
  background-color: #faf1f1;
  border-color: #faf1f1;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.5rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #430000;
  background-color: #e2cccc;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #430000;
  background-color: #cbb8b8;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #430000;
  border-color: #430000;
}

.list-group-item-secondary {
  color: #352525;
  background-color: #ded8d8;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #352525;
  background-color: #c8c2c2;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #352525;
  border-color: #352525;
}

.list-group-item-success {
  color: #2d7955;
  background-color: #dbf4e8;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #2d7955;
  background-color: #c5dcd1;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #2d7955;
  border-color: #2d7955;
}

.list-group-item-info {
  color: #198699;
  background-color: #d4f9ff;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #198699;
  background-color: #bfe0e6;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #198699;
  border-color: #198699;
}

.list-group-item-warning {
  color: #996a0d;
  background-color: #ffefd0;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #996a0d;
  background-color: #e6d7bb;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #996a0d;
  border-color: #996a0d;
}

.list-group-item-danger {
  color: #992e3b;
  background-color: #ffdbe0;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #992e3b;
  background-color: #e6c5ca;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #992e3b;
  border-color: #992e3b;
}

.list-group-item-white {
  color: #999999;
  background-color: white;
}
.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #999999;
  background-color: #e6e6e6;
}
.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #999999;
  border-color: #999999;
}

.list-group-item-light {
  color: #989494;
  background-color: #fffdfd;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #989494;
  background-color: #e6e4e4;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #989494;
  border-color: #989494;
}

.list-group-item-gray {
  color: #8f8888;
  background-color: #fcf9f9;
}
.list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
  color: #8f8888;
  background-color: #e3e0e0;
}
.list-group-item-gray.list-group-item-action.active {
  color: #fff;
  background-color: #8f8888;
  border-color: #8f8888;
}

.list-group-item-dark {
  color: #140d0d;
  background-color: #d3d0d0;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #140d0d;
  background-color: #bebbbb;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #140d0d;
  border-color: #140d0d;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #221515;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23221515'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.5rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #221515;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0.25rem 0.125rem rgba(111, 0, 0, 0.125);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid #faf1f1;
  box-shadow: 0 0.5rem 1rem rgba(34, 21, 21, 0.04);
  border-radius: 0.5rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #785f5f;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid #faf1f1;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px solid transparent;
  border-radius: 0.875rem;
  box-shadow: 0 0.5rem 1rem rgba(34, 21, 21, 0.04);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #221515;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.75;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1.25rem;
  border-bottom: 1px solid #faf1f1;
  border-top-left-radius: calc(0.875rem - 0px);
  border-top-right-radius: calc(0.875rem - 0px);
}
.modal-header .btn-close {
  padding: 0.625rem 0.625rem;
  margin: -0.625rem -0.625rem -0.625rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #faf1f1;
  border-bottom-right-radius: calc(0.875rem - 0px);
  border-bottom-left-radius: calc(0.875rem - 0px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-content {
    box-shadow: 0 0.75rem 1.5rem rgba(34, 21, 21, 0.03);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8203125rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.95;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #6f0000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #6f0000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #6f0000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #6f0000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #6f0000;
  border-radius: 0.5rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8203125rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #faf1f1;
  border-radius: 0.875rem;
  box-shadow: 0 0.5rem 1rem rgba(34, 21, 21, 0.04);
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #faf1f1;
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #faf1f1;
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #faf1f1;
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #faf1f1;
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.9375rem;
  color: #301f1f;
  background-color: #f0f0f0;
  border-bottom: 1px solid #faf1f1;
  border-top-left-radius: calc(0.875rem - 1px);
  border-top-right-radius: calc(0.875rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #785f5f;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #221515;
}
.carousel-dark .carousel-caption {
  color: #221515;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  box-shadow: 0 0.5rem 1rem rgba(34, 21, 21, 0.04);
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #221515;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.75;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1.25rem;
}
.offcanvas-header .btn-close {
  padding: 0.625rem 0.625rem;
  margin-top: -0.625rem;
  margin-right: -0.625rem;
  margin-bottom: -0.625rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1.25rem 1.25rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 0px solid transparent;
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 0px solid transparent;
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 0px solid transparent;
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 0px solid transparent;
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #221515 55%, rgba(0, 0, 0, 0.8) 75%, #221515 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #6f0000;
}
.link-primary:hover, .link-primary:focus {
  color: #430000;
}

.link-secondary {
  color: #593e3e;
}
.link-secondary:hover, .link-secondary:focus {
  color: #352525;
}

.link-success {
  color: #4BC98D;
}
.link-success:hover, .link-success:focus {
  color: #2d7955;
}

.link-info {
  color: #2adfff;
}
.link-info:hover, .link-info:focus {
  color: #7fecff;
}

.link-warning {
  color: #ffb016;
}
.link-warning:hover, .link-warning:focus {
  color: #ffd073;
}

.link-danger {
  color: #ff4d62;
}
.link-danger:hover, .link-danger:focus {
  color: #992e3b;
}

.link-white {
  color: #fff;
}
.link-white:hover, .link-white:focus {
  color: white;
}

.link-light {
  color: #fef6f6;
}
.link-light:hover, .link-light:focus {
  color: #fefafa;
}

.link-gray {
  color: #efe2e2;
}
.link-gray:hover, .link-gray:focus {
  color: #f5eeee;
}

.link-dark {
  color: #221515;
}
.link-dark:hover, .link-dark:focus {
  color: #140d0d;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.075;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(34, 21, 21, 0.04) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.5rem rgba(34, 21, 21, 0.05) !important;
}

.shadow-lg {
  box-shadow: 0 0.75rem 1.5rem rgba(34, 21, 21, 0.03) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #faf1f1 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #faf1f1 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #faf1f1 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #faf1f1 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #faf1f1 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #6f0000 !important;
}

.border-secondary {
  border-color: #593e3e !important;
}

.border-success {
  border-color: #4BC98D !important;
}

.border-info {
  border-color: #2adfff !important;
}

.border-warning {
  border-color: #ffb016 !important;
}

.border-danger {
  border-color: #ff4d62 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-light {
  border-color: #fef6f6 !important;
}

.border-gray {
  border-color: #efe2e2 !important;
}

.border-dark {
  border-color: #221515 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.25rem !important;
}

.gap-5 {
  gap: 1.5rem !important;
}

.gap-6 {
  gap: 1.75rem !important;
}

.gap-7 {
  gap: 2rem !important;
}

.gap-8 {
  gap: 2.5rem !important;
}

.gap-9 {
  gap: 3rem !important;
}

.gap-10 {
  gap: 3.5rem !important;
}

.gap-11 {
  gap: 4rem !important;
}

.gap-12 {
  gap: 5rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.25rem !important;
}

.m-5 {
  margin: 1.5rem !important;
}

.m-6 {
  margin: 1.75rem !important;
}

.m-7 {
  margin: 2rem !important;
}

.m-8 {
  margin: 2.5rem !important;
}

.m-9 {
  margin: 3rem !important;
}

.m-10 {
  margin: 3.5rem !important;
}

.m-11 {
  margin: 4rem !important;
}

.m-12 {
  margin: 5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-5 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-6 {
  margin-right: 1.75rem !important;
  margin-left: 1.75rem !important;
}

.mx-7 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-8 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-9 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-10 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-11 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-12 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-5 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-6 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.my-7 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-8 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-9 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-10 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-11 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-12 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.25rem !important;
}

.mt-5 {
  margin-top: 1.5rem !important;
}

.mt-6 {
  margin-top: 1.75rem !important;
}

.mt-7 {
  margin-top: 2rem !important;
}

.mt-8 {
  margin-top: 2.5rem !important;
}

.mt-9 {
  margin-top: 3rem !important;
}

.mt-10 {
  margin-top: 3.5rem !important;
}

.mt-11 {
  margin-top: 4rem !important;
}

.mt-12 {
  margin-top: 5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.25rem !important;
}

.me-5 {
  margin-right: 1.5rem !important;
}

.me-6 {
  margin-right: 1.75rem !important;
}

.me-7 {
  margin-right: 2rem !important;
}

.me-8 {
  margin-right: 2.5rem !important;
}

.me-9 {
  margin-right: 3rem !important;
}

.me-10 {
  margin-right: 3.5rem !important;
}

.me-11 {
  margin-right: 4rem !important;
}

.me-12 {
  margin-right: 5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.25rem !important;
}

.mb-5 {
  margin-bottom: 1.5rem !important;
}

.mb-6 {
  margin-bottom: 1.75rem !important;
}

.mb-7 {
  margin-bottom: 2rem !important;
}

.mb-8 {
  margin-bottom: 2.5rem !important;
}

.mb-9 {
  margin-bottom: 3rem !important;
}

.mb-10 {
  margin-bottom: 3.5rem !important;
}

.mb-11 {
  margin-bottom: 4rem !important;
}

.mb-12 {
  margin-bottom: 5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.25rem !important;
}

.ms-5 {
  margin-left: 1.5rem !important;
}

.ms-6 {
  margin-left: 1.75rem !important;
}

.ms-7 {
  margin-left: 2rem !important;
}

.ms-8 {
  margin-left: 2.5rem !important;
}

.ms-9 {
  margin-left: 3rem !important;
}

.ms-10 {
  margin-left: 3.5rem !important;
}

.ms-11 {
  margin-left: 4rem !important;
}

.ms-12 {
  margin-left: 5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.25rem !important;
}

.m-n5 {
  margin: -1.5rem !important;
}

.m-n6 {
  margin: -1.75rem !important;
}

.m-n7 {
  margin: -2rem !important;
}

.m-n8 {
  margin: -2.5rem !important;
}

.m-n9 {
  margin: -3rem !important;
}

.m-n10 {
  margin: -3.5rem !important;
}

.m-n11 {
  margin: -4rem !important;
}

.m-n12 {
  margin: -5rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n5 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n6 {
  margin-right: -1.75rem !important;
  margin-left: -1.75rem !important;
}

.mx-n7 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n8 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n9 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n10 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important;
}

.mx-n11 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n12 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n5 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n6 {
  margin-top: -1.75rem !important;
  margin-bottom: -1.75rem !important;
}

.my-n7 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n8 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n9 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n10 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n11 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n12 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.25rem !important;
}

.mt-n5 {
  margin-top: -1.5rem !important;
}

.mt-n6 {
  margin-top: -1.75rem !important;
}

.mt-n7 {
  margin-top: -2rem !important;
}

.mt-n8 {
  margin-top: -2.5rem !important;
}

.mt-n9 {
  margin-top: -3rem !important;
}

.mt-n10 {
  margin-top: -3.5rem !important;
}

.mt-n11 {
  margin-top: -4rem !important;
}

.mt-n12 {
  margin-top: -5rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.25rem !important;
}

.me-n5 {
  margin-right: -1.5rem !important;
}

.me-n6 {
  margin-right: -1.75rem !important;
}

.me-n7 {
  margin-right: -2rem !important;
}

.me-n8 {
  margin-right: -2.5rem !important;
}

.me-n9 {
  margin-right: -3rem !important;
}

.me-n10 {
  margin-right: -3.5rem !important;
}

.me-n11 {
  margin-right: -4rem !important;
}

.me-n12 {
  margin-right: -5rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.25rem !important;
}

.mb-n5 {
  margin-bottom: -1.5rem !important;
}

.mb-n6 {
  margin-bottom: -1.75rem !important;
}

.mb-n7 {
  margin-bottom: -2rem !important;
}

.mb-n8 {
  margin-bottom: -2.5rem !important;
}

.mb-n9 {
  margin-bottom: -3rem !important;
}

.mb-n10 {
  margin-bottom: -3.5rem !important;
}

.mb-n11 {
  margin-bottom: -4rem !important;
}

.mb-n12 {
  margin-bottom: -5rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.25rem !important;
}

.ms-n5 {
  margin-left: -1.5rem !important;
}

.ms-n6 {
  margin-left: -1.75rem !important;
}

.ms-n7 {
  margin-left: -2rem !important;
}

.ms-n8 {
  margin-left: -2.5rem !important;
}

.ms-n9 {
  margin-left: -3rem !important;
}

.ms-n10 {
  margin-left: -3.5rem !important;
}

.ms-n11 {
  margin-left: -4rem !important;
}

.ms-n12 {
  margin-left: -5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.25rem !important;
}

.p-5 {
  padding: 1.5rem !important;
}

.p-6 {
  padding: 1.75rem !important;
}

.p-7 {
  padding: 2rem !important;
}

.p-8 {
  padding: 2.5rem !important;
}

.p-9 {
  padding: 3rem !important;
}

.p-10 {
  padding: 3.5rem !important;
}

.p-11 {
  padding: 4rem !important;
}

.p-12 {
  padding: 5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-5 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-6 {
  padding-right: 1.75rem !important;
  padding-left: 1.75rem !important;
}

.px-7 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-8 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-9 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-10 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-11 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-12 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-5 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-6 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.py-7 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-8 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-9 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-10 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-11 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-12 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.25rem !important;
}

.pt-5 {
  padding-top: 1.5rem !important;
}

.pt-6 {
  padding-top: 1.75rem !important;
}

.pt-7 {
  padding-top: 2rem !important;
}

.pt-8 {
  padding-top: 2.5rem !important;
}

.pt-9 {
  padding-top: 3rem !important;
}

.pt-10 {
  padding-top: 3.5rem !important;
}

.pt-11 {
  padding-top: 4rem !important;
}

.pt-12 {
  padding-top: 5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.25rem !important;
}

.pe-5 {
  padding-right: 1.5rem !important;
}

.pe-6 {
  padding-right: 1.75rem !important;
}

.pe-7 {
  padding-right: 2rem !important;
}

.pe-8 {
  padding-right: 2.5rem !important;
}

.pe-9 {
  padding-right: 3rem !important;
}

.pe-10 {
  padding-right: 3.5rem !important;
}

.pe-11 {
  padding-right: 4rem !important;
}

.pe-12 {
  padding-right: 5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.25rem !important;
}

.pb-5 {
  padding-bottom: 1.5rem !important;
}

.pb-6 {
  padding-bottom: 1.75rem !important;
}

.pb-7 {
  padding-bottom: 2rem !important;
}

.pb-8 {
  padding-bottom: 2.5rem !important;
}

.pb-9 {
  padding-bottom: 3rem !important;
}

.pb-10 {
  padding-bottom: 3.5rem !important;
}

.pb-11 {
  padding-bottom: 4rem !important;
}

.pb-12 {
  padding-bottom: 5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.25rem !important;
}

.ps-5 {
  padding-left: 1.5rem !important;
}

.ps-6 {
  padding-left: 1.75rem !important;
}

.ps-7 {
  padding-left: 2rem !important;
}

.ps-8 {
  padding-left: 2.5rem !important;
}

.ps-9 {
  padding-left: 3rem !important;
}

.ps-10 {
  padding-left: 3.5rem !important;
}

.ps-11 {
  padding-left: 4rem !important;
}

.ps-12 {
  padding-left: 5rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.359375rem + 1.3125vw) !important;
}

.fs-2 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-3 {
  font-size: calc(1.2890625rem + 0.46875vw) !important;
}

.fs-4 {
  font-size: calc(1.265625rem + 0.1875vw) !important;
}

.fs-5 {
  font-size: 1.1484375rem !important;
}

.fs-6 {
  font-size: 0.9375rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: 200 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 500 !important;
}

.fw-bolder {
  font-weight: 600 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-gray {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #b49a9a !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(34, 21, 21, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.5rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.325rem !important;
}

.rounded-2 {
  border-radius: 0.5rem !important;
}

.rounded-3 {
  border-radius: 0.875rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.rounded-end {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.5rem !important;
  border-top-left-radius: 0.5rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.25rem !important;
  }

  .gap-sm-5 {
    gap: 1.5rem !important;
  }

  .gap-sm-6 {
    gap: 1.75rem !important;
  }

  .gap-sm-7 {
    gap: 2rem !important;
  }

  .gap-sm-8 {
    gap: 2.5rem !important;
  }

  .gap-sm-9 {
    gap: 3rem !important;
  }

  .gap-sm-10 {
    gap: 3.5rem !important;
  }

  .gap-sm-11 {
    gap: 4rem !important;
  }

  .gap-sm-12 {
    gap: 5rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.25rem !important;
  }

  .m-sm-5 {
    margin: 1.5rem !important;
  }

  .m-sm-6 {
    margin: 1.75rem !important;
  }

  .m-sm-7 {
    margin: 2rem !important;
  }

  .m-sm-8 {
    margin: 2.5rem !important;
  }

  .m-sm-9 {
    margin: 3rem !important;
  }

  .m-sm-10 {
    margin: 3.5rem !important;
  }

  .m-sm-11 {
    margin: 4rem !important;
  }

  .m-sm-12 {
    margin: 5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-sm-5 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-6 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-sm-7 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-sm-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-sm-9 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-10 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-sm-11 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-12 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-sm-5 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-6 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-sm-7 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-9 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-10 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-11 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-12 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.25rem !important;
  }

  .mt-sm-5 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-6 {
    margin-top: 1.75rem !important;
  }

  .mt-sm-7 {
    margin-top: 2rem !important;
  }

  .mt-sm-8 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-9 {
    margin-top: 3rem !important;
  }

  .mt-sm-10 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-11 {
    margin-top: 4rem !important;
  }

  .mt-sm-12 {
    margin-top: 5rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.25rem !important;
  }

  .me-sm-5 {
    margin-right: 1.5rem !important;
  }

  .me-sm-6 {
    margin-right: 1.75rem !important;
  }

  .me-sm-7 {
    margin-right: 2rem !important;
  }

  .me-sm-8 {
    margin-right: 2.5rem !important;
  }

  .me-sm-9 {
    margin-right: 3rem !important;
  }

  .me-sm-10 {
    margin-right: 3.5rem !important;
  }

  .me-sm-11 {
    margin-right: 4rem !important;
  }

  .me-sm-12 {
    margin-right: 5rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.25rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 1.75rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.25rem !important;
  }

  .ms-sm-5 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-6 {
    margin-left: 1.75rem !important;
  }

  .ms-sm-7 {
    margin-left: 2rem !important;
  }

  .ms-sm-8 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-9 {
    margin-left: 3rem !important;
  }

  .ms-sm-10 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-11 {
    margin-left: 4rem !important;
  }

  .ms-sm-12 {
    margin-left: 5rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.25rem !important;
  }

  .m-sm-n5 {
    margin: -1.5rem !important;
  }

  .m-sm-n6 {
    margin: -1.75rem !important;
  }

  .m-sm-n7 {
    margin: -2rem !important;
  }

  .m-sm-n8 {
    margin: -2.5rem !important;
  }

  .m-sm-n9 {
    margin: -3rem !important;
  }

  .m-sm-n10 {
    margin: -3.5rem !important;
  }

  .m-sm-n11 {
    margin: -4rem !important;
  }

  .m-sm-n12 {
    margin: -5rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-sm-n5 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n6 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-sm-n7 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-sm-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-sm-n9 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-sm-n10 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-sm-n11 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-sm-n12 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-sm-n5 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n6 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-sm-n7 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-sm-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-sm-n9 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-sm-n10 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-sm-n11 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-sm-n12 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.25rem !important;
  }

  .mt-sm-n5 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n6 {
    margin-top: -1.75rem !important;
  }

  .mt-sm-n7 {
    margin-top: -2rem !important;
  }

  .mt-sm-n8 {
    margin-top: -2.5rem !important;
  }

  .mt-sm-n9 {
    margin-top: -3rem !important;
  }

  .mt-sm-n10 {
    margin-top: -3.5rem !important;
  }

  .mt-sm-n11 {
    margin-top: -4rem !important;
  }

  .mt-sm-n12 {
    margin-top: -5rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.25rem !important;
  }

  .me-sm-n5 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n6 {
    margin-right: -1.75rem !important;
  }

  .me-sm-n7 {
    margin-right: -2rem !important;
  }

  .me-sm-n8 {
    margin-right: -2.5rem !important;
  }

  .me-sm-n9 {
    margin-right: -3rem !important;
  }

  .me-sm-n10 {
    margin-right: -3.5rem !important;
  }

  .me-sm-n11 {
    margin-right: -4rem !important;
  }

  .me-sm-n12 {
    margin-right: -5rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.25rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -1.75rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -2rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -2.5rem !important;
  }

  .mb-sm-n9 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -3.5rem !important;
  }

  .mb-sm-n11 {
    margin-bottom: -4rem !important;
  }

  .mb-sm-n12 {
    margin-bottom: -5rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.25rem !important;
  }

  .ms-sm-n5 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n6 {
    margin-left: -1.75rem !important;
  }

  .ms-sm-n7 {
    margin-left: -2rem !important;
  }

  .ms-sm-n8 {
    margin-left: -2.5rem !important;
  }

  .ms-sm-n9 {
    margin-left: -3rem !important;
  }

  .ms-sm-n10 {
    margin-left: -3.5rem !important;
  }

  .ms-sm-n11 {
    margin-left: -4rem !important;
  }

  .ms-sm-n12 {
    margin-left: -5rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.25rem !important;
  }

  .p-sm-5 {
    padding: 1.5rem !important;
  }

  .p-sm-6 {
    padding: 1.75rem !important;
  }

  .p-sm-7 {
    padding: 2rem !important;
  }

  .p-sm-8 {
    padding: 2.5rem !important;
  }

  .p-sm-9 {
    padding: 3rem !important;
  }

  .p-sm-10 {
    padding: 3.5rem !important;
  }

  .p-sm-11 {
    padding: 4rem !important;
  }

  .p-sm-12 {
    padding: 5rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-sm-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-6 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-sm-7 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-sm-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-sm-9 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-10 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-sm-11 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-sm-12 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-sm-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-6 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-sm-7 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-9 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-10 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-11 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-12 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.25rem !important;
  }

  .pt-sm-5 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-6 {
    padding-top: 1.75rem !important;
  }

  .pt-sm-7 {
    padding-top: 2rem !important;
  }

  .pt-sm-8 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-9 {
    padding-top: 3rem !important;
  }

  .pt-sm-10 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-11 {
    padding-top: 4rem !important;
  }

  .pt-sm-12 {
    padding-top: 5rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.25rem !important;
  }

  .pe-sm-5 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-6 {
    padding-right: 1.75rem !important;
  }

  .pe-sm-7 {
    padding-right: 2rem !important;
  }

  .pe-sm-8 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-9 {
    padding-right: 3rem !important;
  }

  .pe-sm-10 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-11 {
    padding-right: 4rem !important;
  }

  .pe-sm-12 {
    padding-right: 5rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.25rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 1.75rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-11 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-12 {
    padding-bottom: 5rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.25rem !important;
  }

  .ps-sm-5 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-6 {
    padding-left: 1.75rem !important;
  }

  .ps-sm-7 {
    padding-left: 2rem !important;
  }

  .ps-sm-8 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-9 {
    padding-left: 3rem !important;
  }

  .ps-sm-10 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-11 {
    padding-left: 4rem !important;
  }

  .ps-sm-12 {
    padding-left: 5rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.25rem !important;
  }

  .gap-md-5 {
    gap: 1.5rem !important;
  }

  .gap-md-6 {
    gap: 1.75rem !important;
  }

  .gap-md-7 {
    gap: 2rem !important;
  }

  .gap-md-8 {
    gap: 2.5rem !important;
  }

  .gap-md-9 {
    gap: 3rem !important;
  }

  .gap-md-10 {
    gap: 3.5rem !important;
  }

  .gap-md-11 {
    gap: 4rem !important;
  }

  .gap-md-12 {
    gap: 5rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.25rem !important;
  }

  .m-md-5 {
    margin: 1.5rem !important;
  }

  .m-md-6 {
    margin: 1.75rem !important;
  }

  .m-md-7 {
    margin: 2rem !important;
  }

  .m-md-8 {
    margin: 2.5rem !important;
  }

  .m-md-9 {
    margin: 3rem !important;
  }

  .m-md-10 {
    margin: 3.5rem !important;
  }

  .m-md-11 {
    margin: 4rem !important;
  }

  .m-md-12 {
    margin: 5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-md-5 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-6 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-md-7 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-md-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-md-9 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-10 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-md-11 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-12 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-md-5 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-6 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-md-7 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-9 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-10 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-11 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-12 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.25rem !important;
  }

  .mt-md-5 {
    margin-top: 1.5rem !important;
  }

  .mt-md-6 {
    margin-top: 1.75rem !important;
  }

  .mt-md-7 {
    margin-top: 2rem !important;
  }

  .mt-md-8 {
    margin-top: 2.5rem !important;
  }

  .mt-md-9 {
    margin-top: 3rem !important;
  }

  .mt-md-10 {
    margin-top: 3.5rem !important;
  }

  .mt-md-11 {
    margin-top: 4rem !important;
  }

  .mt-md-12 {
    margin-top: 5rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.25rem !important;
  }

  .me-md-5 {
    margin-right: 1.5rem !important;
  }

  .me-md-6 {
    margin-right: 1.75rem !important;
  }

  .me-md-7 {
    margin-right: 2rem !important;
  }

  .me-md-8 {
    margin-right: 2.5rem !important;
  }

  .me-md-9 {
    margin-right: 3rem !important;
  }

  .me-md-10 {
    margin-right: 3.5rem !important;
  }

  .me-md-11 {
    margin-right: 4rem !important;
  }

  .me-md-12 {
    margin-right: 5rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.25rem !important;
  }

  .mb-md-5 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-6 {
    margin-bottom: 1.75rem !important;
  }

  .mb-md-7 {
    margin-bottom: 2rem !important;
  }

  .mb-md-8 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-9 {
    margin-bottom: 3rem !important;
  }

  .mb-md-10 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-11 {
    margin-bottom: 4rem !important;
  }

  .mb-md-12 {
    margin-bottom: 5rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.25rem !important;
  }

  .ms-md-5 {
    margin-left: 1.5rem !important;
  }

  .ms-md-6 {
    margin-left: 1.75rem !important;
  }

  .ms-md-7 {
    margin-left: 2rem !important;
  }

  .ms-md-8 {
    margin-left: 2.5rem !important;
  }

  .ms-md-9 {
    margin-left: 3rem !important;
  }

  .ms-md-10 {
    margin-left: 3.5rem !important;
  }

  .ms-md-11 {
    margin-left: 4rem !important;
  }

  .ms-md-12 {
    margin-left: 5rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.25rem !important;
  }

  .m-md-n5 {
    margin: -1.5rem !important;
  }

  .m-md-n6 {
    margin: -1.75rem !important;
  }

  .m-md-n7 {
    margin: -2rem !important;
  }

  .m-md-n8 {
    margin: -2.5rem !important;
  }

  .m-md-n9 {
    margin: -3rem !important;
  }

  .m-md-n10 {
    margin: -3.5rem !important;
  }

  .m-md-n11 {
    margin: -4rem !important;
  }

  .m-md-n12 {
    margin: -5rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-md-n5 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n6 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-md-n7 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-md-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-md-n9 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-md-n10 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-md-n11 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-md-n12 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-md-n5 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n6 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-md-n7 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-md-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-md-n9 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-md-n10 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-md-n11 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-md-n12 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.25rem !important;
  }

  .mt-md-n5 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n6 {
    margin-top: -1.75rem !important;
  }

  .mt-md-n7 {
    margin-top: -2rem !important;
  }

  .mt-md-n8 {
    margin-top: -2.5rem !important;
  }

  .mt-md-n9 {
    margin-top: -3rem !important;
  }

  .mt-md-n10 {
    margin-top: -3.5rem !important;
  }

  .mt-md-n11 {
    margin-top: -4rem !important;
  }

  .mt-md-n12 {
    margin-top: -5rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.25rem !important;
  }

  .me-md-n5 {
    margin-right: -1.5rem !important;
  }

  .me-md-n6 {
    margin-right: -1.75rem !important;
  }

  .me-md-n7 {
    margin-right: -2rem !important;
  }

  .me-md-n8 {
    margin-right: -2.5rem !important;
  }

  .me-md-n9 {
    margin-right: -3rem !important;
  }

  .me-md-n10 {
    margin-right: -3.5rem !important;
  }

  .me-md-n11 {
    margin-right: -4rem !important;
  }

  .me-md-n12 {
    margin-right: -5rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.25rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -1.75rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -2rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -2.5rem !important;
  }

  .mb-md-n9 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -3.5rem !important;
  }

  .mb-md-n11 {
    margin-bottom: -4rem !important;
  }

  .mb-md-n12 {
    margin-bottom: -5rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.25rem !important;
  }

  .ms-md-n5 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n6 {
    margin-left: -1.75rem !important;
  }

  .ms-md-n7 {
    margin-left: -2rem !important;
  }

  .ms-md-n8 {
    margin-left: -2.5rem !important;
  }

  .ms-md-n9 {
    margin-left: -3rem !important;
  }

  .ms-md-n10 {
    margin-left: -3.5rem !important;
  }

  .ms-md-n11 {
    margin-left: -4rem !important;
  }

  .ms-md-n12 {
    margin-left: -5rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.25rem !important;
  }

  .p-md-5 {
    padding: 1.5rem !important;
  }

  .p-md-6 {
    padding: 1.75rem !important;
  }

  .p-md-7 {
    padding: 2rem !important;
  }

  .p-md-8 {
    padding: 2.5rem !important;
  }

  .p-md-9 {
    padding: 3rem !important;
  }

  .p-md-10 {
    padding: 3.5rem !important;
  }

  .p-md-11 {
    padding: 4rem !important;
  }

  .p-md-12 {
    padding: 5rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-md-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-6 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-md-7 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-md-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-md-9 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-10 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-md-11 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-md-12 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-md-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-6 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-md-7 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-9 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-10 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-11 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-12 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.25rem !important;
  }

  .pt-md-5 {
    padding-top: 1.5rem !important;
  }

  .pt-md-6 {
    padding-top: 1.75rem !important;
  }

  .pt-md-7 {
    padding-top: 2rem !important;
  }

  .pt-md-8 {
    padding-top: 2.5rem !important;
  }

  .pt-md-9 {
    padding-top: 3rem !important;
  }

  .pt-md-10 {
    padding-top: 3.5rem !important;
  }

  .pt-md-11 {
    padding-top: 4rem !important;
  }

  .pt-md-12 {
    padding-top: 5rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.25rem !important;
  }

  .pe-md-5 {
    padding-right: 1.5rem !important;
  }

  .pe-md-6 {
    padding-right: 1.75rem !important;
  }

  .pe-md-7 {
    padding-right: 2rem !important;
  }

  .pe-md-8 {
    padding-right: 2.5rem !important;
  }

  .pe-md-9 {
    padding-right: 3rem !important;
  }

  .pe-md-10 {
    padding-right: 3.5rem !important;
  }

  .pe-md-11 {
    padding-right: 4rem !important;
  }

  .pe-md-12 {
    padding-right: 5rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.25rem !important;
  }

  .pb-md-5 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-6 {
    padding-bottom: 1.75rem !important;
  }

  .pb-md-7 {
    padding-bottom: 2rem !important;
  }

  .pb-md-8 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-9 {
    padding-bottom: 3rem !important;
  }

  .pb-md-10 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-11 {
    padding-bottom: 4rem !important;
  }

  .pb-md-12 {
    padding-bottom: 5rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.25rem !important;
  }

  .ps-md-5 {
    padding-left: 1.5rem !important;
  }

  .ps-md-6 {
    padding-left: 1.75rem !important;
  }

  .ps-md-7 {
    padding-left: 2rem !important;
  }

  .ps-md-8 {
    padding-left: 2.5rem !important;
  }

  .ps-md-9 {
    padding-left: 3rem !important;
  }

  .ps-md-10 {
    padding-left: 3.5rem !important;
  }

  .ps-md-11 {
    padding-left: 4rem !important;
  }

  .ps-md-12 {
    padding-left: 5rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.25rem !important;
  }

  .gap-lg-5 {
    gap: 1.5rem !important;
  }

  .gap-lg-6 {
    gap: 1.75rem !important;
  }

  .gap-lg-7 {
    gap: 2rem !important;
  }

  .gap-lg-8 {
    gap: 2.5rem !important;
  }

  .gap-lg-9 {
    gap: 3rem !important;
  }

  .gap-lg-10 {
    gap: 3.5rem !important;
  }

  .gap-lg-11 {
    gap: 4rem !important;
  }

  .gap-lg-12 {
    gap: 5rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.25rem !important;
  }

  .m-lg-5 {
    margin: 1.5rem !important;
  }

  .m-lg-6 {
    margin: 1.75rem !important;
  }

  .m-lg-7 {
    margin: 2rem !important;
  }

  .m-lg-8 {
    margin: 2.5rem !important;
  }

  .m-lg-9 {
    margin: 3rem !important;
  }

  .m-lg-10 {
    margin: 3.5rem !important;
  }

  .m-lg-11 {
    margin: 4rem !important;
  }

  .m-lg-12 {
    margin: 5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-lg-5 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-6 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-lg-7 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-lg-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-lg-9 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-10 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-lg-11 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-12 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-lg-5 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-6 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-lg-7 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-9 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-10 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-11 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-12 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.25rem !important;
  }

  .mt-lg-5 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-6 {
    margin-top: 1.75rem !important;
  }

  .mt-lg-7 {
    margin-top: 2rem !important;
  }

  .mt-lg-8 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-9 {
    margin-top: 3rem !important;
  }

  .mt-lg-10 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-11 {
    margin-top: 4rem !important;
  }

  .mt-lg-12 {
    margin-top: 5rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.25rem !important;
  }

  .me-lg-5 {
    margin-right: 1.5rem !important;
  }

  .me-lg-6 {
    margin-right: 1.75rem !important;
  }

  .me-lg-7 {
    margin-right: 2rem !important;
  }

  .me-lg-8 {
    margin-right: 2.5rem !important;
  }

  .me-lg-9 {
    margin-right: 3rem !important;
  }

  .me-lg-10 {
    margin-right: 3.5rem !important;
  }

  .me-lg-11 {
    margin-right: 4rem !important;
  }

  .me-lg-12 {
    margin-right: 5rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.25rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 1.75rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.25rem !important;
  }

  .ms-lg-5 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-6 {
    margin-left: 1.75rem !important;
  }

  .ms-lg-7 {
    margin-left: 2rem !important;
  }

  .ms-lg-8 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-9 {
    margin-left: 3rem !important;
  }

  .ms-lg-10 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-11 {
    margin-left: 4rem !important;
  }

  .ms-lg-12 {
    margin-left: 5rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.25rem !important;
  }

  .m-lg-n5 {
    margin: -1.5rem !important;
  }

  .m-lg-n6 {
    margin: -1.75rem !important;
  }

  .m-lg-n7 {
    margin: -2rem !important;
  }

  .m-lg-n8 {
    margin: -2.5rem !important;
  }

  .m-lg-n9 {
    margin: -3rem !important;
  }

  .m-lg-n10 {
    margin: -3.5rem !important;
  }

  .m-lg-n11 {
    margin: -4rem !important;
  }

  .m-lg-n12 {
    margin: -5rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-lg-n5 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n6 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-lg-n7 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-lg-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-lg-n9 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-lg-n10 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-lg-n11 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-lg-n12 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-lg-n5 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n6 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-lg-n7 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-lg-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-lg-n9 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-lg-n10 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-lg-n11 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-lg-n12 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.25rem !important;
  }

  .mt-lg-n5 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n6 {
    margin-top: -1.75rem !important;
  }

  .mt-lg-n7 {
    margin-top: -2rem !important;
  }

  .mt-lg-n8 {
    margin-top: -2.5rem !important;
  }

  .mt-lg-n9 {
    margin-top: -3rem !important;
  }

  .mt-lg-n10 {
    margin-top: -3.5rem !important;
  }

  .mt-lg-n11 {
    margin-top: -4rem !important;
  }

  .mt-lg-n12 {
    margin-top: -5rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.25rem !important;
  }

  .me-lg-n5 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n6 {
    margin-right: -1.75rem !important;
  }

  .me-lg-n7 {
    margin-right: -2rem !important;
  }

  .me-lg-n8 {
    margin-right: -2.5rem !important;
  }

  .me-lg-n9 {
    margin-right: -3rem !important;
  }

  .me-lg-n10 {
    margin-right: -3.5rem !important;
  }

  .me-lg-n11 {
    margin-right: -4rem !important;
  }

  .me-lg-n12 {
    margin-right: -5rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.25rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -1.75rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -2rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -2.5rem !important;
  }

  .mb-lg-n9 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -3.5rem !important;
  }

  .mb-lg-n11 {
    margin-bottom: -4rem !important;
  }

  .mb-lg-n12 {
    margin-bottom: -5rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.25rem !important;
  }

  .ms-lg-n5 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n6 {
    margin-left: -1.75rem !important;
  }

  .ms-lg-n7 {
    margin-left: -2rem !important;
  }

  .ms-lg-n8 {
    margin-left: -2.5rem !important;
  }

  .ms-lg-n9 {
    margin-left: -3rem !important;
  }

  .ms-lg-n10 {
    margin-left: -3.5rem !important;
  }

  .ms-lg-n11 {
    margin-left: -4rem !important;
  }

  .ms-lg-n12 {
    margin-left: -5rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.25rem !important;
  }

  .p-lg-5 {
    padding: 1.5rem !important;
  }

  .p-lg-6 {
    padding: 1.75rem !important;
  }

  .p-lg-7 {
    padding: 2rem !important;
  }

  .p-lg-8 {
    padding: 2.5rem !important;
  }

  .p-lg-9 {
    padding: 3rem !important;
  }

  .p-lg-10 {
    padding: 3.5rem !important;
  }

  .p-lg-11 {
    padding: 4rem !important;
  }

  .p-lg-12 {
    padding: 5rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-lg-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-6 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-lg-7 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-lg-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-lg-9 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-10 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-lg-11 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-lg-12 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-lg-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-6 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-lg-7 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-9 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-10 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-11 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-12 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.25rem !important;
  }

  .pt-lg-5 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-6 {
    padding-top: 1.75rem !important;
  }

  .pt-lg-7 {
    padding-top: 2rem !important;
  }

  .pt-lg-8 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-9 {
    padding-top: 3rem !important;
  }

  .pt-lg-10 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-11 {
    padding-top: 4rem !important;
  }

  .pt-lg-12 {
    padding-top: 5rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.25rem !important;
  }

  .pe-lg-5 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-6 {
    padding-right: 1.75rem !important;
  }

  .pe-lg-7 {
    padding-right: 2rem !important;
  }

  .pe-lg-8 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-9 {
    padding-right: 3rem !important;
  }

  .pe-lg-10 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-11 {
    padding-right: 4rem !important;
  }

  .pe-lg-12 {
    padding-right: 5rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.25rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 1.75rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-11 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-12 {
    padding-bottom: 5rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.25rem !important;
  }

  .ps-lg-5 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-6 {
    padding-left: 1.75rem !important;
  }

  .ps-lg-7 {
    padding-left: 2rem !important;
  }

  .ps-lg-8 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-9 {
    padding-left: 3rem !important;
  }

  .ps-lg-10 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-11 {
    padding-left: 4rem !important;
  }

  .ps-lg-12 {
    padding-left: 5rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.25rem !important;
  }

  .gap-xl-5 {
    gap: 1.5rem !important;
  }

  .gap-xl-6 {
    gap: 1.75rem !important;
  }

  .gap-xl-7 {
    gap: 2rem !important;
  }

  .gap-xl-8 {
    gap: 2.5rem !important;
  }

  .gap-xl-9 {
    gap: 3rem !important;
  }

  .gap-xl-10 {
    gap: 3.5rem !important;
  }

  .gap-xl-11 {
    gap: 4rem !important;
  }

  .gap-xl-12 {
    gap: 5rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.25rem !important;
  }

  .m-xl-5 {
    margin: 1.5rem !important;
  }

  .m-xl-6 {
    margin: 1.75rem !important;
  }

  .m-xl-7 {
    margin: 2rem !important;
  }

  .m-xl-8 {
    margin: 2.5rem !important;
  }

  .m-xl-9 {
    margin: 3rem !important;
  }

  .m-xl-10 {
    margin: 3.5rem !important;
  }

  .m-xl-11 {
    margin: 4rem !important;
  }

  .m-xl-12 {
    margin: 5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-xl-5 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-6 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-xl-7 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xl-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xl-9 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-10 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xl-11 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-12 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xl-5 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-6 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xl-7 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-9 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-10 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-11 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-12 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.25rem !important;
  }

  .mt-xl-5 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-6 {
    margin-top: 1.75rem !important;
  }

  .mt-xl-7 {
    margin-top: 2rem !important;
  }

  .mt-xl-8 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-9 {
    margin-top: 3rem !important;
  }

  .mt-xl-10 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-11 {
    margin-top: 4rem !important;
  }

  .mt-xl-12 {
    margin-top: 5rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.25rem !important;
  }

  .me-xl-5 {
    margin-right: 1.5rem !important;
  }

  .me-xl-6 {
    margin-right: 1.75rem !important;
  }

  .me-xl-7 {
    margin-right: 2rem !important;
  }

  .me-xl-8 {
    margin-right: 2.5rem !important;
  }

  .me-xl-9 {
    margin-right: 3rem !important;
  }

  .me-xl-10 {
    margin-right: 3.5rem !important;
  }

  .me-xl-11 {
    margin-right: 4rem !important;
  }

  .me-xl-12 {
    margin-right: 5rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.25rem !important;
  }

  .ms-xl-5 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-6 {
    margin-left: 1.75rem !important;
  }

  .ms-xl-7 {
    margin-left: 2rem !important;
  }

  .ms-xl-8 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-9 {
    margin-left: 3rem !important;
  }

  .ms-xl-10 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-11 {
    margin-left: 4rem !important;
  }

  .ms-xl-12 {
    margin-left: 5rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.25rem !important;
  }

  .m-xl-n5 {
    margin: -1.5rem !important;
  }

  .m-xl-n6 {
    margin: -1.75rem !important;
  }

  .m-xl-n7 {
    margin: -2rem !important;
  }

  .m-xl-n8 {
    margin: -2.5rem !important;
  }

  .m-xl-n9 {
    margin: -3rem !important;
  }

  .m-xl-n10 {
    margin: -3.5rem !important;
  }

  .m-xl-n11 {
    margin: -4rem !important;
  }

  .m-xl-n12 {
    margin: -5rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-xl-n5 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n6 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-xl-n7 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xl-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xl-n9 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xl-n10 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xl-n11 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xl-n12 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xl-n5 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n6 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xl-n7 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xl-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xl-n9 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xl-n10 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xl-n11 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xl-n12 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.25rem !important;
  }

  .mt-xl-n5 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n6 {
    margin-top: -1.75rem !important;
  }

  .mt-xl-n7 {
    margin-top: -2rem !important;
  }

  .mt-xl-n8 {
    margin-top: -2.5rem !important;
  }

  .mt-xl-n9 {
    margin-top: -3rem !important;
  }

  .mt-xl-n10 {
    margin-top: -3.5rem !important;
  }

  .mt-xl-n11 {
    margin-top: -4rem !important;
  }

  .mt-xl-n12 {
    margin-top: -5rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.25rem !important;
  }

  .me-xl-n5 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n6 {
    margin-right: -1.75rem !important;
  }

  .me-xl-n7 {
    margin-right: -2rem !important;
  }

  .me-xl-n8 {
    margin-right: -2.5rem !important;
  }

  .me-xl-n9 {
    margin-right: -3rem !important;
  }

  .me-xl-n10 {
    margin-right: -3.5rem !important;
  }

  .me-xl-n11 {
    margin-right: -4rem !important;
  }

  .me-xl-n12 {
    margin-right: -5rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -2rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xl-n9 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xl-n11 {
    margin-bottom: -4rem !important;
  }

  .mb-xl-n12 {
    margin-bottom: -5rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.25rem !important;
  }

  .ms-xl-n5 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n6 {
    margin-left: -1.75rem !important;
  }

  .ms-xl-n7 {
    margin-left: -2rem !important;
  }

  .ms-xl-n8 {
    margin-left: -2.5rem !important;
  }

  .ms-xl-n9 {
    margin-left: -3rem !important;
  }

  .ms-xl-n10 {
    margin-left: -3.5rem !important;
  }

  .ms-xl-n11 {
    margin-left: -4rem !important;
  }

  .ms-xl-n12 {
    margin-left: -5rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.25rem !important;
  }

  .p-xl-5 {
    padding: 1.5rem !important;
  }

  .p-xl-6 {
    padding: 1.75rem !important;
  }

  .p-xl-7 {
    padding: 2rem !important;
  }

  .p-xl-8 {
    padding: 2.5rem !important;
  }

  .p-xl-9 {
    padding: 3rem !important;
  }

  .p-xl-10 {
    padding: 3.5rem !important;
  }

  .p-xl-11 {
    padding: 4rem !important;
  }

  .p-xl-12 {
    padding: 5rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-xl-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-6 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-xl-7 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xl-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xl-9 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-10 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xl-11 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xl-12 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xl-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-6 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xl-7 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-9 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-10 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-11 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-12 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.25rem !important;
  }

  .pt-xl-5 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-6 {
    padding-top: 1.75rem !important;
  }

  .pt-xl-7 {
    padding-top: 2rem !important;
  }

  .pt-xl-8 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-9 {
    padding-top: 3rem !important;
  }

  .pt-xl-10 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-11 {
    padding-top: 4rem !important;
  }

  .pt-xl-12 {
    padding-top: 5rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.25rem !important;
  }

  .pe-xl-5 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-6 {
    padding-right: 1.75rem !important;
  }

  .pe-xl-7 {
    padding-right: 2rem !important;
  }

  .pe-xl-8 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-9 {
    padding-right: 3rem !important;
  }

  .pe-xl-10 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-11 {
    padding-right: 4rem !important;
  }

  .pe-xl-12 {
    padding-right: 5rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-11 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-12 {
    padding-bottom: 5rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.25rem !important;
  }

  .ps-xl-5 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-6 {
    padding-left: 1.75rem !important;
  }

  .ps-xl-7 {
    padding-left: 2rem !important;
  }

  .ps-xl-8 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-9 {
    padding-left: 3rem !important;
  }

  .ps-xl-10 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-11 {
    padding-left: 4rem !important;
  }

  .ps-xl-12 {
    padding-left: 5rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.25rem !important;
  }

  .gap-xxl-5 {
    gap: 1.5rem !important;
  }

  .gap-xxl-6 {
    gap: 1.75rem !important;
  }

  .gap-xxl-7 {
    gap: 2rem !important;
  }

  .gap-xxl-8 {
    gap: 2.5rem !important;
  }

  .gap-xxl-9 {
    gap: 3rem !important;
  }

  .gap-xxl-10 {
    gap: 3.5rem !important;
  }

  .gap-xxl-11 {
    gap: 4rem !important;
  }

  .gap-xxl-12 {
    gap: 5rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.25rem !important;
  }

  .m-xxl-5 {
    margin: 1.5rem !important;
  }

  .m-xxl-6 {
    margin: 1.75rem !important;
  }

  .m-xxl-7 {
    margin: 2rem !important;
  }

  .m-xxl-8 {
    margin: 2.5rem !important;
  }

  .m-xxl-9 {
    margin: 3rem !important;
  }

  .m-xxl-10 {
    margin: 3.5rem !important;
  }

  .m-xxl-11 {
    margin: 4rem !important;
  }

  .m-xxl-12 {
    margin: 5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-xxl-5 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-6 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-xxl-7 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xxl-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xxl-9 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-10 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xxl-11 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-12 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xxl-5 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-6 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xxl-7 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-9 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-10 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-11 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-12 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.25rem !important;
  }

  .mt-xxl-5 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-6 {
    margin-top: 1.75rem !important;
  }

  .mt-xxl-7 {
    margin-top: 2rem !important;
  }

  .mt-xxl-8 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-9 {
    margin-top: 3rem !important;
  }

  .mt-xxl-10 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-11 {
    margin-top: 4rem !important;
  }

  .mt-xxl-12 {
    margin-top: 5rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.25rem !important;
  }

  .me-xxl-5 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-6 {
    margin-right: 1.75rem !important;
  }

  .me-xxl-7 {
    margin-right: 2rem !important;
  }

  .me-xxl-8 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-9 {
    margin-right: 3rem !important;
  }

  .me-xxl-10 {
    margin-right: 3.5rem !important;
  }

  .me-xxl-11 {
    margin-right: 4rem !important;
  }

  .me-xxl-12 {
    margin-right: 5rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-11 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.25rem !important;
  }

  .ms-xxl-5 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-6 {
    margin-left: 1.75rem !important;
  }

  .ms-xxl-7 {
    margin-left: 2rem !important;
  }

  .ms-xxl-8 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-9 {
    margin-left: 3rem !important;
  }

  .ms-xxl-10 {
    margin-left: 3.5rem !important;
  }

  .ms-xxl-11 {
    margin-left: 4rem !important;
  }

  .ms-xxl-12 {
    margin-left: 5rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.25rem !important;
  }

  .m-xxl-n5 {
    margin: -1.5rem !important;
  }

  .m-xxl-n6 {
    margin: -1.75rem !important;
  }

  .m-xxl-n7 {
    margin: -2rem !important;
  }

  .m-xxl-n8 {
    margin: -2.5rem !important;
  }

  .m-xxl-n9 {
    margin: -3rem !important;
  }

  .m-xxl-n10 {
    margin: -3.5rem !important;
  }

  .m-xxl-n11 {
    margin: -4rem !important;
  }

  .m-xxl-n12 {
    margin: -5rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n6 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-xxl-n7 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xxl-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xxl-n9 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xxl-n10 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xxl-n11 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xxl-n12 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xxl-n5 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n6 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xxl-n7 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xxl-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xxl-n9 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xxl-n10 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xxl-n11 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xxl-n12 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.25rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -1.75rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -2rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -2.5rem !important;
  }

  .mt-xxl-n9 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -3.5rem !important;
  }

  .mt-xxl-n11 {
    margin-top: -4rem !important;
  }

  .mt-xxl-n12 {
    margin-top: -5rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.25rem !important;
  }

  .me-xxl-n5 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n6 {
    margin-right: -1.75rem !important;
  }

  .me-xxl-n7 {
    margin-right: -2rem !important;
  }

  .me-xxl-n8 {
    margin-right: -2.5rem !important;
  }

  .me-xxl-n9 {
    margin-right: -3rem !important;
  }

  .me-xxl-n10 {
    margin-right: -3.5rem !important;
  }

  .me-xxl-n11 {
    margin-right: -4rem !important;
  }

  .me-xxl-n12 {
    margin-right: -5rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -2rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xxl-n9 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xxl-n11 {
    margin-bottom: -4rem !important;
  }

  .mb-xxl-n12 {
    margin-bottom: -5rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.25rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -1.75rem !important;
  }

  .ms-xxl-n7 {
    margin-left: -2rem !important;
  }

  .ms-xxl-n8 {
    margin-left: -2.5rem !important;
  }

  .ms-xxl-n9 {
    margin-left: -3rem !important;
  }

  .ms-xxl-n10 {
    margin-left: -3.5rem !important;
  }

  .ms-xxl-n11 {
    margin-left: -4rem !important;
  }

  .ms-xxl-n12 {
    margin-left: -5rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.25rem !important;
  }

  .p-xxl-5 {
    padding: 1.5rem !important;
  }

  .p-xxl-6 {
    padding: 1.75rem !important;
  }

  .p-xxl-7 {
    padding: 2rem !important;
  }

  .p-xxl-8 {
    padding: 2.5rem !important;
  }

  .p-xxl-9 {
    padding: 3rem !important;
  }

  .p-xxl-10 {
    padding: 3.5rem !important;
  }

  .p-xxl-11 {
    padding: 4rem !important;
  }

  .p-xxl-12 {
    padding: 5rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-xxl-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-6 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-xxl-7 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xxl-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xxl-9 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-10 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xxl-11 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-12 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xxl-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-6 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xxl-7 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-9 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-10 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xxl-11 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-12 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.25rem !important;
  }

  .pt-xxl-5 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-6 {
    padding-top: 1.75rem !important;
  }

  .pt-xxl-7 {
    padding-top: 2rem !important;
  }

  .pt-xxl-8 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-9 {
    padding-top: 3rem !important;
  }

  .pt-xxl-10 {
    padding-top: 3.5rem !important;
  }

  .pt-xxl-11 {
    padding-top: 4rem !important;
  }

  .pt-xxl-12 {
    padding-top: 5rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.25rem !important;
  }

  .pe-xxl-5 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-6 {
    padding-right: 1.75rem !important;
  }

  .pe-xxl-7 {
    padding-right: 2rem !important;
  }

  .pe-xxl-8 {
    padding-right: 2.5rem !important;
  }

  .pe-xxl-9 {
    padding-right: 3rem !important;
  }

  .pe-xxl-10 {
    padding-right: 3.5rem !important;
  }

  .pe-xxl-11 {
    padding-right: 4rem !important;
  }

  .pe-xxl-12 {
    padding-right: 5rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xxl-11 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 5rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.25rem !important;
  }

  .ps-xxl-5 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-6 {
    padding-left: 1.75rem !important;
  }

  .ps-xxl-7 {
    padding-left: 2rem !important;
  }

  .ps-xxl-8 {
    padding-left: 2.5rem !important;
  }

  .ps-xxl-9 {
    padding-left: 3rem !important;
  }

  .ps-xxl-10 {
    padding-left: 3.5rem !important;
  }

  .ps-xxl-11 {
    padding-left: 4rem !important;
  }

  .ps-xxl-12 {
    padding-left: 5rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.34375rem !important;
  }

  .fs-2 {
    font-size: 1.875rem !important;
  }

  .fs-3 {
    font-size: 1.640625rem !important;
  }

  .fs-4 {
    font-size: 1.40625rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
.border-dashed.border-bottom {
  border-bottom-style: dashed !important;
}
.border-dashed.border-top {
  border-top-style: dashed !important;
}
.border-dashed.border-end {
  border-right-style: dashed !important;
}
.border-dashed.border-start {
  border-left-style: dashed !important;
}
.border-dashed.border {
  border-style: dashed !important;
}

.min-vh-10 {
  min-height: 10vh !important;
}

.min-vh-20 {
  min-height: 20vh !important;
}

.min-vh-30 {
  min-height: 30vh !important;
}

.min-vh-40 {
  min-height: 40vh !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.min-vh-60 {
  min-height: 60vh !important;
}

.min-vh-70 {
  min-height: 70vh !important;
}

.min-vh-80 {
  min-height: 80vh !important;
}

.min-vh-90 {
  min-height: 90vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vh-5 {
  height: 5vh !important;
}

.vh-10 {
  height: 10vh !important;
}

.vh-15 {
  height: 15vh !important;
}

.vh-20 {
  height: 20vh !important;
}

.vh-25 {
  height: 25vh !important;
}

.vh-30 {
  height: 30vh !important;
}

.vh-35 {
  height: 35vh !important;
}

.vh-40 {
  height: 40vh !important;
}

.vh-45 {
  height: 45vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-55 {
  height: 55vh !important;
}

.vh-60 {
  height: 60vh !important;
}

.vh-65 {
  height: 65vh !important;
}

.vh-70 {
  height: 70vh !important;
}

.vh-75 {
  height: 75vh !important;
}

.vh-80 {
  height: 80vh !important;
}

.vh-85 {
  height: 85vh !important;
}

.vh-90 {
  height: 90vh !important;
}

.vh-95 {
  height: 95vh !important;
}

.vh-100 {
  height: 100vh !important;
}

.vw-5 {
  width: 5vw;
}

.vw-10 {
  width: 10vw;
}

.vw-15 {
  width: 15vw;
}

.vw-20 {
  width: 20vw;
}

.vw-25 {
  width: 25vw;
}

.vw-30 {
  width: 30vw;
}

.vw-35 {
  width: 35vw;
}

.vw-40 {
  width: 40vw;
}

.vw-45 {
  width: 45vw;
}

.vw-50 {
  width: 50vw;
}

.vw-55 {
  width: 55vw;
}

.vw-60 {
  width: 60vw;
}

.vw-65 {
  width: 65vw;
}

.vw-70 {
  width: 70vw;
}

.vw-75 {
  width: 75vw;
}

.vw-80 {
  width: 80vw;
}

.vw-85 {
  width: 85vw;
}

.vw-90 {
  width: 90vw;
}

.vw-95 {
  width: 95vw;
}

.vw-100 {
  width: 100vw;
}

.height-5 {
  height: 5px !important;
}

.height-10 {
  height: 10px !important;
}

.height-15 {
  height: 15px !important;
}

.height-20 {
  height: 20px !important;
}

.height-25 {
  height: 25px !important;
}

.height-30 {
  height: 30px !important;
}

.height-35 {
  height: 35px !important;
}

.height-40 {
  height: 40px !important;
}

.height-45 {
  height: 45px !important;
}

.height-50 {
  height: 50px !important;
}

.height-55 {
  height: 55px !important;
}

.height-60 {
  height: 60px !important;
}

.height-65 {
  height: 65px !important;
}

.height-70 {
  height: 70px !important;
}

.height-75 {
  height: 75px !important;
}

.height-80 {
  height: 80px !important;
}

.height-85 {
  height: 85px !important;
}

.height-90 {
  height: 90px !important;
}

.height-95 {
  height: 95px !important;
}

.height-100 {
  height: 100px !important;
}

.height-105 {
  height: 105px !important;
}

.height-110 {
  height: 110px !important;
}

.height-115 {
  height: 115px !important;
}

.height-120 {
  height: 120px !important;
}

.height-125 {
  height: 125px !important;
}

.height-130 {
  height: 130px !important;
}

.height-135 {
  height: 135px !important;
}

.height-140 {
  height: 140px !important;
}

.height-145 {
  height: 145px !important;
}

.height-150 {
  height: 150px !important;
}

.height-155 {
  height: 155px !important;
}

.height-160 {
  height: 160px !important;
}

.height-165 {
  height: 165px !important;
}

.height-170 {
  height: 170px !important;
}

.height-175 {
  height: 175px !important;
}

.height-180 {
  height: 180px !important;
}

.height-185 {
  height: 185px !important;
}

.height-190 {
  height: 190px !important;
}

.height-195 {
  height: 195px !important;
}

.height-200 {
  height: 200px !important;
}

.height-205 {
  height: 205px !important;
}

.height-210 {
  height: 210px !important;
}

.height-215 {
  height: 215px !important;
}

.height-220 {
  height: 220px !important;
}

.height-225 {
  height: 225px !important;
}

.height-230 {
  height: 230px !important;
}

.height-235 {
  height: 235px !important;
}

.height-240 {
  height: 240px !important;
}

.height-245 {
  height: 245px !important;
}

.height-250 {
  height: 250px !important;
}

.height-255 {
  height: 255px !important;
}

.height-260 {
  height: 260px !important;
}

.height-265 {
  height: 265px !important;
}

.height-270 {
  height: 270px !important;
}

.height-275 {
  height: 275px !important;
}

.height-280 {
  height: 280px !important;
}

.height-285 {
  height: 285px !important;
}

.height-290 {
  height: 290px !important;
}

.height-295 {
  height: 295px !important;
}

.height-300 {
  height: 300px !important;
}

.height-305 {
  height: 305px !important;
}

.height-310 {
  height: 310px !important;
}

.height-315 {
  height: 315px !important;
}

.height-320 {
  height: 320px !important;
}

.height-325 {
  height: 325px !important;
}

.height-330 {
  height: 330px !important;
}

.height-335 {
  height: 335px !important;
}

.height-340 {
  height: 340px !important;
}

.height-345 {
  height: 345px !important;
}

.height-350 {
  height: 350px !important;
}

.width-5 {
  width: 5px !important;
}

.width-10 {
  width: 10px !important;
}

.width-15 {
  width: 15px !important;
}

.width-20 {
  width: 20px !important;
}

.width-25 {
  width: 25px !important;
}

.width-30 {
  width: 30px !important;
}

.width-35 {
  width: 35px !important;
}

.width-40 {
  width: 40px !important;
}

.width-45 {
  width: 45px !important;
}

.width-50 {
  width: 50px !important;
}

.width-55 {
  width: 55px !important;
}

.width-60 {
  width: 60px !important;
}

.width-65 {
  width: 65px !important;
}

.width-70 {
  width: 70px !important;
}

.width-75 {
  width: 75px !important;
}

.width-80 {
  width: 80px !important;
}

.width-85 {
  width: 85px !important;
}

.width-90 {
  width: 90px !important;
}

.width-95 {
  width: 95px !important;
}

.width-100 {
  width: 100px !important;
}

.width-105 {
  width: 105px !important;
}

.width-110 {
  width: 110px !important;
}

.width-115 {
  width: 115px !important;
}

.width-120 {
  width: 120px !important;
}

.width-125 {
  width: 125px !important;
}

.width-130 {
  width: 130px !important;
}

.width-135 {
  width: 135px !important;
}

.width-140 {
  width: 140px !important;
}

.width-145 {
  width: 145px !important;
}

.width-150 {
  width: 150px !important;
}

.width-155 {
  width: 155px !important;
}

.width-160 {
  width: 160px !important;
}

.width-165 {
  width: 165px !important;
}

.width-170 {
  width: 170px !important;
}

.width-175 {
  width: 175px !important;
}

.width-180 {
  width: 180px !important;
}

.width-185 {
  width: 185px !important;
}

.width-190 {
  width: 190px !important;
}

.width-195 {
  width: 195px !important;
}

.width-200 {
  width: 200px !important;
}

.width-205 {
  width: 205px !important;
}

.width-210 {
  width: 210px !important;
}

.width-215 {
  width: 215px !important;
}

.width-220 {
  width: 220px !important;
}

.width-225 {
  width: 225px !important;
}

.width-230 {
  width: 230px !important;
}

.width-235 {
  width: 235px !important;
}

.width-240 {
  width: 240px !important;
}

.width-245 {
  width: 245px !important;
}

.width-250 {
  width: 250px !important;
}

.width-255 {
  width: 255px !important;
}

.width-260 {
  width: 260px !important;
}

.width-265 {
  width: 265px !important;
}

.width-270 {
  width: 270px !important;
}

.width-275 {
  width: 275px !important;
}

.width-280 {
  width: 280px !important;
}

.width-285 {
  width: 285px !important;
}

.width-290 {
  width: 290px !important;
}

.width-295 {
  width: 295px !important;
}

.width-300 {
  width: 300px !important;
}

.width-305 {
  width: 305px !important;
}

.width-310 {
  width: 310px !important;
}

.width-315 {
  width: 315px !important;
}

.width-320 {
  width: 320px !important;
}

.width-325 {
  width: 325px !important;
}

.width-330 {
  width: 330px !important;
}

.width-335 {
  width: 335px !important;
}

.width-340 {
  width: 340px !important;
}

.width-345 {
  width: 345px !important;
}

.width-350 {
  width: 350px !important;
}

.size-5 {
  width: 5px !important;
  height: 5px !important;
}

.size-10 {
  width: 10px !important;
  height: 10px !important;
}

.size-15 {
  width: 15px !important;
  height: 15px !important;
}

.size-20 {
  width: 20px !important;
  height: 20px !important;
}

.size-25 {
  width: 25px !important;
  height: 25px !important;
}

.size-30 {
  width: 30px !important;
  height: 30px !important;
}

.size-35 {
  width: 35px !important;
  height: 35px !important;
}

.size-40 {
  width: 40px !important;
  height: 40px !important;
}

.size-45 {
  width: 45px !important;
  height: 45px !important;
}

.size-50 {
  width: 50px !important;
  height: 50px !important;
}

.size-55 {
  width: 55px !important;
  height: 55px !important;
}

.size-60 {
  width: 60px !important;
  height: 60px !important;
}

.size-65 {
  width: 65px !important;
  height: 65px !important;
}

.size-70 {
  width: 70px !important;
  height: 70px !important;
}

.size-75 {
  width: 75px !important;
  height: 75px !important;
}

.size-80 {
  width: 80px !important;
  height: 80px !important;
}

.size-85 {
  width: 85px !important;
  height: 85px !important;
}

.size-90 {
  width: 90px !important;
  height: 90px !important;
}

.size-95 {
  width: 95px !important;
  height: 95px !important;
}

.size-100 {
  width: 100px !important;
  height: 100px !important;
}

.size-105 {
  width: 105px !important;
  height: 105px !important;
}

.size-110 {
  width: 110px !important;
  height: 110px !important;
}

.size-115 {
  width: 115px !important;
  height: 115px !important;
}

.size-120 {
  width: 120px !important;
  height: 120px !important;
}

.size-125 {
  width: 125px !important;
  height: 125px !important;
}

.size-130 {
  width: 130px !important;
  height: 130px !important;
}

.size-135 {
  width: 135px !important;
  height: 135px !important;
}

.size-140 {
  width: 140px !important;
  height: 140px !important;
}

.size-145 {
  width: 145px !important;
  height: 145px !important;
}

.size-150 {
  width: 150px !important;
  height: 150px !important;
}

.size-155 {
  width: 155px !important;
  height: 155px !important;
}

.size-160 {
  width: 160px !important;
  height: 160px !important;
}

.size-165 {
  width: 165px !important;
  height: 165px !important;
}

.size-170 {
  width: 170px !important;
  height: 170px !important;
}

.size-175 {
  width: 175px !important;
  height: 175px !important;
}

.size-180 {
  width: 180px !important;
  height: 180px !important;
}

.size-185 {
  width: 185px !important;
  height: 185px !important;
}

.size-190 {
  width: 190px !important;
  height: 190px !important;
}

.size-195 {
  width: 195px !important;
  height: 195px !important;
}

.size-200 {
  width: 200px !important;
  height: 200px !important;
}

.size-205 {
  width: 205px !important;
  height: 205px !important;
}

.size-210 {
  width: 210px !important;
  height: 210px !important;
}

.size-215 {
  width: 215px !important;
  height: 215px !important;
}

.size-220 {
  width: 220px !important;
  height: 220px !important;
}

.size-225 {
  width: 225px !important;
  height: 225px !important;
}

.size-230 {
  width: 230px !important;
  height: 230px !important;
}

.size-235 {
  width: 235px !important;
  height: 235px !important;
}

.size-240 {
  width: 240px !important;
  height: 240px !important;
}

.size-245 {
  width: 245px !important;
  height: 245px !important;
}

.size-250 {
  width: 250px !important;
  height: 250px !important;
}

.size-255 {
  width: 255px !important;
  height: 255px !important;
}

.size-260 {
  width: 260px !important;
  height: 260px !important;
}

.size-265 {
  width: 265px !important;
  height: 265px !important;
}

.size-270 {
  width: 270px !important;
  height: 270px !important;
}

.size-275 {
  width: 275px !important;
  height: 275px !important;
}

.size-280 {
  width: 280px !important;
  height: 280px !important;
}

.size-285 {
  width: 285px !important;
  height: 285px !important;
}

.size-290 {
  width: 290px !important;
  height: 290px !important;
}

.size-295 {
  width: 295px !important;
  height: 295px !important;
}

.size-300 {
  width: 300px !important;
  height: 300px !important;
}

.size-305 {
  width: 305px !important;
  height: 305px !important;
}

.size-310 {
  width: 310px !important;
  height: 310px !important;
}

.size-315 {
  width: 315px !important;
  height: 315px !important;
}

.size-320 {
  width: 320px !important;
  height: 320px !important;
}

.size-325 {
  width: 325px !important;
  height: 325px !important;
}

.size-330 {
  width: 330px !important;
  height: 330px !important;
}

.size-335 {
  width: 335px !important;
  height: 335px !important;
}

.size-340 {
  width: 340px !important;
  height: 340px !important;
}

.size-345 {
  width: 345px !important;
  height: 345px !important;
}

.size-350 {
  width: 350px !important;
  height: 350px !important;
}

@media (min-width: 768px) {
  .w-md-5 {
    width: 5% !important;
  }

  .w-md-10 {
    width: 10% !important;
  }

  .w-md-15 {
    width: 15% !important;
  }

  .w-md-20 {
    width: 20% !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-30 {
    width: 30% !important;
  }

  .w-md-35 {
    width: 35% !important;
  }

  .w-md-40 {
    width: 40% !important;
  }

  .w-md-45 {
    width: 45% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-55 {
    width: 55% !important;
  }

  .w-md-60 {
    width: 60% !important;
  }

  .w-md-65 {
    width: 65% !important;
  }

  .w-md-70 {
    width: 70% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-80 {
    width: 80% !important;
  }

  .w-md-85 {
    width: 85% !important;
  }

  .w-md-90 {
    width: 90% !important;
  }

  .w-md-95 {
    width: 95% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .h-md-5 {
    height: 5% !important;
  }

  .h-md-10 {
    height: 10% !important;
  }

  .h-md-15 {
    height: 15% !important;
  }

  .h-md-20 {
    height: 20% !important;
  }

  .h-md-25 {
    height: 25% !important;
  }

  .h-md-30 {
    height: 30% !important;
  }

  .h-md-35 {
    height: 35% !important;
  }

  .h-md-40 {
    height: 40% !important;
  }

  .h-md-45 {
    height: 45% !important;
  }

  .h-md-50 {
    height: 50% !important;
  }

  .h-md-55 {
    height: 55% !important;
  }

  .h-md-60 {
    height: 60% !important;
  }

  .h-md-65 {
    height: 65% !important;
  }

  .h-md-70 {
    height: 70% !important;
  }

  .h-md-75 {
    height: 75% !important;
  }

  .h-md-80 {
    height: 80% !important;
  }

  .h-md-85 {
    height: 85% !important;
  }

  .h-md-90 {
    height: 90% !important;
  }

  .h-md-95 {
    height: 95% !important;
  }

  .h-md-100 {
    height: 100% !important;
  }
}
@media (min-width: 992px) {
  .w-lg-5 {
    width: 5% !important;
  }

  .w-lg-10 {
    width: 10% !important;
  }

  .w-lg-15 {
    width: 15% !important;
  }

  .w-lg-20 {
    width: 20% !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-30 {
    width: 30% !important;
  }

  .w-lg-35 {
    width: 35% !important;
  }

  .w-lg-40 {
    width: 40% !important;
  }

  .w-lg-45 {
    width: 45% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-55 {
    width: 55% !important;
  }

  .w-lg-60 {
    width: 60% !important;
  }

  .w-lg-65 {
    width: 65% !important;
  }

  .w-lg-70 {
    width: 70% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-80 {
    width: 80% !important;
  }

  .w-lg-85 {
    width: 85% !important;
  }

  .w-lg-90 {
    width: 90% !important;
  }

  .w-lg-95 {
    width: 95% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .h-lg-5 {
    height: 5% !important;
  }

  .h-lg-10 {
    height: 10% !important;
  }

  .h-lg-15 {
    height: 15% !important;
  }

  .h-lg-20 {
    height: 20% !important;
  }

  .h-lg-25 {
    height: 25% !important;
  }

  .h-lg-30 {
    height: 30% !important;
  }

  .h-lg-35 {
    height: 35% !important;
  }

  .h-lg-40 {
    height: 40% !important;
  }

  .h-lg-45 {
    height: 45% !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .h-lg-55 {
    height: 55% !important;
  }

  .h-lg-60 {
    height: 60% !important;
  }

  .h-lg-65 {
    height: 65% !important;
  }

  .h-lg-70 {
    height: 70% !important;
  }

  .h-lg-75 {
    height: 75% !important;
  }

  .h-lg-80 {
    height: 80% !important;
  }

  .h-lg-85 {
    height: 85% !important;
  }

  .h-lg-90 {
    height: 90% !important;
  }

  .h-lg-95 {
    height: 95% !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }
}
.min-height-100 {
  min-height: 100%;
}

.overflow-scroll-y {
  overflow-y: scroll;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.z-index-4 {
  z-index: 4 !important;
}

.z-index-fixed {
  z-index: 1030 !important;
}

.flex-root {
  flex: 1;
}

.flex-column-fluid {
  flex: 1 0 auto;
}

.flex-row-fluid {
  flex: 1 auto;
  min-width: 0;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  letter-spacing: 0.005rem;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 500;
  letter-spacing: 0.01rem;
}

*:focus {
  outline: 0 !important;
}

a, button {
  transition: all 0.2s ease-in-out;
}

.text-mono {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

strong,
b {
  font-weight: 500;
}

a:not(.nav-link):not(.btn):not(.dropdown-item):not(.badge).text-dark:hover {
  color: #6f0000 !important;
}
a:not(.nav-link):not(.btn):not(.dropdown-item):not(.badge).text-secondary:hover {
  color: #221515 !important;
}
a:not(.nav-link):not(.btn):not(.dropdown-item):not(.badge).text-muted:hover {
  color: #221515 !important;
}

.text-white h1, .text-white .h1,
.text-white h2,
.text-white .h2,
.text-white h3,
.text-white .h3,
.text-white h4,
.text-white .h4,
.text-white h5,
.text-white .h5,
.text-white h6,
.text-white .h6,
.text-light h1,
.text-light .h1,
.text-light h2,
.text-light .h2,
.text-light h3,
.text-light .h3,
.text-light h4,
.text-light .h4,
.text-light h5,
.text-light .h5,
.text-light h6,
.text-light .h6 {
  color: #fff !important;
}
.text-white a:not(.btn):not(.dropdown-item):not([class*=bg-]),
.text-light a:not(.btn):not(.dropdown-item):not([class*=bg-]) {
  color: #fff;
}
.text-white .text-muted,
.text-light .text-muted {
  color: rgba(255, 255, 255, 0.75) !important;
}

.text-white .breadcrumb-item:not(.active), .text-light .breadcrumb-item:not(.active) {
  color: rgba(255, 255, 255, 0.75);
}
.text-white .breadcrumb-item + .breadcrumb-item::before, .text-light .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.4);
}
.text-white .breadcrumb-item.active, .text-light .breadcrumb-item.active {
  color: rgba(255, 255, 255, 0.75);
}

.text-white .form-control, .text-light .form-control {
  border-color: rgba(255, 255, 255, 0.4);
  background-color: transparent;
  color: #fff;
}
.text-white .form-control::placeholder, .text-white .form-control::-webkit-placeholder, .text-white .form-control::-ms-placeholder, .text-light .form-control::placeholder, .text-light .form-control::-webkit-placeholder, .text-light .form-control::-ms-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

@keyframes scale {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0;
  }
}
.anim-scale {
  animation: 1s scale forwards infinite 1s;
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
.anim-spinner {
  -webkit-animation: spinner 0.75s ease-in-out infinite;
  animation: spinner 0.75s ease-in-out infinite;
}

.typing {
  align-items: center;
  display: inline-flex;
}

.typing .dot {
  animation: typingAnimation 1.8s infinite ease-in-out;
  background-color: currentColor;
  border-radius: 50%;
  height: 4px;
  width: 4px;
  vertical-align: middle;
  display: inline-block;
}

.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
  margin: 0px 0.25rem;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.typing .dot:last-child {
  margin-right: 0;
}

@keyframes typingAnimation {
  0% {
    transform: translateY(0px);
    background-color: currentColor;
    opacity: 1;
  }
  28% {
    transform: translateY(-7px);
    background-color: currentColor;
    opacity: 0.5;
  }
  44% {
    transform: translateY(0px);
    background-color: currentColor;
    opacity: 0.25;
  }
}
.avatar {
  width: 3rem;
  height: 3rem;
  font-size: 0.8203125rem;
}
.avatar.xs {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.6328125rem;
}
.avatar.sm {
  width: 2rem;
  height: 2rem;
  font-size: 0.7669921875rem;
}
.avatar.lg {
  width: 4rem;
  height: 4rem;
  font-size: 0.9375rem;
}
.avatar.xl {
  width: 5rem;
  height: 5rem;
  font-size: 1.1484375rem;
}
.avatar.xxl {
  width: 7rem;
  height: 7rem;
  font-size: 1.1484375rem;
}

.avatar-status {
  position: relative;
}
.avatar-status::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 5%;
  width: 25%;
  height: 25%;
  border-radius: 100%;
  border: 2px solid #fff;
  display: table;
}
.avatar-status.status-online::before {
  background-color: #4BC98D;
}
.avatar-status.status-offline::before {
  background-color: #efe2e2;
}
.avatar-status.status-dnd::before {
  background-color: #ff4d62;
}
.avatar-status.status-away::before {
  background-color: #ffb016;
}

.avatar-group {
  display: inline-flex;
  align-items: stretch;
}
.avatar-group .avatar-group-item {
  display: inline-block;
  position: relative;
  margin-left: -0.5rem;
  border: 2px solid #fff;
}
.avatar-group .avatar-group-item .avatar-group-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.avatar-group .avatar-group-item:hover {
  z-index: 1;
  position: relative;
}

.btn {
  text-transform: capitalize;
  font-weight: 400;
}

.btn-white {
  border-color: #faf1f1;
}
.btn-white:hover, .btn-white:focus {
  background-color: #fef6f6;
  border-color: #efe2e2;
}

.btn-group-toggle .btn-white:not(:disabled):not(.disabled):active,
.btn-group-toggle .btn-white:not(:disabled):not(.disabled).active,
.btn-group-toggle .btn-check:checked + .btn-white {
  background-color: #fff;
  border-color: #b78080;
  box-shadow: none;
  color: #6f0000;
}

.btn-group-toggle .btn-white:focus,
.btn-group-toggle .btn-white.focus {
  box-shadow: none;
}

.dropdown-toggle::after, .dropdown-toggle::before {
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  transition: transform 0.25s ease-in-out;
  vertical-align: middle;
  font-size: 0.8203125rem;
}

.dropdown-toggle::after {
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  content: "";
}

.dropend .dropdown-toggle::after {
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  content: "";
  vertical-align: -0.06em;
}

.dropstart .dropdown-toggle::before {
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  content: "";
  vertical-align: -0.06em;
}

.dropup .dropdown-toggle::after {
  content: "";
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  vertical-align: -0.022em;
}

.dropdown-toggle.show::after {
  transform: rotate(180deg);
}

.dropend > .dropdown-toggle.show::after {
  transform: rotate(90deg);
}

.dropstart > .dropdown-toggle.show::before {
  transform: rotate(-90deg);
}

.progress-bar {
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.navbar .nav-link {
  transition: all 0.2s ease-in-out;
}

.nav-tabs .nav-item {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.nav-tabs .nav-link {
  padding: 1rem 0;
  border-bottom: 1px solid transparent;
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
}
.nav-tabs .nav-link:not(.active) {
  color: #785f5f;
}
.nav-tabs .nav-link:not(.active):hover {
  color: #593e3e;
}
.nav-tabs .nav-link.active {
  border-bottom-color: #6f0000;
  background-color: transparent;
}
.nav-tabs .nav-item:first-child {
  margin-left: 0;
}
.nav-tabs .nav-item:last-child {
  margin-right: 0;
}
.nav-tabs .nav-item.show .nav-link {
  border-color: transparent;
}
.nav-tabs.nav-vertical {
  flex-direction: column;
  border-bottom: 0px;
}
.nav-tabs.nav-vertical .nav-item {
  margin-left: 0px;
  margin-right: 0px;
}
.nav-tabs.nav-vertical .nav-item:not(:first-child) {
  margin-top: 0.25rem;
}
.nav-tabs.nav-vertical .nav-link {
  border-bottom-width: 0px;
  padding: 0.5rem 1rem;
  border-radius: 0.875rem;
}
.nav-tabs.nav-vertical .nav-link:not(.active) {
  color: #785f5f;
}
.nav-tabs.nav-vertical .nav-link:not(.active):hover {
  color: #6f0000;
  background-color: #f1e6e6;
}
.nav-tabs.nav-vertical .nav-link.active {
  color: #6f0000;
  background-color: #e2cccc;
}

.nav-overflow {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 1px;
}
.nav-overflow::-webkit-scrollbar {
  display: none;
}

.dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(34, 21, 21, 0.04);
}

.dropdown-item {
  font-weight: 400;
  border-radius: 0.5rem;
}

.dropdown-item {
  text-transform: capitalize;
}

@media (min-width: 992px) {
  .dropdown-menu-sm {
    width: 380px;
    max-width: 380px;
  }
}
@media (max-width: 991.98px) {
  .navbar .nav-item.dropdown {
    position: static !important;
    display: block;
    height: auto;
  }
  .navbar .dropdown-menu .dropdown-menu {
    display: block;
    box-shadow: none;
    padding-left: 1rem;
  }
  .navbar .nav-item > .dropdown-menu[data-bs-popper] {
    width: 94%;
    max-width: 94%;
    left: 3%;
    top: 100%;
    right: 3%;
  }
  .navbar .nav-item > .dropdown-menu[data-bs-popper] .dropstart .dropdown-menu, .navbar .nav-item > .dropdown-menu[data-bs-popper] .dropend .dropdown-menu {
    position: static;
  }
}
.list-group-flush > .list-group-item {
  padding-left: 0;
  padding-right: 0;
}

.card-hover .hover-image {
  overflow: hidden;
}
.card-hover .hover-image img {
  transition: all 0.3s;
}
.card-hover .hover-image .hover-image-overlay {
  background-image: linear-gradient(180deg, transparent 20%, rgba(34, 21, 21, 0.4) 100%);
  transition: all 0.2s;
  opacity: 0;
  visibility: hidden;
}
.card-hover .hover-image .hover-image-overlay span {
  display: block;
  opacity: 0;
  transition: all 0.45s;
  transform: translateY(-10px);
}
.card-hover:hover .hover-image img {
  transform: scale(1.1);
}
.card-hover:hover .hover-image .hover-image-overlay {
  visibility: visible;
  opacity: 1;
}
.card-hover:hover .hover-image .hover-image-overlay span {
  transform: none;
  opacity: 1;
}

.form-check .form-check-input {
  cursor: pointer;
}

.form-switch-label {
  cursor: pointer;
}

.form-switch {
  min-height: 1.5rem;
}

.form-switch > .form-check-input {
  cursor: pointer;
  height: 1.5rem;
  margin-top: 0;
}

.form-switch > .form-check-label {
  margin-top: calc((1.5rem - 1.5 * 1em) / 2);
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-05 {
  opacity: 0.05 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.bg-tint-primary {
  background-color: #e2cccc !important;
}

a.bg-tint-primary:hover {
  background-color: #d4b3b3 !important;
}

.bg-tint-secondary {
  background-color: #ded8d8 !important;
}

a.bg-tint-secondary:hover {
  background-color: #cdc5c5 !important;
}

.bg-tint-success {
  background-color: #dbf4e8 !important;
}

a.bg-tint-success:hover {
  background-color: #c9efdd !important;
}

.bg-tint-info {
  background-color: #d4f9ff !important;
}

a.bg-tint-info:hover {
  background-color: #bff5ff !important;
}

.bg-tint-warning {
  background-color: #ffefd0 !important;
}

a.bg-tint-warning:hover {
  background-color: #ffe7b9 !important;
}

.bg-tint-danger {
  background-color: #ffdbe0 !important;
}

a.bg-tint-danger:hover {
  background-color: #ffcad0 !important;
}

.bg-tint-white {
  background-color: white !important;
}

a.bg-tint-white:hover {
  background-color: white !important;
}

.bg-tint-light {
  background-color: #fffdfd !important;
}

a.bg-tint-light:hover {
  background-color: #fffcfc !important;
}

.bg-tint-gray {
  background-color: #fcf9f9 !important;
}

a.bg-tint-gray:hover {
  background-color: #faf6f6 !important;
}

.bg-tint-dark {
  background-color: #d3d0d0 !important;
}

a.bg-tint-dark:hover {
  background-color: #bdb9b9 !important;
}

.bg-shade-primary {
  background-color: #1c0000 !important;
}

.bg-shade-secondary {
  background-color: #161010 !important;
}

.bg-shade-success {
  background-color: #133223 !important;
}

.bg-shade-info {
  background-color: #0b3840 !important;
}

.bg-shade-warning {
  background-color: #402c06 !important;
}

.bg-shade-danger {
  background-color: #401319 !important;
}

.bg-shade-white {
  background-color: #404040 !important;
}

.bg-shade-light {
  background-color: #403e3e !important;
}

.bg-shade-gray {
  background-color: #3c3939 !important;
}

.bg-shade-dark {
  background-color: #090505 !important;
}

.hover-bg-primary:hover {
  background-color: #e2cccc !important;
}

.hover-bg-secondary:hover {
  background-color: #ded8d8 !important;
}

.hover-bg-success:hover {
  background-color: #dbf4e8 !important;
}

.hover-bg-info:hover {
  background-color: #d4f9ff !important;
}

.hover-bg-warning:hover {
  background-color: #ffefd0 !important;
}

.hover-bg-danger:hover {
  background-color: #ffdbe0 !important;
}

.hover-bg-white:hover {
  background-color: white !important;
}

.hover-bg-light:hover {
  background-color: #fffdfd !important;
}

.hover-bg-gray:hover {
  background-color: #fcf9f9 !important;
}

.hover-bg-dark:hover {
  background-color: #d3d0d0 !important;
}

.bg-gradient-dark {
  background: linear-gradient(53.38deg, #160000 18.37%, #060000 140.92%);
}

.bg-gradient-primary {
  background: linear-gradient(53.38deg, #6f0000 18.37%, #ff4d62 140.92%);
}

.page-sidebar,
.aside {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.page-sidebar {
  width: 260px;
  height: 100%;
  position: fixed;
  z-index: 1030;
  left: 0;
  top: 0;
  overflow: hidden;
}
.page-sidebar [data-simplebar] {
  overflow-x: hidden;
}
.page-sidebar .aside-footer {
  min-width: 260px;
  transition: all 0.35s;
}
.page-sidebar .nav-link {
  width: 100%;
  border-radius: 0.875rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-weight: 400;
}
.page-sidebar .simplebar-content {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.page-sidebar .nav-link[data-bs-toggle=collapse],
.page-sidebar .sidebar-link[data-bs-toggle=collapse] {
  display: flex;
  align-items: center;
}
.page-sidebar .nav-link[data-bs-toggle=collapse]::after,
.page-sidebar .sidebar-link[data-bs-toggle=collapse]::after {
  display: inline-block;
  font-size: 0.8203125rem;
  margin-right: 0.5rem;
  margin-left: auto;
  content: "";
  font-family: bootstrap-icons;
  font-weight: normal;
  transition: transform 0.15s ease-in-out;
  opacity: 0.5;
}
.page-sidebar .nav-link[data-bs-toggle=collapse][aria-expanded=true],
.page-sidebar .sidebar-link[data-bs-toggle=collapse][aria-expanded=true] {
  color: #6f0000;
}
.page-sidebar .nav-link[data-bs-toggle=collapse][aria-expanded=true]::after,
.page-sidebar .sidebar-link[data-bs-toggle=collapse][aria-expanded=true]::after {
  transform: rotate(180deg);
}
.page-sidebar .nav-link[data-bs-toggle=collapse].active,
.page-sidebar .sidebar-link[data-bs-toggle=collapse].active {
  color: #6f0000;
}
.page-sidebar .nav-link[data-bs-toggle=collapse]:hover,
.page-sidebar .sidebar-link[data-bs-toggle=collapse]:hover {
  color: #6f0000;
}

.sidebar-icon {
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
}
.sidebar-icon > i {
  font-size: 1.171875rem;
  line-height: 1;
  vertical-align: middle;
  font-weight: lighter !important;
}

.sidebar-dropdown {
  padding-left: 2.75rem;
}
.sidebar-dropdown .sidebar-item {
  display: block;
}
.sidebar-dropdown .sidebar-item .sidebar-link {
  font-size: 0.88734375rem;
  padding: 0.4rem 1rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
}
.sidebar-dropdown .sidebar-dropdown {
  padding-left: 0.5rem;
}

.aside-light .aside-logo a,
.aside-light .sidebar-title {
  color: #221515;
}
.aside-light .nav-link,
.aside-light .nav-link[data-bs-toggle=collapse],
.aside-light .sidebar-item .sidebar-link {
  color: #785f5f;
}
.aside-light .nav-link:hover, .aside-light .nav-link[aria-expanded=true],
.aside-light .nav-link[data-bs-toggle=collapse]:hover,
.aside-light .nav-link[data-bs-toggle=collapse][aria-expanded=true],
.aside-light .sidebar-item .sidebar-link:hover,
.aside-light .sidebar-item .sidebar-link[aria-expanded=true] {
  color: #6f0000;
}
.aside-light .nav-link.active,
.aside-light .nav-link[data-bs-toggle=collapse].active,
.aside-light .sidebar-item .sidebar-link.active {
  color: #6f0000;
}

.aside-dark .aside-logo a,
.aside-dark .sidebar-title {
  color: white;
}
.aside-dark .nav-link,
.aside-dark .nav-link[data-bs-toggle=collapse],
.aside-dark .sidebar-item .sidebar-link {
  color: rgba(255, 255, 255, 0.65);
}
.aside-dark .nav-link:hover, .aside-dark .nav-link[aria-expanded=true],
.aside-dark .nav-link[data-bs-toggle=collapse]:hover,
.aside-dark .nav-link[data-bs-toggle=collapse][aria-expanded=true],
.aside-dark .sidebar-item .sidebar-link:hover,
.aside-dark .sidebar-item .sidebar-link[aria-expanded=true] {
  color: white;
}
.aside-dark .nav-link.active,
.aside-dark .nav-link[data-bs-toggle=collapse].active,
.aside-dark .sidebar-item .sidebar-link.active {
  color: #fff;
}

.sidebar-title {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  opacity: 0.4;
  font-size: 0.8203125rem;
}
.sidebar-title > i {
  display: flex;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
}

.page-header,
.aside-logo {
  height: 70px;
  display: flex;
  align-items: center;
}

.page-content {
  transition: margin 0.3s;
}

.page-sidebar {
  transition: width 0.3s;
}

@media (min-width: 992px) {
  .page-content {
    margin-left: 260px;
  }

  body.page-sidebar-mini .page-sidebar:not(:hover) {
    width: 84px;
  }
  body.page-sidebar-mini .page-sidebar:not(:hover) .sidebar-dropdown {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }
  body.page-sidebar-mini .page-sidebar:not(:hover) .sidebar-text,
body.page-sidebar-mini .page-sidebar:not(:hover) .logo-text,
body.page-sidebar-mini .page-sidebar:not(:hover) .sidebar-title > span {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  body.page-sidebar-mini .page-sidebar:not(:hover) .aside-footer {
    transform: translateX(-100%);
    opacity: 0;
  }
  body.page-sidebar-mini .page-content {
    margin-left: 84px;
  }
}
@media (max-width: 991.98px) {
  .page-content {
    margin-left: 0px;
  }

  .page-sidebar {
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: translateX(-100%);
    z-index: 1055;
  }

  .sidebar-close a {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s background-color 0.25s;
  }

  .page-sidebar-in {
    overflow: hidden;
  }
  .page-sidebar-in .page-sidebar {
    transform: translateX(0);
    box-shadow: 16px 0px 300px rgba(89, 62, 62, 0.225);
  }
  .page-sidebar-in .page-content {
    pointer-events: none;
  }
  .page-sidebar-in .sidebar-close a {
    opacity: 1;
    visibility: visible;
    background-color: rgba(89, 62, 62, 0.5);
    z-index: 1020;
  }
}
.sidebar-trigger {
  transition: transform cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
}
.sidebar-trigger.active {
  transform: rotate(180deg);
}

.popover {
  box-shadow: 0 0.5rem 1rem rgba(34, 21, 21, 0.04);
}

.tooltip-inner {
  box-shadow: 0 0.5rem 1rem rgba(34, 21, 21, 0.04);
}

.list-timeline-v {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.list-timeline-v li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}
.list-timeline-v li:not(:last-child) {
  margin-bottom: 1.5rem;
}
.list-timeline-v li .timeline-icon {
  margin-right: 1.5rem;
  z-index: 1;
  position: relative;
}
.list-timeline-v li .timeline-icon + div {
  -ms-flex: 1;
  flex: 1;
}

.timeline-icon {
  width: 3rem;
  height: 3rem;
  display: flex;
  line-height: 1;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.25rem;
  color: #6f0000;
}

@media (min-width: 768px) {
  .list-timeline-v li {
    width: 50%;
    margin-left: 50%;
    position: relative;
  }
  .list-timeline-v li .timeline-icon {
    margin-left: -1.5rem;
  }
  .list-timeline-v li:nth-child(even) {
    flex-direction: row-reverse;
    text-align: right;
    margin-left: 0;
    margin-right: 50%;
  }
  .list-timeline-v li:nth-child(even) .timeline-icon {
    margin-right: -1.5rem;
    margin-left: 1.5rem;
  }
  .list-timeline-v li:not(:last-child) {
    margin-bottom: 3rem;
    padding-bottom: 0;
  }
  .list-timeline-v li:not(:last-child):after {
    content: "";
    display: block;
    width: 1px;
    height: calc(100% - 2rem);
    border-left: 1px dashed #faf1f1;
    position: absolute;
    top: 4rem;
  }
}
.step .step-item {
  margin-bottom: 0.75rem;
}
.step .step-row {
  position: relative;
  display: flex;
  padding-bottom: 0.75rem;
}
.step .step-icon {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin-right: 1.25rem;
}
.step .step-icon::after {
  border-left: 1px solid #faf1f1;
  content: "";
  position: absolute;
  top: 3rem;
  left: calc(1.25rem - 1px);
  width: 1rem;
  height: calc(100% - 2.75rem);
}
.step .step-item:last-child {
  margin-bottom: 0;
}
.step .step-item:last-child .step-icon::after {
  height: 0;
}
.step .step-item:last-child .step-row {
  padding-bottom: 0px;
}

.board {
  display: block;
  white-space: nowrap;
  overflow-x: auto;
}

.task-list-items {
  min-height: 100px;
  position: relative;
  width: 20rem;
}
.task-list-items .card {
  white-space: normal;
}
.task-list-items:before {
  content: "No Tasks";
  position: absolute;
  line-height: 110px;
  width: 100%;
  padding-left: 1.25rem;
  display: block;
  font-weight: 500;
  color: #b49a9a;
  border: 1px dashed #faf1f1;
  border-radius: 0.875rem;
}

.tasks.tasks:not(:first-child) {
  border-left: 1px solid #efe2e2;
}

.loader {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  display: flex;
  overflow: hidden;
}

body.layout-horizontal .navbar .navbar-nav.navbar-icon .dropdown-menu {
  position: absolute;
}

@media (min-width: 576px) {
  .content-fixed-sm {
    overflow: hidden;
    position: relative;
  }
  .content-fixed-sm .content-fixed-body {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .content-fixed-sm .content-fixed-body [class~=col-], .content-fixed-sm .content-fixed-body [class*=col-] {
    height: 100%;
  }
  .content-fixed-sm .content-fixed-body .content-fixed-main .card-body {
    display: flex;
    flex-direction: column-reverse;
  }
  .content-fixed-sm .content-fixed-body .scroll {
    overflow-y: auto;
  }
}
@media (min-width: 768px) {
  .content-fixed-md {
    overflow: hidden;
    position: relative;
  }
  .content-fixed-md .content-fixed-body {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .content-fixed-md .content-fixed-body [class~=col-], .content-fixed-md .content-fixed-body [class*=col-] {
    height: 100%;
  }
  .content-fixed-md .content-fixed-body .content-fixed-main .card-body {
    display: flex;
    flex-direction: column-reverse;
  }
  .content-fixed-md .content-fixed-body .scroll {
    overflow-y: auto;
  }
}
@media (min-width: 992px) {
  .content-fixed-lg {
    overflow: hidden;
    position: relative;
  }
  .content-fixed-lg .content-fixed-body {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .content-fixed-lg .content-fixed-body [class~=col-], .content-fixed-lg .content-fixed-body [class*=col-] {
    height: 100%;
  }
  .content-fixed-lg .content-fixed-body .content-fixed-main .card-body {
    display: flex;
    flex-direction: column-reverse;
  }
  .content-fixed-lg .content-fixed-body .scroll {
    overflow-y: auto;
  }
}
@media (min-width: 1200px) {
  .content-fixed-xl {
    overflow: hidden;
    position: relative;
  }
  .content-fixed-xl .content-fixed-body {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .content-fixed-xl .content-fixed-body [class~=col-], .content-fixed-xl .content-fixed-body [class*=col-] {
    height: 100%;
  }
  .content-fixed-xl .content-fixed-body .content-fixed-main .card-body {
    display: flex;
    flex-direction: column-reverse;
  }
  .content-fixed-xl .content-fixed-body .scroll {
    overflow-y: auto;
  }
}
@media (min-width: 1400px) {
  .content-fixed-xxl {
    overflow: hidden;
    position: relative;
  }
  .content-fixed-xxl .content-fixed-body {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .content-fixed-xxl .content-fixed-body [class~=col-], .content-fixed-xxl .content-fixed-body [class*=col-] {
    height: 100%;
  }
  .content-fixed-xxl .content-fixed-body .content-fixed-main .card-body {
    display: flex;
    flex-direction: column-reverse;
  }
  .content-fixed-xxl .content-fixed-body .scroll {
    overflow-y: auto;
  }
}
.content-fixed {
  overflow: hidden;
  position: relative;
}
.content-fixed .content-fixed-body {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.content-fixed .content-fixed-body [class~=col-], .content-fixed .content-fixed-body [class*=col-] {
  height: 100%;
}
.content-fixed .content-fixed-body .content-fixed-main .card-body {
  display: flex;
  flex-direction: column-reverse;
}
.content-fixed .content-fixed-body .scroll {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .content-fixed-sm .card-body.scroll {
    overflow-y: scroll;
    max-height: 410px;
  }
  .content-fixed-sm .content-fixed-main .card-body {
    display: flex;
    flex-direction: column-reverse;
  }
}
@media (max-width: 767.98px) {
  .content-fixed-md .card-body.scroll {
    overflow-y: scroll;
    max-height: 410px;
  }
  .content-fixed-md .content-fixed-main .card-body {
    display: flex;
    flex-direction: column-reverse;
  }
}
@media (max-width: 991.98px) {
  .content-fixed-lg .card-body.scroll {
    overflow-y: scroll;
    max-height: 410px;
  }
  .content-fixed-lg .content-fixed-main .card-body {
    display: flex;
    flex-direction: column-reverse;
  }
}
@media (max-width: 1199.98px) {
  .content-fixed-xl .card-body.scroll {
    overflow-y: scroll;
    max-height: 410px;
  }
  .content-fixed-xl .content-fixed-main .card-body {
    display: flex;
    flex-direction: column-reverse;
  }
}
@media (max-width: 1399.98px) {
  .content-fixed-xxl .card-body.scroll {
    overflow-y: scroll;
    max-height: 410px;
  }
  .content-fixed-xxl .content-fixed-main .card-body {
    display: flex;
    flex-direction: column-reverse;
  }
}
.content-fixed .card-body.scroll {
  overflow-y: scroll;
  max-height: 410px;
}
.content-fixed .content-fixed-main .card-body {
  display: flex;
  flex-direction: column-reverse;
}

.chat-user-box, .chat-self-box {
  padding: 0.5rem 0.75rem;
  border-radius: 0.875rem;
  margin-bottom: 0.25rem;
  display: table;
  position: relative;
}
.chat-user-box .chat-user-time, .chat-user-box .chat-self-time, .chat-self-box .chat-user-time, .chat-self-box .chat-self-time {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s;
  opacity: 0;
  margin-top: 0.25rem;
}
.chat-user-box:hover .chat-user-time, .chat-user-box:hover .chat-self-time, .chat-self-box:hover .chat-user-time, .chat-self-box:hover .chat-self-time {
  opacity: 1;
  margin-top: 0;
}

.chat-user-time {
  left: 100%;
  margin-left: 0.5rem;
}

.chat-self-time {
  left: auto;
  right: 100%;
  margin-right: 0.5rem;
}

.chat-user-box {
  background-color: #f1e6e6;
  border-top-left-radius: 0px;
}

.chat-self-box {
  background-color: #6f0000;
  color: #fff;
  border-bottom-right-radius: 0px;
}

.fe-1x {
  width: 1rem;
  height: 1rem;
}

.fe-2x {
  width: 1.25rem;
  height: 1.25rem;
}

.fe-3x {
  width: 1.5rem;
  height: 1.5rem;
}

.fe-4x {
  width: 2rem;
  height: 2rem;
}

.fe-5x {
  width: 2.25rem;
  height: 2.25rem;
}

table th {
  font-weight: 500;
}
table.table td, table.table th {
  border-top: 1px solid #faf1f1;
  border-bottom: 0;
}
table.table.table-dark td, table.table.table-dark th {
  border-top: 1px solid #593e3e;
}
table.table-card th:first-child, table.table-card td:first-child {
  padding-left: 1.25rem !important;
}
table.table-card th:last-child, table.table-card td:last-child {
  padding-right: 1.25rem !important;
}
table.table-nowrap td, table.table-nowrap th {
  white-space: nowrap;
}

.todolist .form-check .form-check-input:checked + .form-check-label {
  text-decoration: line-through;
  color: #b49a9a;
}
.todolist .form-check + .form-check {
  margin-top: 1rem;
}

.ql-toolbar.ql-snow, .ql-container.ql-snow {
  border-color: #faf1f1;
}

.ql-container {
  font-size: var(--bs-font-sans-serif);
  font-family: var(--bs-font-sans-serif);
}

.ql-editor {
  padding: 1.25rem 1.25rem;
  background-color: #fff;
  border-bottom-left-radius: 0.875rem;
  border-bottom-right-radius: 0.875rem;
  font-family: var(--bs-font-sans-serif);
}

.ql-container {
  border-bottom-left-radius: 0.875rem;
  border-bottom-right-radius: 0.875rem;
}

.ql-toolbar.ql-snow {
  font-family: var(--bs-font-sans-serif);
  padding: 0.9375rem 1.25rem;
  background-color: #fff;
  border-top-left-radius: 0.875rem;
  border-top-right-radius: 0.875rem;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: #faf1f1;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #785f5f;
}

.ql-snow .ql-editor a {
  text-decoration: none;
  font-weight: 500;
}
.ql-snow .ql-editor img {
  border-radius: 0.875rem;
  box-shadow: 0 0.75rem 1.5rem rgba(34, 21, 21, 0.03);
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #faf1f1;
  box-shadow: 0 0.75rem 1.5rem rgba(34, 21, 21, 0.03);
  padding: 0.75rem 0.75rem;
}

.ql-snow .ql-editor code, .ql-snow .ql-editor pre {
  background-color: #fef6f6;
  border-radius: 0.5rem;
}

.ql-snow .ql-editor code {
  padding: 4px 8px;
}

.ql-snow .ql-editor blockquote {
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
  background: transparent;
  border: 1px solid #faf1f1;
  border-left-width: 5px;
  border-left-color: #6f0000;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.error.invalid-feedback {
  width: auto;
  position: relative;
  margin-top: 0.5rem;
  display: table;
}
.error.invalid-feedback::before {
  border-width: 1px 0 0 1px;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  top: 0;
  left: 50%;
  background-color: #ff4d62;
  position: absolute;
  content: "";
  z-index: 2;
  width: 0.6666em;
  height: 0.6666em;
}

.row.error .form-control {
  border-color: #ff4d62;
}
.row.error .form-control:focus {
  box-shadow: inset 0 1px 2px rgba(255, 77, 98, 0.08), 0 0 0.5rem 0.25rem rgba(255, 77, 98, 0.13);
}
.row.error .form-label {
  color: #cc3e4e;
}
.row.error .form-check-input {
  background-color: #ffdbe0;
}
.row.error .form-check-label {
  color: #ff4d62;
}

.bs-stepper .bs-stepper-header {
  margin: 0 0;
}
.bs-stepper .step-trigger {
  padding: 0 0.5rem;
  font-size: 0.9375rem;
  font-weight: 500;
}
.bs-stepper .step-trigger:hover {
  text-decoration: none;
  background-color: transparent;
}
.bs-stepper .step:first-child .step-trigger {
  padding-left: 0px;
}
.bs-stepper .step:last-child .step-trigger {
  padding-right: 0px;
}
.bs-stepper .bs-stepper-circle {
  background-color: #faf1f1;
  color: #785f5f;
}
.bs-stepper .active .bs-stepper-circle {
  background-color: #6f0000;
  color: #fff;
}
.bs-stepper .bs-stepper-line,
.bs-stepper .line {
  background-color: #efe2e2;
}

.bs-stepper-circle {
  width: 2.75rem;
  height: 2.75rem;
  padding: 0;
  border-radius: 50rem;
  align-items: center;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
  background-color: #f1e6e6;
}

.dropzone .dz-preview .dz-details .dz-filename span:hover {
  border: 0px;
}

.fc .fc-toolbar {
  flex-direction: column;
}
.fc td, .fc th {
  vertical-align: top;
  padding: 0;
  border: 1px solid #faf1f1;
  background-color: #fff;
}
.fc th {
  border-bottom: 0px;
}
.fc .fc-scrollgrid table {
  border-top-style: inherit;
  border-left-style: inherit;
}
.fc .fc-list-event:hover td {
  background-color: #fef6f6;
}
.fc .fc-daygrid-dot-event .fc-event-title {
  text-overflow: ellipsis;
}
.fc .table-bordered > :not(caption) > * {
  border: 0px;
}
.fc .fc-list-table tr > * {
  border-left: 1px;
  border-right: 1px;
}
.fc .fc-list-day-cushion, .fc .fc-list-day-cushion {
  padding: 1.25rem 1.25rem;
}
.fc .fc-direction-ltr .fc-list-table .fc-list-event-graphic {
  padding-right: 1.25rem;
}
.fc .fc-daygrid-event {
  padding: 0.1625rem 0.75rem;
  border-radius: 50rem;
}
.fc .fc-daygrid-dot-event.fc-event-mirror, .fc .fc-daygrid-dot-event:hover {
  background: rgba(89, 62, 62, 0.1);
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: #fef6f6;
}
.fc .fc-toolbar-chunk + .fc-toolbar-chunk {
  margin-top: 0.75rem;
}
.fc .btn {
  padding: 0.325rem 0.75rem;
  font-size: 0.8203125rem;
}
.fc .fc-toolbar-chunk .btn {
  border-radius: 50rem;
}
.fc .fc-toolbar-chunk .btn-group .btn {
  border-radius: 0px;
}
.fc .fc-toolbar-chunk .btn-group .btn:first-child {
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem;
}
.fc .fc-toolbar-chunk .btn-group .btn:last-child {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
}

@media (min-width: 768px) {
  .fc .fc-toolbar {
    flex-direction: row;
  }
  .fc .fc-toolbar-chunk + .fc-toolbar-chunk {
    margin: 0;
  }
}
div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_length {
  padding-top: 1rem;
  padding-left: 0.75rem;
  padding-bottom: 1rem;
}

div.dataTables_wrapper div.dataTables_paginate, div.dataTables_wrapper .dataTables_filter {
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.card div.dataTables_wrapper div.dataTables_info, .card div.dataTables_wrapper div.dataTables_length {
  padding-left: 1.25rem;
}
.card div.dataTables_wrapper .dataTables_filter, .card div.dataTables_wrapper div.dataTables_paginate {
  padding-right: 1.25rem;
}

div.dataTables_wrapper > .row {
  align-items: center;
}

div.dataTables_wrapper .table.dataTable {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

table.dataTable > thead .sorting:before, table.dataTable > thead .sorting_asc:before, table.dataTable > thead .sorting_desc:before, table.dataTable > thead .sorting_asc_disabled:before, table.dataTable > thead .sorting_desc_disabled:before {
  right: 1em;
  top: 1.25em;
  font-size: 0.675rem;
  content: "";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
}

table.dataTable > thead .sorting:after, table.dataTable > thead .sorting_asc:after, table.dataTable > thead .sorting_desc:after, table.dataTable > thead .sorting_asc_disabled:after, table.dataTable > thead .sorting_desc_disabled:after {
  content: "";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-size: 0.675rem;
  right: 1em;
  top: 2em;
}

.choices__list--single {
  padding: 0px;
}

.choices__list.dropdown-menu {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #b78080;
  border-top: 0;
  max-height: 280px;
  overflow-y: auto;
  box-shadow: 0 0.5rem 1rem rgba(34, 21, 21, 0.04);
}
.choices__list.dropdown-menu > .form-control {
  margin-bottom: 0.5rem;
  margin-left: 2%;
  width: 96%;
  padding: 0.325rem 0.75rem;
}

.choices.is-open > .form-control, .choices.is-focused > .form-control {
  box-shadow: none;
  border-color: #b78080;
}
.choices.is-open[aria-expanded=true] > .form-control {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.choices__list--single {
  padding: 0;
}

.form-control-sm + .choices__list.dropdown-menu {
  border-bottom-left-radius: 0.325rem;
  border-bottom-right-radius: 0.325rem;
}

.form-control + .choices__list.dropdown-menu {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.form-control-lg + .choices__list.dropdown-menu {
  border-bottom-left-radius: 0.875rem;
  border-bottom-right-radius: 0.875rem;
}

.choices.is-flipped > .form-control {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.choices.is-flipped .dropdown-menu {
  bottom: 100%;
  top: auto;
  border-bottom: 0px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-top: 1px solid #b78080;
}
.choices.is-flipped .form-control-lg + .choices__list.dropdown-menu {
  border-top-left-radius: 0.875rem;
  border-top-right-radius: 0.875rem;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.choices.is-flipped .form-control-sm + .choices__list.dropdown-menu {
  border-top-left-radius: 0.325rem;
  border-top-right-radius: 0.325rem;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.choices.is-flipped[aria-expanded=true] > .form-control {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.choices.is-flipped[aria-expanded=true] > .form-control-lg {
  border-bottom-left-radius: 0.875rem;
  border-bottom-right-radius: 0.875rem;
}
.choices.is-flipped[aria-expanded=true] > .form-control-sm {
  border-bottom-left-radius: 0.325rem;
  border-bottom-right-radius: 0.325rem;
}
.choices.is-flipped.is-open .choices__inner {
  border-radius: 0 0 0.5rem 0.5rem;
}
.choices.is-open .choices__inner {
  border-radius: 0.5rem 0.5rem 0 0;
}

.form-control.form-control-sm + .choices__list.dropdown-menu .dropdown-item {
  font-size: 0.8203125rem;
}

.form-control.form-control-lg + .choices__list.dropdown-menu .dropdown-item {
  font-size: 1.0546875rem;
}

.choices[data-type*=select-one]::after {
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  content: "";
  font-family: bootstrap-icons !important;
  transform: translateY(-50%);
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.75rem;
  height: 0.75rem;
  top: 50%;
  font-size: 0.8203125rem;
  transition: all 0.2s ease-in-out;
}
.choices[data-type*=select-one].is-open::after {
  margin-top: 0px;
  transform: translateY(-50%) rotate(180deg);
}

.choices__list.dropdown-menu.show .form-control {
  box-shadow: 0px 0px;
}

.choices__list--multiple .choices__item {
  background-color: #6f0000;
  border: 0px;
  border-radius: 0.5rem;
  margin-right: 0.125rem;
  margin-bottom: 0.075rem;
  margin-top: 0.075rem;
}
.choices__list--multiple .choices__item .choices__button {
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}
.choices__list--multiple + .form-control {
  width: auto;
  display: inline-block;
  border: 0px;
  padding: 0 0.15em;
  background-color: transparent;
  box-shadow: none;
}

.choices__inner {
  transition: all 0.2s ease-in-out;
  border-color: #efe2e2;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: inset 0 1px 2px rgba(34, 21, 21, 0.0125);
  padding: 0.475rem 0.875rem;
  min-height: 0;
}

.is-focused .choices__inner, .is-open .choices__inner {
  border-color: #b78080;
  box-shadow: 0 0 0.25rem 0.125rem rgba(111, 0, 0, 0.125);
}

.choices__list--multiple .choices__item.is-highlighted {
  background-color: #6f0000;
  border: 0px;
}

.daterangepicker {
  border-color: #faf1f1;
  margin-top: 2px;
  color: #785f5f;
  border-radius: 0.5rem;
  font-family: var(--bs-font-sans-serif);
  box-shadow: 0 0.5rem 1rem rgba(34, 21, 21, 0.04);
  padding: 0.5rem;
}
.daterangepicker::before, .daterangepicker::after {
  display: none;
}
.daterangepicker .ranges li {
  border-radius: 0.5rem;
}
.daterangepicker .ranges li:not(:last-child) {
  margin-bottom: 0.125rem;
}
.daterangepicker.show-calendar .ranges {
  margin-right: 0.5rem;
}

.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  color: #b49a9a;
}

.daterangepicker td.off.today {
  color: #fff;
  background-color: #6f0000;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left-color: #faf1f1;
}

.daterangepicker .drp-buttons {
  border-top-color: #faf1f1;
}
.daterangepicker .drp-buttons .btn {
  font-weight: 400;
}
.daterangepicker .drp-buttons .btn.btn-default {
  background-color: #faf1f1;
}

.daterangepicker .ranges li:hover, .daterangepicker td.available:hover, .daterangepicker th.available:hover, .daterangepicker td.in-range {
  background-color: #e9d9d9;
}

.daterangepicker td.active, .daterangepicker td.active:hover, .daterangepicker .ranges li.active, .daterangepicker td.in-range.end-date {
  background-color: #6f0000;
}

.monthselect, .yearselect {
  border-color: #efe2e2;
}

.apexcharts-tooltip {
  box-shadow: 0 0.125rem 0.5rem rgba(34, 21, 21, 0.05) !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
  border: 0px !important;
  background: rgba(255, 255, 255, 0.986) !important;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: #faf1f1 !important;
  border-bottom: 0px !important;
  color: #221515;
  padding-left: 0.75rem;
}

.apexcharts-tooltip-text-y-value, .apexcharts-tooltip-text-goals-value, .apexcharts-tooltip-text-z-value {
  font-weight: 500 !important;
}

.apexcharts-xaxistooltip {
  background-color: #fef6f6 !important;
  border: 1px solid #faf1f1 !important;
}

.apexcharts-xaxistooltip-bottom:before {
  border-bottom-color: #faf1f1 !important;
}

.apexcharts-xaxistooltip-bottom:after {
  border-bottom-color: #fef6f6 !important;
}

.simplebar-track.simplebar-vertical {
  width: 8px;
}

.simplebar-track.simplebar-horizontal {
  height: 8px;
}

.simplebar-scrollbar:before {
  left: 2px;
  right: 2px;
  border-radius: 0.5rem;
  background-color: #efe2e2;
}

.text-white .simplebar-scrollbar:before {
  background-color: rgba(255, 255, 255, 0.35);
}

.chips {
  border-radius: 15px !important;
  box-shadow: 0px 0px 0px 1px lightgrey;
  margin-right: 20px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
}

.chipsContainer {
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
}

.ng-submitted .ng-invalid > .invalid-feedback {
  display: block;
}

.ng-submitted .ng-valid > .invalid-feedback {
  display: none;
}

.ng-submitted ngb-timepicker.ng-invalid input {
  background-image: none !important;
  padding-right: revert !important;
}

ngb-timepicker.ng-touched.ng-invalid input {
  background-image: none !important;
  padding-right: revert !important;
}

.ng-submitted ngb-timepicker.ng-valid input {
  background-image: none !important;
  padding-right: revert !important;
}

ngb-timepicker.ng-touched.ng-valid input {
  background-image: none !important;
  padding-right: revert !important;
}