//Site preloader

.loader{
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    display: flex;
    overflow: hidden;
}