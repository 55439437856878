html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}


body {
  display: flex;
  flex-direction: column;
  letter-spacing: $letter-spacing-base;
}

%heading {
  font-weight: $headings-font-weight;
  letter-spacing: $letter-spacing-headings;
}
*{
  &:focus{
    outline: 0!important;
  }
}
a,button {
  transition: $transition-base;
}

.text-mono {
  font-family: $font-family-monospace;
}

strong,
b {
  font-weight: $font-weight-bold;
}
a:not(.nav-link):not(.btn):not(.dropdown-item):not(.badge){
  &.text-dark{
    &:hover{
      color:$primary!important;
    }
  }
  &.text-secondary{
    &:hover{
      color:$black!important;
    }
  }
  &.text-muted{
    &:hover{
      color:$black!important;
    }
  }
}