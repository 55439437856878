//Kanban
.board {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
}

.task-list-items {
    min-height: 100px;
    position: relative;
    width: 20rem;
    .card {
        white-space: normal;
    }

    &:before {
        content: "No Tasks";
        position: absolute;
        line-height: 110px;
        width: 100%;
        padding-left: 1.25rem;
        display: block;
        font-weight: $font-weight-bold;
        color: $text-muted;
        border: 1px dashed $border-color;
        border-radius: $border-radius-lg;
    }
}

.tasks.tasks:not(:first-child) {
    border-left: 1px solid $gray;
}