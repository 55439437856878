//avatars
.avatar{
    width:$spacer * 3;
    height:$spacer * 3;
    font-size: $font-size-sm;
    &.xs{
        width:$spacer * 1.5;
        height:$spacer * 1.5;
    font-size: $font-size-base * .675;
    }
    &.sm{
        width:$spacer * 2;
        height:$spacer * 2;
    font-size: $font-size-sm * .935;
    }
    &.lg{
        width:$spacer * 4;
        height:$spacer * 4;
    font-size: $font-size-base;
    }
    &.xl{
        width:$spacer * 5;
        height:$spacer * 5;
    font-size: $font-size-lg;
    }
    &.xxl{
        width:$spacer * 7;
        height:$spacer * 7;
    font-size: $font-size-lg;
    }
}
.avatar-status{
    position: relative;
    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 5%;
        width: 25%;
        height: 25%;
        border-radius: 100%;
        border: 2px solid #fff;
        display: table;
    }
    &.status-online{
        &::before{
            background-color: $success;
        }
    }
    &.status-offline{
        &::before{
            background-color: $gray-300;
        }
    }
    &.status-dnd{
        &::before{
            background-color: $danger;
        }
    }
    &.status-away{
        &::before{
            background-color: $warning;
        }
    }
}


//avatar group
.avatar-group{
    display: inline-flex;
    align-items: stretch;
    .avatar-group-item{
       display: inline-block;
       position: relative;
       margin-left: -.5rem;
       border: 2px solid $white;
       .avatar-group-img{
           object-fit: cover;
           width: 100%;
           height: 100%;
       }
       &:hover{
           z-index: 1;
           position: relative;
       }
    }
}