
// Bootstrap functions
@import './node_modules/bootstrap/scss/functions';

// theme functions
@import './custom/functions/functions';

//Third party plugins style import

//Aos Plugin Scss
@import "./node_modules/aos/src/sass/aos.scss";

//Import bootstrap overrides variables - theme variables
@import "./custom/variables/variables";
@import "./custom/variables/additional-variables";

//Import bootstrap core scss
@import "./node_modules/bootstrap/scss/bootstrap.scss";


//Admin core css files
@import "./custom/utilities/utilities";
@import "./custom/components/components";
// Bootstrap functions
@import './custom/webfonts/webfonts';


.chips {
    border-radius: 15px !important;
    box-shadow: 0px 0px 0px 1px lightgrey;
    margin-right: 20px;
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .chipsContainer {
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
  }
  
  .ng-submitted .ng-invalid {
    @extend .is-invalid;
  }
  
  
  .ng-touched.ng-invalid {
    @extend .is-invalid;
  }
  
  .ng-submitted .ng-valid {
    @extend .is-valid;
  }
  
  .ng-touched.ng-valid {
    @extend .is-valid;
  }
  
  .ng-submitted .ng-invalid > .invalid-feedback {
    display: block;
  }
  
  .ng-submitted .ng-valid > .invalid-feedback {
    display: none;
  }
  
  // Do not show error symbol on timepickers
  
  .ng-submitted ngb-timepicker.ng-invalid {
    input {
      background-image: none !important;
      padding-right: revert !important;
      @extend .is-invalid;
    }
  }
  
  ngb-timepicker.ng-touched.ng-invalid {
    input {
      background-image: none !important;
      padding-right: revert !important;
      @extend .is-invalid;
    }
  }
  
  .ng-submitted ngb-timepicker.ng-valid {
    input {
      background-image: none !important;
      padding-right: revert !important;
      @extend .is-valid;
    }
  }
  
  ngb-timepicker.ng-touched.ng-valid {
    input {
      background-image: none !important;
      padding-right: revert !important;
      @extend .is-valid;
    }
  }
  