
/* You can add global styles to this file, and also import other style files */

@import "../node_modules/datatables.net-bs5/css/dataTables.bootstrap5.min.css";
@import "../node_modules/datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
@import "../node_modules/datatables.net-colreorder-bs5/css/colReorder.bootstrap5.min.css";
@import "../node_modules/bs-stepper/dist/css/bs-stepper.min.css";
@import "assets/assan/fonts/bootstrap-icons/bootstrap-icons.css";
@import "assets/assan/vendor/css/simplebar.min.css";
@import "assets/assan/vendor/css/choices.min.css";
@import "assets/assan/css/style.css";


a {
  text-decoration: none;
}

.dataTables_empty {
  display: none;
}

.sorting_disabled {
  font-size: 0
}

.sidebar-dropdown .sidebar-item .sidebar-link {
  text-transform: none;
}
