//Navs and Tabs
// Changing nav tabs to be bottom highlight style

.nav-tabs {
    .nav-item {
      margin-left: $nav-tabs-link-margin-x;
      margin-right: $nav-tabs-link-margin-x;
    }
    .nav-link {
      padding: $nav-tabs-link-padding-y 0;
      border-bottom: $nav-tabs-link-active-border-width solid transparent;
      border-left-width: 0;
      border-right-width: 0;
      border-top-width: 0;
      
      &:not(.active) {
        color: $gray-600;
  
        &:hover {
          color: $gray-700;
        }
      }
  &.active{
    border-bottom-color:$component-active-bg;
    background-color: transparent;
  }
    }
  
    .nav-item:first-child {
      margin-left: 0;
    }
  
    .nav-item:last-child {
      margin-right: 0;
    }
  
    // Removes the primary color underline from dropdowns in .nav-tabs
    .nav-item.show .nav-link {
      border-color: transparent;
    }
  &.nav-vertical{
    flex-direction: column;
    border-bottom: 0px;
    .nav-item{
      margin-left: 0px;
      margin-right: 0px;
      &:not(:first-child){
        margin-top:$nav-link-padding-y * .5;
      }
    }
    .nav-link{
      border-bottom-width: 0px;
      padding: $nav-link-padding-y $nav-link-padding-x;
      border-radius: $card-border-radius;
      &:not(.active) {
        color: $gray-600;
        &:hover {
          color: $component-active-bg;
          background-color: tint-color($component-active-bg,90%);
        }
      }
      &.active{
        color: $component-active-bg;
          background-color: tint-color($component-active-bg,80%);
      }
    }
  }
  }


// Nav overflow
//
// Allow links to overflow and make horizontally scrollable

.nav-overflow {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 1px; // to prevent active links border bottom from hiding
    // Hide scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
  }