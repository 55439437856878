//
//Horizontal layout style
//
body.layout-horizontal{
    .navbar{
        .navbar-nav.navbar-icon{
            .dropdown-menu{
                position: absolute;
            }
        }
    }
}