//Zindex
.z-index-1{
    z-index: 1!important;
}
.z-index-2{
    z-index: 2!important;
}
.z-index-3{
    z-index: 3!important;
}
.z-index-4{
    z-index: 4!important;
}
.z-index-fixed{
    z-index: $zindex-fixed!important;
}