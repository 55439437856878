//Apex charts custom css
.apexcharts-tooltip{
    box-shadow: $box-shadow-sm!important;
}
.apexcharts-tooltip.apexcharts-theme-light {
    border:0px!important;
    background: rgba($white, 0.986)!important;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title{
    background: rgba($gray-200, 1)!important;
    border-bottom: 0px!important;
    color: $dark;
    padding-left: $spacer * .75;
}
.apexcharts-tooltip-text-y-value, .apexcharts-tooltip-text-goals-value, .apexcharts-tooltip-text-z-value{
    font-weight: $font-weight-bold!important;
}

.apexcharts-xaxistooltip{
    background-color: $light!important;
    border: 1px solid $border-color!important;
}
.apexcharts-xaxistooltip-bottom:before {
   border-bottom-color: $border-color!important;
}
.apexcharts-xaxistooltip-bottom:after {
    border-bottom-color: $light!important;
}