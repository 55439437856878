 //Headings, link
 .text-white,
 .text-light {
     h1,
     h2,
     h3,
     h4,
     h5,
     h6 {
         color: $white !important;
     }

     a:not(.btn):not(.dropdown-item):not([class*="bg-"]) {
         color: $white;
     }

     .text-muted {
         color: rgba($white, .75) !important;
     }
 }


 //Breadcrumb
.text-white,.text-light{
    .breadcrumb-item {
        &:not(.active) {
            color: rgba($white, .75);
        }
   
        +.breadcrumb-item {
            &::before {
                color: rgba($white, .4);
            }
        }
        &.active{
            color: rgba($white, .75);
        }
    }
}


//Input and form components on dark background
.text-white,.text-light{
    .form-control{
        border-color: rgba($white, .4);
        background-color: transparent;
        color: $white;
        &::placeholder,&::-webkit-placeholder,&::-ms-placeholder{
            color: rgba($white, .6);
        }
    }
}