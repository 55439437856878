//visibility
//Opacity
@use "sass:math";
.opacity-0 {
    opacity: 0 !important;
  }
  .opacity-05 {
    opacity: 0.05 !important;
  }
  .opacity-100 {
    opacity: 1 !important;
  }


    //Light(tint) backgrounds
    @mixin bg-tint($color: $theme-colors) {
        background-color: tint-color($color, 80%) !important;
      }
      @mixin bg-tint-hover($color: $theme-colors) {
        background-color: tint-color($color, 70%) !important;
      }
      @each $color, $value in $theme-colors {
        .bg-tint-#{$color} {
          @include bg-tint($value);
        }
        a.bg-tint-#{$color}{
            &:hover{
                @include bg-tint-hover($value);
            }
        }
      }
      //Dark(shade) backgrounds for colors
      @mixin bg-shade($color: $theme-colors) {
        background-color: shade-color($color, 75%) !important;
      }
      @each $color, $value in $theme-colors {
        .bg-shade-#{$color} {
          @include bg-shade($value);
        }
      }
    
      //Hover tint background
      @mixin hover-bg-tint($color: $theme-colors) {
        background-color: tint-color($color, 80%) !important;
      }
      @each $color, $value in $theme-colors {
        .hover-bg-#{$color} {
          &:hover{
            @include hover-bg-tint($value);
          }
        }
      }



      //gradient-background
      .bg-gradient-dark{
        background: $bg-gradient-dark;
      }
      .bg-gradient-primary{
        background: $bg-gradient-primary;
      }