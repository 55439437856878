//
//Icons scss start

.fe-1x{
  width: $spacer;
  height: $spacer;
}
.fe-2x{
  width: $spacer * 1.25;
  height: $spacer * 1.25;
}
.fe-3x{
  width: $spacer * 1.5;
  height: $spacer * 1.5;
}
.fe-4x{
  width: $spacer * 2;
  height: $spacer * 2;
}
.fe-5x{
  width: $spacer * 2.25;
  height: $spacer * 2.25;
}
