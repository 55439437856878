//
// Checklist todos list
//
.todolist {
    .form-check {
        .form-check-input:checked + .form-check-label {
            text-decoration: line-through;
            color: $text-muted;
          }
          + .form-check{
              margin-top: $spacer;
          }
    }
}