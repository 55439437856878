//
//Additional Border styles
//
.border-dashed{
    &.border-bottom{
        border-bottom-style: dashed!important;
    }
    &.border-top{
        border-top-style: dashed!important;
    }
    &.border-end{
        border-right-style: dashed!important;
    }
    &.border-start{
        border-left-style: dashed!important;
    }
    &.border{
        border-style: dashed!important;
    }
}