//data tables style
div.dataTables_wrapper div.dataTables_info,div.dataTables_wrapper div.dataTables_length {
    padding-top: $table-cell-padding-y;
    padding-left:$table-cell-padding-x;
    padding-bottom:$table-cell-padding-y;
}
div.dataTables_wrapper div.dataTables_paginate,div.dataTables_wrapper .dataTables_filter{
    padding-right:$table-cell-padding-x;
    padding-top: $table-cell-padding-y * .5;
    padding-bottom: $table-cell-padding-y * .5;
}
.card{
    div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_length{
        padding-left: $card-spacer-x;
    }
    div.dataTables_wrapper .dataTables_filter, div.dataTables_wrapper div.dataTables_paginate{
        padding-right: $card-spacer-x;
    }
}
div.dataTables_wrapper>.row{
align-items: center;
}
div.dataTables_wrapper .table.dataTable{
margin-top: 0!important;
margin-bottom: 0!important;
}
//Sorting icons
table.dataTable>thead .sorting:before, table.dataTable>thead .sorting_asc:before, table.dataTable>thead .sorting_desc:before, table.dataTable>thead .sorting_asc_disabled:before, table.dataTable>thead .sorting_desc_disabled:before {
    right: 1em;
    top: 1.25em;
    font-size: .675rem;
    content: "\f286";
    display: inline-block;
        font-family: bootstrap-icons !important;
        font-style: normal;
        font-weight: normal !important;
}
table.dataTable>thead .sorting:after, table.dataTable>thead .sorting_asc:after, table.dataTable>thead .sorting_desc:after, table.dataTable>thead .sorting_asc_disabled:after, table.dataTable>thead .sorting_desc_disabled:after{
    content: "\f282";
    display: inline-block;
        font-family: bootstrap-icons !important;
        font-style: normal;
        font-weight: normal !important;
        font-size: .675rem;
        right: 1em;
        top: 2em;
}