//
//Admin hover styles for images, links and more
//
.card-hover{
    .hover-image{
        overflow: hidden;
        img{
            transition: all .3s;
        }
        .hover-image-overlay{
            background-image: linear-gradient(180deg, transparent 20%, rgba($black,.4) 100%);
            transition: all .2s;
            opacity: 0;
            visibility: hidden;
            span{
                display: block;
                opacity: 0;
                transition: all .45s;
                transform: translateY(-10px);
            }
        }
    }
    
    &:hover{
       .hover-image {
           img{
               transform: scale(1.1);
           }
        .hover-image-overlay{
            visibility: visible;
            opacity: 1;
            span{
                transform: none;
                opacity: 1;
            }
        }
       }
    }
}